import { v4 as uuid } from 'uuid';

interface RequestContext {
  init: RequestInit;
  url: string;
}

export const traceIdHeader = 'x-b3-traceid';

const withTraceId = (requestId?: string) => async (ctx: RequestContext) => ({
  ...ctx,
  init: {
    ...ctx.init,
    headers: {
      ...ctx.init.headers,
      [traceIdHeader]: requestId ?? uuid(),
    },
  },
});

export const createTrace = (traceId?: string) => {
  const finalTraceId = traceId ?? uuid();

  return <T extends { withPreMiddleware: (withTraceId: (ctx: RequestContext) => Promise<RequestContext>) => T }>(client: T) =>
    client.withPreMiddleware(withTraceId(finalTraceId));
};
