import { Flex } from '@get-fabric/fabric-design-system';
import React from 'react';
import styled from 'styled-components';
import { TouchpointLogo } from './TouchpointLogo';
import { TouchpointSelectionCard } from './TouchpointSelectionCard';

const TouchpointSelectionContainer = styled(Flex)`
  width: 100%;
  margin-top: 100px;
  align-items: center;
  flex-direction: column;
`;

export const TouchpointSelectionPage = () => (
  <TouchpointSelectionContainer gap={70}>
    <TouchpointLogo size={250} />
    <TouchpointSelectionCard />
  </TouchpointSelectionContainer>
);
