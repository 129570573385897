import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Popover } from '@mui/material';
import Send from '@mui/icons-material/Send';
import History from '@mui/icons-material/History';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { Flex } from '@get-fabric/fabric-design-system';
import { createHandler } from '../../../services/manualScanner';
import { ScanHistory } from './ScanHistory';
import type { ScanRecord } from './scan';
import { BarcodeInput } from './BarcodeInput';

const ScanMenu = styled(Flex)`
  padding: 25px 15px;
  max-height: 500px;
`;

interface ManualScanPopoverProps {
  open: boolean;
  close: () => void;
  anchorEl: Element | null;
}

const manualScanHandle = createHandler();

export const ManualScanPopover: FunctionComponent<ManualScanPopoverProps> = ({ open, close, anchorEl }) => {
  const [barcode, setBarcode] = useState('');
  const [scanRecord, setScanRecord] = useState<ScanRecord | null>(null);
  const [historyOpen, toggleHistory] = useToggle(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const openHistory = useCallback(() => {
    toggleHistory(true);
  }, [toggleHistory]);

  const closeHistory = useCallback(() => {
    setScanRecord(null);
    toggleHistory(false);
  }, [toggleHistory]);

  const closeScanMenu = useCallback(() => {
    setScanRecord(null);
    setBarcode('');
    closeHistory();
    close();
  }, [closeHistory, close]);

  const focusMenu = useCallback(() => (open ? inputRef.current?.focus() : inputRef.current?.blur()), [open]);

  const sendBarcode = useCallback(() => {
    if (!barcode) {
      return;
    }

    closeScanMenu();
    manualScanHandle(barcode);
  }, [barcode, closeScanMenu]);

  const selectScanRecordFromHistory = useCallback((scanRecord: ScanRecord) => {
    setScanRecord(scanRecord);
    setBarcode(scanRecord.barcode);
  }, []);

  const selectBarcodeFromInput = useCallback((barcode: string) => {
    setBarcode(barcode);
    setScanRecord(null);
  }, []);

  useEffect(() => {
    setBarcode('');
    inputRef.current?.focus();
  }, []);

  return (
    <Popover
      open={open}
      onFocus={focusMenu}
      anchorEl={anchorEl}
      onClose={closeScanMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ScanMenu flexDirection={'column'}>
        <Flex gap={2} alignItems={'center'} fullWidth>
          <IconButton color={'default'} onClick={historyOpen ? closeHistory : openHistory} aria-label={'show scan history'}>
            <History />
          </IconButton>
          <BarcodeInput ref={inputRef} value={barcode} onChange={selectBarcodeFromInput} onEnter={sendBarcode} />
          <IconButton color={'primary'} disabled={!barcode} onClick={sendBarcode} aria-label={'send barcode'}>
            <Send />
          </IconButton>
        </Flex>
        <Flex px={30}>
          <ScanHistory
            open={historyOpen}
            onOpen={openHistory}
            onClose={closeHistory}
            selectedScanRecordId={scanRecord?.id ?? ''}
            onScanSelect={selectScanRecordFromHistory}
          />
        </Flex>
      </ScanMenu>
    </Popover>
  );
};
