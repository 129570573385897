import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleGroup } from '@get-fabric/fabric-design-system';
import { FormField } from './FormField';
import type { ProductFormProperties } from './useProductFormProperties';

interface ScannableSelectionProps {
  value: ProductFormProperties['scannable'];
  onChange: (value: ProductFormProperties['scannable']) => void;
  disabled: boolean;
}

export const ScannableSelection: FunctionComponent<ScannableSelectionProps> = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => [
      { label: t('actions.yes'), value: true },
      { label: t('actions.no'), value: false },
    ],
    [t],
  );

  return (
    <FormField text={t('decant.productPropertiesModal.scannableSubtitle')}>
      <ToggleGroup options={options} value={value} onChange={onChange} disabled={disabled} size={'s'} />
    </FormField>
  );
};
