import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { useSubstationToteAllocations, useOrderTotesInPosition } from '../../../../shared/hooks';
import { useRecoilAsync, toteByPositionState } from '../../../../shared/state';
import type { Tote } from '../../../../shared/state';
import { TaskCancelled } from '../../../../shared/components/TaskCancelled/TaskCancelled';
import { logger } from '../../../../shared/clients/loggingApi';
import { Loading } from '../../../../shared/components/Loading';

interface TaskCancelledWrapperProps {
  position: 'left' | 'right';
  inventoryTote: Tote;
}

export const TaskCancelledWrapper: FunctionComponent<TaskCancelledWrapperProps> = ({ position, inventoryTote }) => {
  const orderTotesInPosition = useOrderTotesInPosition(position);

  const { currentAllocation } = useSubstationToteAllocations({
    position,
    toteInPositionId: inventoryTote.id,
  });

  const currentAllocationOrderTote = useMemo(
    () => orderTotesInPosition.find(({ orderTote }) => orderTote && orderTote.totesRequestId === currentAllocation?.totesRequestId),
    [orderTotesInPosition, currentAllocation],
  );

  const activeTrayPosition = useMemo(() => currentAllocationOrderTote?.substationKey ?? '', [currentAllocationOrderTote]);

  const { data: orderToteInActiveTray } = useRecoilAsync(toteByPositionState(activeTrayPosition));

  if (!orderToteInActiveTray) {
    logger.error('Order tote not found for current allocation');
    return <Loading />;
  }

  return <TaskCancelled allocation={currentAllocation} tote={inventoryTote} orderTote={orderToteInActiveTray} position={position} />;
};
