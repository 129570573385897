import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@get-fabric/fabric-design-system';
import { format } from 'date-fns';
import { BinInfoProductImage } from './BinInfoProductImage';
import { BinContentProperty } from './BinContentProperty';

interface BinInfoContainerProps {
  id: string;
  name?: string;
  quantity?: number;
  sku?: string;
  barcodes?: string[];
  imageUrl?: string;
  expiryDate?: number;
  bestBeforeDate?: number;
}

export const BinContentInfo: FunctionComponent<BinInfoContainerProps> = ({
  id,
  name,
  quantity,
  sku,
  barcodes,
  imageUrl,
  expiryDate,
  bestBeforeDate,
}) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent={'space-between'} alignItems={'flex-start'} fullWidth>
      <Flex flexDirection={'column'} gap={1}>
        <BinContentProperty label={t('toteContents.binId')} value={id} />
        {name && <BinContentProperty label={t('toteContents.productName')} value={name} />}
        {!!quantity && <BinContentProperty label={t('toteContents.quantity')} value={quantity} />}
        {sku && <BinContentProperty label={t('toteContents.sku')} value={sku} />}
        {barcodes && <BinContentProperty label={t('toteContents.barcodes')} value={barcodes.join(', ')} />}
        {expiryDate && <BinContentProperty label={t('toteContents.expiryDate')} value={format(expiryDate, t('date.expirationFormat'))} />}
        {bestBeforeDate && (
          <BinContentProperty label={t('toteContents.bestBeforeDate')} value={format(bestBeforeDate, t('date.expirationFormat'))} />
        )}
      </Flex>
      {imageUrl && <BinInfoProductImage imageUrl={imageUrl} />}
    </Flex>
  );
};
