import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody, Text } from '@get-fabric/fabric-design-system';
import { useRecoilValue } from 'recoil';
import { ExceptionModalFooter } from '../ExceptionsModal';
import { stationNameState } from '../../../state';

interface MoveRejectedItemProps {
  submitException: () => Promise<void>;
  damagedTrolleyLocation: string;
}

export const MoveRejectedItemsStep: FunctionComponent<MoveRejectedItemProps> = ({ damagedTrolleyLocation, submitException }) => {
  const { t } = useTranslation();
  const stationName = useRecoilValue(stationNameState);

  return (
    <>
      <ModalBody>
        <Text size={22}>{t('exceptions.rejectedItem.damagedTrolley', { stationName })}</Text>
      </ModalBody>
      <ExceptionModalFooter reportException={submitException} disableReport={!damagedTrolleyLocation} />
    </>
  );
};
