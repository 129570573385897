import type { FunctionComponent } from 'react';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import type { DecantAction } from '@get-fabric/action-api-client';
import { useTranslation } from 'react-i18next';
import { Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';
import { useAsync } from 'react-use';
import { toast } from 'react-toastify';
import { productsApi } from '../../../../../../shared/clients/wmsApi';
import { logger } from '../../../../../../shared/clients/loggingApi';

interface ActionDetailsProps {
  action: DecantAction;
}

const Title = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionDetails: FunctionComponent<ActionDetailsProps> = ({ action }) => {
  const { t } = useTranslation();
  const { id, mfcId } = action;

  const { value: product, loading } = useAsync(async () => {
    try {
      return await productsApi.getDecantActionProduct(id, mfcId, uuid());
    } catch (error) {
      logger.error('failed to get decant action product', {
        error,
        actionId: id,
      });
      toast.error(t('decant.actionSelectionModal.ActionOption.failedToGetProductDetails'));

      throw error;
    }
  }, [id, mfcId, t]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Flex flexDirection={'column'}>
      <Title size={34} pb={3}>
        {product?.name ?? t('decant.actionSelectionModal.actionOption.productNameFallback')}
      </Title>
      <Flex flexDirection={'column'} gap={3}>
        <Text size={14} color={'gray.700'}>{`#${id}`}</Text>
        {product?.expiryDateMandatory && product?.expiryDate && (
          <Text>{t('decant.actionSelectionModal.actionOption.expiryDate', { date: format(product?.expiryDate, 'dd MMM yyyy') })}</Text>
        )}
        {product?.lotNumber && <Text>{t('decant.actionSelectionModal.actionOption.lotNumber', { lotNumber: product?.lotNumber })}</Text>}
      </Flex>
    </Flex>
  );
};
