import type { FunctionComponent, SyntheticEvent } from 'react';
import React, { useCallback } from 'react';
import { Autocomplete } from '@mui/material';
import { MfcSelectionOption } from './MfcSelectionOption';

interface MfcSelectionAutoCompleteProps {
  onSelect: (mfc: string) => void;
  mfcs: string[];
  loading: boolean;
}

export const MfcSelectionAutoComplete: FunctionComponent<MfcSelectionAutoCompleteProps> = ({ onSelect, mfcs, loading }) => {
  const onMfcChange = useCallback(
    (_: SyntheticEvent, value: string | null) => {
      onSelect(value ?? '');
    },
    [onSelect],
  );

  return (
    <Autocomplete
      autoHighlight
      loading={loading}
      options={[...mfcs]}
      onChange={onMfcChange}
      renderInput={MfcSelectionOption}
      data-testid={'mfcSelectionInput'}
    />
  );
};
