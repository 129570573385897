import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { Bin } from '@get-fabric/wms-api-client';
import { useCompleteBin } from '../hooks/useCompleteBin';
import { Loading } from '../../../../shared/components/Loading';
import { BinBlindCheckSteps } from './BinBlindCheckSteps';

interface BinBlindCheckProps {
  bin: Bin;
}

export const BinBlindCheck: FunctionComponent<BinBlindCheckProps> = ({ bin }) => {
  const { completeBin, completingBin } = useCompleteBin();

  if (completingBin) {
    return <Loading />;
  }

  return (
    <Flex alignItems={'center'} gap={10}>
      <BinBlindCheckSteps bin={bin} onComplete={completeBin} />
    </Flex>
  );
};
