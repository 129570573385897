import styled, { css } from 'styled-components';
import type { FlexProps } from '@get-fabric/fabric-design-system';
import { Flex } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';

interface NotAllowedWrapperProps extends FlexProps {
  inactive: boolean;
}

export const PointerEventsBlocker = styled<FunctionComponent<NotAllowedWrapperProps>>(Flex)`
  flex-grow: 1;
  padding: 24px;
  box-sizing: border-box;
  ${({ inactive }) =>
    inactive &&
    css`
      pointer-events: none;
    `}
`;
