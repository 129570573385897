import { Flex, Text } from '@get-fabric/fabric-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TasksDone = () => {
  const { t } = useTranslation();

  return (
    <Flex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={25}>
      <Text weight={300} size={64} textAlign={'center'}>
        {t('tasksDone.nicelyDone')}
      </Text>
      <Text weight={300} size={64} textAlign={'center'}>
        {t('tasksDone.tasksCompleted')}
      </Text>
    </Flex>
  );
};
