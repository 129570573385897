import React from 'react';
import type { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import type { FlexProps } from '@get-fabric/fabric-design-system';
import { Flex } from '@get-fabric/fabric-design-system';
import { grey } from '@mui/material/colors';
import { PointerEventsBlocker } from './PointerEventsBlocker';

interface SubStationContainerProps extends FlexProps {
  active: boolean;
  inactive: boolean;
}

const StyledSubstationContainer = styled<FunctionComponent<SubStationContainerProps>>(Flex)`
  overflow: hidden;
  transition: width 0.7s;
  width: ${({ active, inactive }) => (active ? 70 : inactive ? 30 : 50)}%;

  ${({ inactive }) =>
    inactive &&
    css`
      background-color: ${grey[300]};
      opacity: 0.4;
      user-select: none;
      cursor: not-allowed;
    `}
`;

export const SubstationContainer: FunctionComponent<SubStationContainerProps> = ({ inactive, active, children, ...props }) => (
  <StyledSubstationContainer inactive={inactive} active={active} {...props}>
    <PointerEventsBlocker inactive={inactive}>{children}</PointerEventsBlocker>
  </StyledSubstationContainer>
);
