import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, lightTheme, KeyboardProvider, KeyboardOverlay } from '@get-fabric/fabric-design-system';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { PrivateRoute } from '../shared/components/PrivateRoute';
import { LogoLoadingPage } from '../shared/components/LogoLoadingPage';
import { LDProvider as LDProviderPromise } from '../shared/providers/LaunchDarklyProvider';
import { Auth0Provider } from '../shared/providers/Auth0Provider';
import { Toast } from '../shared/components/Toast';
import i18n from '../shared/services/i18n/i18n';
import { errorHandler, errorFallback } from '../framework/ErrorHandler';
import { initMixpanel } from '../framework/mixpanel';
import { Touchpoint } from './Touchpoint/Touchpoint';
import { TouchpointSelectionPage } from './TouchpointSelection';

const TouchpointContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LDProvider = React.lazy(async () => ({
  default: await LDProviderPromise(),
}));

initMixpanel();

export const queryClient = new QueryClient();

export const App = () => (
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={lightTheme}>
      <Suspense fallback={<LogoLoadingPage />}>
        <LDProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Auth0Provider>
                <RecoilRoot>
                  <ErrorBoundary FallbackComponent={errorFallback} onError={errorHandler}>
                    <TouchpointContainer>
                      <KeyboardProvider>
                        <Switch>
                          <PrivateRoute path={'/'} component={TouchpointSelectionPage} exact />
                          <PrivateRoute path={'/:id'} component={Touchpoint} />
                        </Switch>
                        <Toast position={toast.POSITION.TOP_CENTER} pauseOnFocusLoss />
                        <KeyboardOverlay />
                      </KeyboardProvider>
                    </TouchpointContainer>
                  </ErrorBoundary>
                </RecoilRoot>
              </Auth0Provider>
            </Router>
          </QueryClientProvider>
        </LDProvider>
      </Suspense>
    </ThemeProvider>
  </I18nextProvider>
);
