import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import { useCurrentTotes } from '../../../hooks/useCurrentTotes';

export const otherToteType = { name: 'otherType', partitions: 0, toteHeight: 0 };

export const useToteTypes = () => {
  const totes = useCurrentTotes();

  return useMemo(
    () =>
      uniqBy(
        [
          ...totes
            .map((tote) => ({ partitions: tote.bins.length, toteHeight: tote.height }))
            .map(({ partitions, toteHeight }) => ({
              name: `${partitions}_${toteHeight}`,
              partitions,
              toteHeight,
            })),
          otherToteType,
        ],
        (tote) => tote.name,
      ),
    [totes],
  );
};
