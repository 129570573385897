import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import type { StationUpdates } from '@get-fabric/live-station-api-types';
import { ModeName } from '@get-fabric/station-api-types';
import { SocketEvents as StationSocketEvents } from '@get-fabric/live-station-api-types';
import { SocketEvents as SessionSocketEvents } from '@get-fabric/live-session-api-types';
import type { SessionUpdates } from '@get-fabric/live-session-api-types';
import { ModalRoot } from '@get-fabric/fabric-design-system';
import { useLaunchDarklyIdentification, useInitializeHandScanner } from '../../shared/hooks';
import { Content } from '../../shared/components/Content';
import { TopNav } from '../../shared/components/TopNav';
import { LogoLoadingPage } from '../../shared/components/LogoLoadingPage';
import { stationAtom, sessionAtom } from '../../shared/state';
import {
  emitter as stationEmitter,
  init as initLiveStationApi,
  subscribe as subscribeToStationUpdates,
} from '../../shared/clients/liveStationApi';
import { logger } from '../../shared/clients/loggingApi';
import {
  emitter as sessionEmitter,
  init as initLiveSessionApi,
  subscribe as subscribeToSessionUpdates,
} from '../../shared/clients/liveSessionApi';
import { LiveAllocationProvider } from '../../shared/providers/LiveAllocationsProvider';
import { ActionProvider } from '../../shared/providers/ActionsProvider';
import { PlcErrorModal } from '../../shared/components/PlcErrorModal';
import { Idle } from './Idle';
import { Decant } from './Decant';
import { PickingOut } from './PickingOut';
import { Qc } from './Qc';
import { Merge } from './Merge';
import { ExtractStock } from './ExtractStock';
import { PickToTray } from './PickToTray/components/PickToTray';

export const tpModeComponents = {
  [ModeName.Decant]: Decant,
  [ModeName.PickingOut]: PickingOut,
  [ModeName.PickToTray]: PickToTray,
  [ModeName.Idle]: Idle,
  [ModeName.Qc]: Qc,
  [ModeName.Merge]: Merge,
  [ModeName.ExtractStock]: ExtractStock,
};

export const Touchpoint = () => {
  const [station, setStation] = useRecoilState(stationAtom);
  const setSession = useSetRecoilState(sessionAtom);
  const { id: stationId } = useParams<{ id: string }>();
  const modeName = station?.mode.current.name;
  const Mode = useMemo(() => modeName && tpModeComponents[modeName], [modeName]);
  const resetSession = useResetRecoilState(sessionAtom);
  const resetStation = useResetRecoilState(stationAtom);
  const identifiedLD = useLaunchDarklyIdentification(station);

  const resetRecoil = useCallback(() => {
    resetSession();
    resetStation();
  }, [resetSession, resetStation]);

  useInitializeHandScanner();

  useEffect(() => {
    const disposeLiveStationApi = initLiveStationApi();
    const disposeLiveSessionApi = initLiveSessionApi();

    const unsubscribeStation = subscribeToStationUpdates(stationId);
    const unsubscribeSession = subscribeToSessionUpdates(stationId);

    const unsubscribeStationEmitter = stationEmitter.on(StationSocketEvents.StationUpdates, (update: StationUpdates) => {
      setStation(update.value);
    });

    const unsubscribeSessionEmitter = sessionEmitter.on(SessionSocketEvents.SessionUpdates, (update: SessionUpdates) => {
      setSession(update.value);
    });

    return () => {
      unsubscribeStationEmitter();
      unsubscribeSessionEmitter();
      unsubscribeStation();
      unsubscribeSession();
      disposeLiveStationApi();
      disposeLiveSessionApi();
      resetRecoil();
    };
  }, [setStation, setSession, stationId, resetRecoil]);

  useEffect(() => {
    logger.addContext({ stationId: station?.id, mfcId: station?.mfcId });

    return () => {
      logger.removeContext('stationId');
      logger.removeContext('mfcId');
    };
  }, [station?.id, station?.mfcId]);

  if (!identifiedLD || !station) {
    return <LogoLoadingPage />;
  }

  return (
    <LiveAllocationProvider>
      <ActionProvider>
        <TopNav />
        <ModalRoot>
          <PlcErrorModal />
          <Content>{Mode && <Mode />}</Content>
        </ModalRoot>
      </ActionProvider>
    </LiveAllocationProvider>
  );
};
