import type { FunctionComponent } from 'react';
import React from 'react';
import { Button, Flex, Modal, ModalBody, ModalFooter, ModalHeader, Text } from '@get-fabric/fabric-design-system';
import ReactJson from 'react-json-view';
import { useRecoilValue } from 'recoil';
import { Divider } from '@mui/material';
import { stationState } from '../../../../state';
import { StationHistory } from './StationHistory';
import { SubstationsState } from './SubstationsState';

interface StationInfoModalProps {
  open: () => void;
  close: () => void;
  modalOpen: boolean;
}

export const StationInfoModal: FunctionComponent<StationInfoModalProps> = ({ modalOpen, close }) => {
  const station = useRecoilValue(stationState);

  return (
    <Modal open={modalOpen} onRequestClose={close}>
      <ModalHeader>{'Station info'}</ModalHeader>
      <ModalBody>
        <Flex flexDirection={'column'} gap={4} fullWidth>
          <Text size={28}>{'Totes:'}</Text>
          <SubstationsState substations={station.substations} />
          <Divider />
          <Text size={28}>{'Current JSON:'}</Text>
          <ReactJson src={station} collapsed />
          <Divider />
          <Text size={28}>{'History:'}</Text>
          <StationHistory station={station} />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} variant={'secondary'} size={'xs'}>
          {'Close'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
