import type { FunctionComponent } from 'react';
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';

interface StationInfoButtonProps {
  onClick: () => void;
}

export const StationInfoButton: FunctionComponent<StationInfoButtonProps> = ({ onClick }) => (
  <Tooltip title={'Show station info'}>
    <IconButton onClick={onClick} aria-label={'station info'}>
      <BabyChangingStationIcon />
    </IconButton>
  </Tooltip>
);
