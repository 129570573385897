import { Configuration, MotionApi } from '@get-fabric/motion-api-client';
import { enhancedFetch } from '../../framework/enhancedFetch';
import { motionApiBasePath } from '../../framework/environment';

const motionApiConfiguration = new Configuration({
  basePath: `${motionApiBasePath}/v1`,
  fetchApi: enhancedFetch,
});

export const motionApi = new MotionApi(motionApiConfiguration);
