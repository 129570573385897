import { removeCurrentOrderTote } from '../../../../shared/clients/stationApi';
import { toteApi } from '../../../../shared/clients/wmsApi';

export const releaseOrderHolder = async ({
  position,
  stationId,
  orderToteId,
  mfcId,
  correlationId,
}: {
  position: string;
  stationId: string;
  orderToteId: string;
  mfcId: string;
  correlationId: string;
}) => {
  const orderTote = await toteApi.getTote(orderToteId, mfcId);

  if (orderTote.bins[0]?.content[0]?.quantity) {
    await toteApi.totePickingDone(orderToteId, { payload: { mfcId, stationId }, meta: { correlationId } });
  }

  return removeCurrentOrderTote(position, stationId, correlationId);
};
