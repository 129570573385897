import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { Button, Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';
import { useAsync, useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { allocationsApi } from '../../clients/allocationApi';
import type { Tote } from '../../state';
import { mfcIdState } from '../../state';
import { ProductCard } from '../ProductCard';
import { SubstationToteBins } from '../SubstationToteBins';
import { productsApi } from '../../clients/wmsApi';
import { OrderTote } from '../OrderTote/OrderTote';
import { InstructionContainer } from '../InstructionContainer';
import { logger } from '../../clients/loggingApi';
import { ActionBar } from '../../../App/Touchpoint/PickingOut/components/shared/ActionBar';

interface TaskCancelledProps {
  allocation: Allocation;
  tote: Tote;
  orderTote: Tote;
  position: 'left' | 'right';
}

export const TaskCancelled: FunctionComponent<TaskCancelledProps> = ({ allocation, tote, orderTote, position }) => {
  const mfcId = useRecoilValue(mfcIdState);
  const { t } = useTranslation();

  const { loading: fetchingProduct, value: product } = useAsync(async () => {
    if (!allocation?.actionId) {
      return;
    }

    try {
      return await productsApi.getPickingActionProduct(allocation.actionId, mfcId, uuid());
    } catch (error) {
      logger.error('failed to get product for canceling picking action', {
        error,
        actionId: allocation.actionId,
      });
      toast.error(t('pickingOut.failedToGetProductDetails'));
    }
  }, [allocation?.actionId]);

  const quantity = useMemo(
    () => orderTote.bins[0].content.find((binContent) => binContent.product.sku === product?.sku)?.quantity,
    [product, orderTote.bins],
  );

  const [{ loading: cancellingAllocations }, cancelAllocation] = useAsyncFn(async () => {
    try {
      await allocationsApi.updateByAllocationId(allocation.id, { payload: { status: AllocationStatus.Cancelled } });
    } catch (error) {
      logger.error('failed to cancel allocation', {
        error,
        allocationId: allocation.id,
      });
      toast.error(t('pickingOut.taskCancelled.failedToCancelAllocation'));
    }
  }, [t, allocation]);

  return !fetchingProduct ? (
    <Flex flexDirection={'column'} fullWidth>
      <InstructionContainer flexDirection={'column'}>
        <ActionBar>
          <OrderTote orderToteId={orderTote.id} />
        </ActionBar>
      </InstructionContainer>
      <Flex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={30}>
        <Text weight={500} size={40}>
          {t('pickingOut.taskCancelled.title')}
        </Text>
        <Flex flexDirection={'column'}>
          <Text weight={400} size={34} textAlign={'center'}>
            {t('pickingOut.taskCancelled.verifyOrderToteContent', {
              count: quantity ?? 0,
            })}
          </Text>
          <Text weight={400} size={34} textAlign={'center'}>
            {t('pickingOut.taskCancelled.returnExtraItems')}
          </Text>
        </Flex>
        <Flex alignItems={'center'} gap={50}>
          <ProductCard product={product!} />
          <SubstationToteBins
            toteId={tote.id}
            bins={tote.bins}
            orientation={tote.orientation}
            position={position}
            selectedBin={allocation.binId}
          />
        </Flex>
        <Button loading={cancellingAllocations} onClick={cancelAllocation} size={'l'}>
          {t('actions.confirm')}
        </Button>
      </Flex>
    </Flex>
  ) : (
    <Flex fullWidth alignItems={'center'} justifyContent={'center'}>
      <Spinner size={'l'} />
    </Flex>
  );
};
