import type { FunctionComponent } from 'react';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { OrderToteContent } from './OrderToteContent';

interface ToteContentModalProps {
  open: boolean;
  onClose: () => void;
  toteId: string;
}

export const OrderToteContentModal: FunctionComponent<ToteContentModalProps> = ({ open, onClose, toteId }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onRequestClose={onClose} size={'s'}>
      <ModalHeader>{t('orderToteContentModal.title', { toteId })}</ModalHeader>
      <ModalBody>
        <OrderToteContent toteId={toteId} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={'secondary'} size={'xs'}>
          {t('actions.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
