import { useEffect } from 'react';
import { useMap } from 'react-use';
import type { DecantActionProduct } from '@get-fabric/wms-api-client';
import type { ProductProperties } from '../productProperties';
import { getProductProperties } from '../productProperties';

export interface ProductFormProperties {
  scannable?: ProductProperties['scannable'];
  maxItemsPerBin?: ProductProperties['maxItemsPerBin'];
  toteType?: {
    name: string;
    partitions: ProductProperties['partitions'];
    toteHeight: ProductProperties['toteHeight'];
  };
}

const getFormProperties = (product: DecantActionProduct): ProductFormProperties => {
  const { scannable, maxItemsPerBin, partitions, toteHeight } = getProductProperties(product);

  return {
    scannable,
    maxItemsPerBin,
    toteType:
      partitions && toteHeight
        ? {
            name: `${partitions}_${toteHeight}`,
            partitions,
            toteHeight,
          }
        : undefined,
  };
};

export const useProductFormProperties = (product: DecantActionProduct) => {
  const [properties, { set, remove, setAll }] = useMap<ProductFormProperties>(getFormProperties(product));

  useEffect(() => {
    setAll(getFormProperties(product));
  }, [product, setAll]);

  return [properties, set, remove] as const;
};
