import type { FunctionComponent } from 'react';
import React, { useEffect, useRef } from 'react';
import { List } from '@mui/material';
import type { ScanRecord } from './scan';
import { ScanRecordListItem } from './ScanRecordListItem';

interface ScanListProps {
  scans: ScanRecord[];
  onScanSelect: (scanRecord: ScanRecord) => void;
  selectedRecordId?: string;
}

export const ScanList: FunctionComponent<ScanListProps> = ({ scans, onScanSelect, selectedRecordId }) => {
  const scanRecordRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedRecordId || !scanRecordRef.current) {
      return;
    }

    scanRecordRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [selectedRecordId]);

  return (
    <List role={'list'}>
      {scans
        .sort((scan1, scan2) => scan2.date.getTime() - scan1.date.getTime())
        .map((scanRecord) => {
          const selected = scanRecord.id === selectedRecordId;

          return (
            <ScanRecordListItem
              key={scanRecord.id}
              ref={selected ? scanRecordRef : null}
              scanRecord={scanRecord}
              selected={selected}
              onClick={onScanSelect}
            />
          );
        })}
    </List>
  );
};
