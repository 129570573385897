import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { Collapse, List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import styled from 'styled-components';
import type { Product } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface ToteContentModalProps {
  product: Product;
  quantity: number;
}

export const StyledProductImage = styled.img`
  max-height: 80px;
  max-width: 80%;
  flex-grow: 0;
`;

export const OrderToteListItem: FunctionComponent<ToteContentModalProps> = ({ product, quantity }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const showMoreInfo = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  return (
    <>
      <ListItemButton role={'listitem'} key={product.sku} onClick={showMoreInfo}>
        <Flex justifyContent={'space-between'} alignItems={'center'} fullWidth>
          <Flex>
            <ListItemAvatar>
              <StyledProductImage src={product.imageUrl} />
            </ListItemAvatar>
            <Flex flexDirection={'column'}>
              <ListItemText primary={<Text size={22}>{t('orderToteListItem.productName', { name: product.name })}</Text>} />
              <ListItemText primary={<Text size={18}>{t('orderToteListItem.productQuantity', { quantity })}</Text>} />
            </Flex>
          </Flex>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Flex>
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List disablePadding>
          <Flex flexDirection={'column'} pl={10}>
            <ListItemText primary={<Text size={16}>{t('orderToteListItem.productSku', { sku: product.sku })}</Text>} />
            <ListItemText primary={<Text size={16}>{t('orderToteListItem.productBarcodes', { barcodes: product.barcodes })}</Text>} />
            {product.expiryDate && (
              <ListItemText
                primary={
                  <Text size={16}>
                    {t('orderToteListItem.expiryDate', { expiryDate: format(product.expiryDate, t('date.expirationFormat')) })}
                  </Text>
                }
              />
            )}
            {product.bestBeforeDate && (
              <ListItemText
                primary={
                  <Text size={16}>
                    {t('orderToteListItem.bestBeforeDate', { bestBeforeDate: format(product.bestBeforeDate, t('date.expirationFormat')) })}
                  </Text>
                }
              />
            )}
          </Flex>
        </List>
      </Collapse>
    </>
  );
};
