import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { StyledBinContent } from './ToteContentBins.styles';
import { BinBrief } from './BinBrief';

interface BinContentProps {
  imageUrl?: string;
  quantity?: number;
  name?: string;
  id: string;
  onSelect: (selectedBinId: string) => void;
  masked?: boolean;
}

export const BinContent: FunctionComponent<BinContentProps> = ({ imageUrl, quantity, name, id, onSelect, ...props }) => {
  const selectBin = useCallback(() => {
    onSelect(id);
  }, [id, onSelect]);

  return (
    <StyledBinContent imageUrl={imageUrl} onClick={selectBin} {...props}>
      {quantity ? <BinBrief quantity={quantity} name={name} /> : null}
    </StyledBinContent>
  );
};
