import styled, { css } from 'styled-components';

const width = 650;
const height = 650;

const selectedStyle = css`
  background-color: ${({ theme }) => theme.colors.purple[700]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[700]};
  }
`;

const yearStyle = css`
  // spacing for the year buttons
  .MuiYearPicker-root {
    margin-left: 30px;
    gap: 60px;
  }

  // re-size the year buttons
  .PrivatePickersYear-yearButton {
    width: 150px;
    height: 75px;
    border-radius: 66px;
    font-size: 1.5rem;
  }

  .Mui-selected.PrivatePickersYear-yearButton {
    ${selectedStyle}
  }
`;

const monthCounterDisplay = css`
  text-indent: 5px;
  counter-increment: monthView 1;
  content: '(' counter(monthView) ')';
`;

const monthStyle = css`
  // spacing for the month buttons
  .MuiMonthPicker-root {
    gap: 60px;
    width: ${width - 60}px;
    margin-left: 30px;
    counter-reset: monthView;
  }

  // re-size the month buttons
  .PrivatePickersMonth-root {
    flex: 1 0 19%;
    height: 75px;
    border-radius: 65px;
    font-size: 1.5rem;
  }

  .MuiTypography-root.MuiTypography-subtitle1.PrivatePickersMonth-root::after {
    ${monthCounterDisplay}
  }

  .Mui-selected.PrivatePickersMonth-root {
    ${selectedStyle}
  }

  .Mui-selected.PrivatePickersMonth-root::after {
    ${monthCounterDisplay}
  }
`;

const dayStyle = css`
  // spacing for the day buttons
  // caution for future changes: reminder to check months with six calendar-weeks (e.x. 31 May 2026)
  .PrivatePickersSlideTransition-root {
    height: 483px;
    overflow: hidden;

    > div > div {
      gap: 8px;
      margin: 5px 0;
    }
  }

  // re-size the day buttons
  .MuiPickersDay-dayWithMargin,
  .MuiTypography-caption {
    width: 75px;
    height: 75px;
    font-size: 1.75rem;
    font-weight: 400;
  }

  // header days of week
  .MuiTypography-caption {
    margin: 0 6px;
  }

  // header arrow icons
  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }

  .MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin {
    ${selectedStyle}
  }
`;

export const StyledDatePicker = styled.div`
  width: ${width}px;

  .MuiPickerStaticWrapper-root {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    height: ${height}px;
  }

  .MuiPickerStaticWrapper-root > div > div {
    width: ${width}px;
    max-height: ${height}px;
  }

  .MuiCalendarOrClockPicker-root > div {
    width: 100%;
    max-height: 100%;
  }

  .MuiCalendarPicker-root {
    width: ${width}px;
    margin: unset;
    max-height: unset;

    > div {
      min-height: 60px;
      margin-bottom: 16px;
      overflow: hidden;

      > div {
        font-weight: 400;
        font-size: 1.5rem;
      }
    }
  }

  ${yearStyle}
  ${monthStyle}
  ${dayStyle}
`;
