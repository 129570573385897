import { Menu, MenuItem } from '@mui/material';
import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

interface UserMenuProps {
  open: boolean;
  close: () => void;
  anchorEl: Element | null;
}

export const UserMenu: FunctionComponent<UserMenuProps> = ({ open, close, anchorEl }) => {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const logoutUser = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <Menu open={open} anchorEl={anchorEl} onClose={close}>
      <MenuItem onClick={logoutUser}>{t('actions.logout')}</MenuItem>
    </Menu>
  );
};
