import type { FunctionComponent } from 'react';
import React from 'react';
import { ModalBody, ModalFooter, ModalHeader, Button, Modal } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { useRecoilValue } from 'recoil';
import { StationType } from '@get-fabric/motion-api-client';
import { toast } from 'react-toastify';
import { motionApi } from '../clients/motionApi';
import { mfcIdState, stationNameState, substationsWithSafetyErrorState } from '../state';

const WAIT_AFTER_RESET_TIME_MS = 2000;

export const PlcErrorModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const mfcId = useRecoilValue(mfcIdState);
  const stationName = useRecoilValue(stationNameState);
  const substationsWithSafetyError = useRecoilValue(substationsWithSafetyErrorState);

  const [{ loading }, resetPlc] = useAsyncFn(async () => {
    try {
      await motionApi.resetPlc(mfcId, StationType.Touchpoints, stationName);
      await new Promise((resolve) => setTimeout(resolve, WAIT_AFTER_RESET_TIME_MS));
    } catch {
      toast.error(t('plcErrorModal.resetFailure'), { toastId: 'resetPlcFailure' });

      return;
    }
  }, [mfcId, stationName, t]);

  return (
    <Modal open={!!substationsWithSafetyError.length} closeOnOverlayClick={false} data-testid={'PlcErrorModal'}>
      <ModalHeader>{t('plcErrorModal.title')}</ModalHeader>
      <ModalBody>
        {substationsWithSafetyError.length > 1
          ? t('plcErrorModal.bothSidesInstructions')
          : t('plcErrorModal.oneSideInstructions', { substationName: substationsWithSafetyError[0] })}
      </ModalBody>
      <ModalFooter>
        <Button variant={'primary'} size={'s'} onClick={resetPlc} loading={loading}>
          {t('plcErrorModal.resetButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
