import { Flex } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useActiveSession, useModalState } from '../../../../shared/hooks';
import type { Tote } from '../../../../shared/state';
import { mfcIdState, stationIdState, substationOverflowState } from '../../../../shared/state';
import { logger } from '../../../../shared/clients/loggingApi';
import { merge, skipMerge } from '../services/merge';
import { Substations } from './Substations';
import { Instructions } from './Instructions';
import { SkipMoveModal } from './SkipMoveModal';

interface MergeInstructionsProps {
  sourceBin: Bin;
  targetBinId: string;
  sourceBinId: string;
  sourceTote: Tote;
  targetTote: Tote;
  allocationId: string;
}

export const MergeInstructions: FunctionComponent<MergeInstructionsProps> = ({
  sourceBinId,
  targetBinId,
  sourceBin,
  sourceTote,
  targetTote,
  allocationId,
}) => {
  const [stationId, mfcId] = [useRecoilValue(stationIdState), useRecoilValue(mfcIdState)];
  const { t } = useTranslation();
  const targetOverflow = useRecoilValue(substationOverflowState('right'));
  const sourceOverflow = useRecoilValue(substationOverflowState('left'));
  const { activeSession } = useActiveSession();
  const { modalOpen, close, open } = useModalState();
  const product = useMemo(() => sourceBin.content[0]?.product, [sourceBin]);

  const [{ loading: confirmationInProgress }, confirmAll] = useAsyncFn(async () => {
    if (!activeSession) {
      toast.error(t('merge.notActiveSession'));

      return;
    }
    if (targetOverflow) {
      toast.error(t('merge.overflow.target'));

      return;
    }

    if (sourceOverflow) {
      toast.error(t('merge.overflow.source'));

      return;
    }

    if (!product) {
      toast.error(t('merge.missingProduct'));

      return;
    }

    try {
      await merge(allocationId, stationId, sourceBinId, targetBinId, mfcId);
    } catch (error) {
      toast.error(t('merge.failed'), { toastId: 'completeMerge' });
      logger.error('failed performing merge operation', { allocationId, error, sourceBinId, targetBinId, product });
    }
  }, [allocationId, sourceOverflow, targetOverflow, activeSession, product, mfcId, sourceBinId, stationId, t, targetBinId]);

  const confirmSkip = useCallback(async () => {
    if (!activeSession) {
      toast.error(t('merge.notActiveSession'));

      return;
    }
    if (targetOverflow) {
      toast.error(t('merge.overflow.target'));

      return;
    }

    if (sourceOverflow) {
      toast.error(t('merge.overflow.source'));

      return;
    }

    if (!product) {
      toast.error(t('merge.missingProduct'));

      return;
    }

    try {
      await skipMerge(allocationId);
    } catch (error) {
      toast.error(t('merge.failedSkip'), { toastId: 'completeMerge' });
      logger.error('failed to skip merge operation', { allocationId, error });
    }
  }, [activeSession, allocationId, product, sourceOverflow, t, targetOverflow]);

  return (
    <>
      <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={45} fullWidth>
        <Instructions loading={confirmationInProgress} product={product} confirm={confirmAll} skip={open} />
        <Substations sourceTote={sourceTote} targetTote={targetTote} targetBinId={targetBinId} sourceBinId={sourceBinId} />
      </Flex>
      <SkipMoveModal open={modalOpen} close={close} submit={confirmSkip} sourceBin={sourceBin} />
    </>
  );
};
