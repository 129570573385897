import type { FunctionComponent } from 'react';
import React from 'react';
import Schedule from '@mui/icons-material/Schedule';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import type { DecantTask } from '@get-fabric/action-api-client';

interface TaskDetailsProps {
  task: DecantTask;
  status: string;
}

const Title = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ClockIcon = styled(Schedule)`
  color: #bbbbbb;
`;

const getTimeText = (days) => {
  if (days === 0) {
    return i18next.t('decant.taskSelectionModal.taskOption.time.today');
  } else if (days === 1) {
    return i18next.t('decant.taskSelectionModal.taskOption.time.yesterday');
  }

  return i18next.t('decant.taskSelectionModal.taskOption.time.daysAgo', { days });
};

export const TaskDetails: FunctionComponent<TaskDetailsProps> = ({ task, status }) => {
  const { t } = useTranslation();
  const { id, supplierName, createdAt } = task.asn;
  const days = Math.floor((Date.now() - +createdAt) / (1000 * 60 * 60 * 24));

  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} gap={2} pb={2}>
        <ClockIcon />
        <Text size={14} color={'gray.700'}>
          {getTimeText(days)}
        </Text>
      </Flex>
      <Title size={34} pb={3}>
        {supplierName ?? t('decant.taskSelectionModal.taskOption.supplierNameFallback')}
      </Title>
      <Flex alignItems={'center'} justifyContent={'space-between'} gap={4}>
        <Text size={14} color={'gray.700'}>{`#${id ?? t('decant.taskSelectionModal.taskOption.asnIdFallback')}`}</Text>
        <Text size={14} weight={700} color={'purple.700'} capitalize>
          {t(`taskStatus.${status}`)}
        </Text>
      </Flex>
    </Flex>
  );
};
