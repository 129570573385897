import { Flex, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React from 'react';

interface FormFieldProps {
  text: string;
}

export const FormField: FunctionComponent<FormFieldProps> = ({ text, children }) => (
  <Flex flexDirection={'column'} gap={4}>
    <Text size={28}>{text}</Text>
    {children}
  </Flex>
);
