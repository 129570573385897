import { Flex } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useRecoilAsync, stationIdState, toteByPositionState, mfcIdState } from '../../../../shared/state';
import { ActionContext } from '../providers/ActionProvider';
import { exceptionApi } from '../../../../shared/clients/wmsApi';
import { Loading } from '../../../../shared/components/Loading';
import { useModalState } from '../../../../shared/hooks';
import { wmsErrorHandler, wmsErrorTypes } from '../../../../shared/components/Exceptions/wmsErrorHandler';
import { ExpirationDateModal } from './ExpirationDateModal';
import { YesNoQuestionModal } from './YesNoQuestionModal';
import { YesNoQuestion } from './YesNoQuestion';

interface Props {
  binId: string;
  onComplete: (reportedExpiryDate?: Date) => void;
  onException: () => void;
}

export const CheckExpirationDate: FunctionComponent<Props> = ({ binId, onComplete, onException }) => {
  const { t } = useTranslation();
  const {
    id: actionId,
    task: { blindCheck },
  } = useContext(ActionContext);
  const mfcId = useRecoilValue(mfcIdState);
  const stationId = useRecoilValue(stationIdState);
  const { data: tote } = useRecoilAsync(toteByPositionState('right'));
  const selectDateModal = useModalState();
  const confirmPastDateModal = useModalState();
  const bin = useMemo(() => tote?.bins.find((b) => b.id === binId), [tote, binId]);
  const originalExpiryDate = useMemo(() => new Date(bin?.content?.[0]?.product.expiryDate ?? 0), [bin]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const setExpiryDate = useCallback(
    async (date: Date) => {
      if (!bin) {
        return;
      }

      if (blindCheck) {
        onComplete(date);

        return;
      }

      if (date === originalExpiryDate) {
        onComplete();

        return;
      }

      try {
        await exceptionApi.wrongExpirationDate({
          payload: {
            exception: {
              sku: bin.content[0].product.sku,
              binId: bin.id,
              expirationDate: date.getTime(),
              companyCode: bin.content[0].product.companyCode,
            },
            mfcId,
            stationId,
            actionId,
          },
        });
      } catch (error) {
        try {
          wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed to report wrong expiration date', {
            bin,
            actionId,
          });
        } catch {
          toast.error(t('qc.failedUpdatingExpiryDate'));
        }

        return;
      }
      onException();
    },
    [bin, originalExpiryDate, onComplete, onException, t, mfcId, stationId, actionId, blindCheck],
  );

  const onSelectExpiryDate = useCallback(
    async (date: Date) => {
      setSelectedDate(date);

      if (date < new Date()) {
        confirmPastDateModal.open();
      } else {
        await setExpiryDate(date);
        selectDateModal.close();
      }
    },
    [setSelectedDate, confirmPastDateModal, selectDateModal, setExpiryDate],
  );

  const onPastDateConfirmed = useCallback(async () => {
    await setExpiryDate(selectedDate);
    confirmPastDateModal.close();
    selectDateModal.close();
  }, [selectedDate, confirmPastDateModal, selectDateModal, setExpiryDate]);

  if (!bin) {
    return <Loading />;
  }

  return (
    <>
      {blindCheck ? null : (
        <Flex flexDirection={'column'} alignItems={'center'} gap={60}>
          <YesNoQuestion
            question={t('qc.expiryDateQuestion', { expiryDate: format(originalExpiryDate, 'dd MMM yyyy') })}
            onYes={onComplete}
            onNo={selectDateModal.open}
          />
        </Flex>
      )}
      <ExpirationDateModal
        open={blindCheck || selectDateModal.modalOpen}
        close={selectDateModal.close}
        onClickConfirm={onSelectExpiryDate}
      />
      <YesNoQuestionModal
        open={confirmPastDateModal.modalOpen}
        onNo={confirmPastDateModal.close}
        onYes={onPastDateConfirmed}
        question={t('qc.pastExpiryDateSelected')}
      />
    </>
  );
};
