import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import { VerticalDivider } from '../../../../shared/components/Substations';
import { useActiveSubstationState } from '../../../../shared/hooks';
import { Substation } from './Substation';

export const PickToTray: FunctionComponent = () => {
  const { activeSubstation, setLeftReadySubstation, setRightReadySubstation } = useActiveSubstationState();

  return (
    <Flex flexGrow={1} flexShrink={0} flexBasis={'auto'} fullWidth>
      <Substation position={'left'} activeSubstation={activeSubstation} setReady={setLeftReadySubstation} />
      <VerticalDivider />
      <Substation position={'right'} activeSubstation={activeSubstation} setReady={setRightReadySubstation} />
    </Flex>
  );
};
