import type { Action } from '@get-fabric/action-api-client';
import type { FunctionComponent } from 'react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { sortBy, uniq } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { logger } from '../clients/loggingApi';
import { getActions } from '../clients/actionApi';
import { LiveAllocationContext } from './LiveAllocationsProvider';

interface ActionContextProps {
  actions: Action[];
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const ActionContext = React.createContext<ActionContextProps>(undefined as any);

export const ActionProvider: FunctionComponent = ({ children }) => {
  const { allocations: allAllocations } = useContext(LiveAllocationContext);
  const actionIds = useMemo<string[]>(() => uniq(allAllocations.map((a) => a.actionId).filter(Boolean) as string[]), [allAllocations]);
  const [actions, setActions] = useState<Action[]>([]);
  const { status, data, error } = useQuery(['actions', actionIds], () => (actionIds.length ? getActions(actionIds, uuid()) : []));

  useEffect(() => {
    if (status === 'loading') {
      logger.info('Loading actions', { actionIds });
    } else if (status === 'error') {
      logger.error('Error loading actions', { actionIds, error });
    } else {
      setActions(data);
    }
  }, [status, data, setActions, actionIds, error]);

  const actionsState = useMemo(
    () => ({
      actions: sortBy(actions, ({ id }) => id),
    }),
    [actions],
  );

  return <ActionContext.Provider value={actionsState}>{children}</ActionContext.Provider>;
};
