import React from 'react';
import { useKey } from 'react-use';
import { KeyboardInput } from '@get-fabric/fabric-design-system';

interface BarcodeInputProps {
  value: string;
  onChange: (value: string) => void;
  onEnter: () => void;
}

export const BarcodeInput = React.forwardRef<HTMLInputElement, BarcodeInputProps>(({ value, onChange, onEnter }, ref) => {
  useKey(
    'Enter',
    (e) => {
      e.preventDefault();
      onEnter();
    },
    {},
    [onEnter],
  );

  return <KeyboardInput ref={ref} value={value} onChange={onChange} placeholder={'Enter barcode'} aria-label={'barcode'} />;
});
