import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import type { ExceptionType } from '../exceptionTypes';

interface ExceptionsModalContextProps {
  exception: ExceptionType | null;
  resetExceptionState: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const ReportExceptionContext = React.createContext<ExceptionsModalContextProps>(undefined as any);

interface ExceptionsModalProviderProps {
  exception: ExceptionsModalContextProps['exception'];
  resetExceptionState: ExceptionsModalContextProps['resetExceptionState'];
}

export const ReportExceptionProvider: FunctionComponent<ExceptionsModalProviderProps> = ({ exception, resetExceptionState, children }) => {
  const exceptionsModalState = useMemo(
    () => ({
      exception,
      resetExceptionState,
    }),
    [exception, resetExceptionState],
  );

  return <ReportExceptionContext.Provider value={exceptionsModalState}>{children}</ReportExceptionContext.Provider>;
};
