import { NumberInput } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormField } from './FormField';
import type { ProductFormProperties } from './useProductFormProperties';

interface MaxItemsPerBinFieldProps {
  value: ProductFormProperties['maxItemsPerBin'];
  onChange: (value: ProductFormProperties['maxItemsPerBin']) => void;
  disabled?: boolean;
}

const QuantityInput = styled(NumberInput)`
  width: 100px;
`;

export const MaxItemsPerBinField: FunctionComponent<MaxItemsPerBinFieldProps> = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <FormField text={t('decant.productPropertiesModal.maxItemPerBinSubtitle')}>
      <QuantityInput value={value ?? 0} onChange={onChange} disabled={disabled} />
    </FormField>
  );
};
