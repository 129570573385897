import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@get-fabric/fabric-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';

interface TaskIsNotActiveModalProps {
  open: boolean;
  confirm: () => Promise<void>;
}

export const TaskIsNotActiveModal = ({ open, confirm }: TaskIsNotActiveModalProps) => {
  const { t } = useTranslation();

  const [{ loading }, onConfirm] = useAsyncFn(confirm, [confirm]);

  return (
    <Modal open={open} closeOnOverlayClick={false} data-testid={'taskIsNotActiveModal'}>
      <ModalHeader>{t('decant.taskIsNotActiveModal.title')}</ModalHeader>
      <ModalBody>{t('decant.taskIsNotActiveModal.instructions')}</ModalBody>
      <ModalFooter>
        <Button variant={'primary'} size={'s'} onClick={onConfirm} loading={loading}>
          {t('actions.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
