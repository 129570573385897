import React, { useCallback, useEffect } from 'react';
import type { User } from '@auth0/auth0-react';
import { Auth0Provider as Provider, useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { v4 as uuid } from 'uuid';
import { setTokenGenerator } from '../../framework/getToken';
import { setUser } from '../clients/sessionApi';

const TokenProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return children;
};

export const Auth0Provider = ({ children }) => {
  const history = useHistory();
  const [sessionId] = useSessionStorage<string>('sessionId', uuid(), true);

  const onRedirectCallback = useCallback(
    async (appState, user?: User) => {
      history.push((appState?.returnTo as string) || window.location.pathname);

      if (user?.email) {
        await setUser(sessionId, user.email, uuid());
      }
    },
    [history, sessionId],
  );
  return (
    <Provider
      domain={'get-fabric.us.auth0.com'}
      clientId={'3gTMANRKspGyybYNQNJ8kgWoZpVJnzh9'}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      audience={'https://touchpoint'}
    >
      <TokenProvider>{children}</TokenProvider>
    </Provider>
  );
};
