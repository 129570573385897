import React, { useMemo } from 'react';
import type { ComponentType, FunctionComponent } from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LogoLoadingPage } from './LogoLoadingPage';

interface PrivateRouteProps extends Partial<RouteProps> {
  component: ComponentType;
}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ component, ...props }) => {
  const Component = useMemo(
    () =>
      withAuthenticationRequired(component, {
        onRedirecting: LogoLoadingPage,
        returnTo: window.location.pathname,
      }),
    [component],
  );

  return (
    <Route {...props}>
      <Component />
    </Route>
  );
};
