import { useContext, useMemo } from 'react';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { LiveAllocationContext } from '../../../../shared/providers/LiveAllocationsProvider';
import type { Tote } from '../../../../shared/state';

export interface ToteAllocation extends Omit<Allocation, 'binId' | 'toteId'> {
  toteId: string;
  binId: string;
}

export const useToteAllocations = (totes: Tote[]): ToteAllocation[] => {
  const toteIds = useMemo(() => totes.map(({ id }) => id), [totes]);
  const { allocations } = useContext(LiveAllocationContext);

  return useMemo(
    (): ToteAllocation[] =>
      allocations.filter(
        (allocation): allocation is ToteAllocation => !!allocation.toteId && !!allocation.binId && toteIds.includes(allocation.toteId),
      ),
    [allocations, toteIds],
  );
};

const unfulfilledAllocationStatuses = new Set([AllocationStatus.Approved, AllocationStatus.Ongoing]);

export const useUnfulfilledToteAllocations = (toteIds: string[]): ToteAllocation[] => {
  const { allocations: allAllocations } = useContext(LiveAllocationContext);

  return useMemo(
    (): ToteAllocation[] =>
      allAllocations.filter(
        (allocation): allocation is ToteAllocation =>
          unfulfilledAllocationStatuses.has(allocation.status) && toteIds.includes(allocation.toteId ?? ''),
      ) ?? [],
    [allAllocations, toteIds],
  );
};
