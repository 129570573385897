import type { FunctionComponent } from 'react';
import React from 'react';
import { Button, Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../hooks';
import { OrderToteContentModal } from './OrderToteContentModal';

interface OrderToteButtonProps {
  orderToteId: string;
}

export const OrderTote: FunctionComponent<OrderToteButtonProps> = ({ orderToteId }) => {
  const { t } = useTranslation();
  const { modalOpen, open, close } = useModalState();

  return (
    <>
      <Button size={'l'} variant={'secondary'} onClick={open}>
        <Flex flexDirection={'column'}>
          <Text textAlign={'center'} size={40}>
            {t('orderTote.orderToteButton')}
          </Text>
          <Text size={20}>{orderToteId}</Text>
        </Flex>
      </Button>
      <OrderToteContentModal open={modalOpen} onClose={close} toteId={orderToteId} />
    </>
  );
};
