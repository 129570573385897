import type { DecantAction, Meta, QcAction, Action } from '@get-fabric/action-api-client';
import { FetchHttpRequest, ActionsApi, OpenAPI } from '@get-fabric/action-api-client';
import { enhancedFetch } from '../../framework/enhancedFetch';
import { actionApiBasePath } from '../../framework/environment';

const actionApi = new ActionsApi(new FetchHttpRequest(enhancedFetch, { ...OpenAPI, BASE: `${actionApiBasePath}/v1` }));

export const getCreatedDecantActions = (mfcId: string, meta: Meta): Promise<DecantAction[]> =>
  actionApi.getActionBatch({ payload: { mfcIds: [mfcId], statuses: ['created'], taskTypes: ['decant'] }, meta }) as Promise<DecantAction[]>;

export const getQcAction = (actionId: string): Promise<QcAction> => actionApi.getAction(actionId) as Promise<QcAction>;

export const getActions = (actionIds: string[], correlationId: string) =>
  actionApi.getActionBatch({ payload: { actionIds }, meta: { correlationId } }) as Promise<Action[]>;
