import type { FunctionComponent } from 'react';
import React from 'react';
import { StyledBin, StyledCheckIcon } from './Bin.styles';

interface BinProps {
  selected: boolean;
  animated?: boolean;
  completed?: boolean;
}

export const Bin: FunctionComponent<BinProps> = ({ selected, animated, completed }) => (
  <StyledBin selected={selected} aria-selected={selected} animated={animated} role={'listitem'}>
    {completed && <StyledCheckIcon size={48} />}
  </StyledBin>
);
