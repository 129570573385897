import type { FunctionComponent } from 'react';
import React from 'react';
import type { CatalogProduct, Tote } from '@get-fabric/wms-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { ExceptionsModal } from '../../../../shared/components/Exceptions';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { PickToTrayExceptionsSteps } from './PickToTrayExceptionsSteps';

interface PickToTrayExceptionsModalProps {
  open: boolean;
  close: () => void;
  onReportException: (exception: ExceptionType) => void;
  tote: Tote;
  allocation: Allocation;
  product?: CatalogProduct;
}

const pickToTrayExceptions = [
  ExceptionType.BinIsEmpty,
  ExceptionType.WrongQuantityInBin,
  ExceptionType.DamagedItem,
  ExceptionType.WrongItemInBin,
];

export const PickToTrayExceptionsModal: FunctionComponent<PickToTrayExceptionsModalProps> = ({
  open,
  close,
  onReportException,
  tote,
  allocation,
  product,
}) => (
  <ExceptionsModal open={open} close={close} exceptions={pickToTrayExceptions}>
    <PickToTrayExceptionsSteps onReportException={onReportException} tote={tote} allocation={allocation} product={product} />
  </ExceptionsModal>
);
