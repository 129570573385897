import React, { useCallback } from 'react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Text } from '@get-fabric/fabric-design-system';
import { startOfDay, format } from 'date-fns';

export const dateFormat = 'dd MMM yyyy';

interface SuggestionButtonListProps {
  dateSuggestions: Date[];
  hasTodaySuggestion: boolean;
  enableDateMissing: boolean;
  onClickDate: (date: Date) => void;
  onClickDateMissing: () => void;
}

export const SuggestionButtonList: FunctionComponent<SuggestionButtonListProps> = ({
  dateSuggestions,
  hasTodaySuggestion,
  enableDateMissing,
  onClickDate,
  onClickDateMissing,
}) => {
  const { t } = useTranslation();
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { value } = e.currentTarget;

      onClickDate(new Date(value));
    },
    [onClickDate],
  );

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex alignSelf={'center'}>
        <Text color={'royalBlue.500'} size={28} weight={500}>
          {t('suggestionDatePicker.suggestions')}
        </Text>
      </Flex>
      <Flex alignItems={'center'} flexDirection={'column'} gap={20}>
        {dateSuggestions.map((date) => (
          <Button value={date.toString()} onClick={onClick} variant={'secondary'} key={date.toString()}>
            {format(date, dateFormat)}
          </Button>
        ))}
        {hasTodaySuggestion && (
          <Button value={startOfDay(new Date()).toString()} onClick={onClick} variant={'secondary'}>
            {t('suggestionDatePicker.today')}
          </Button>
        )}
        {enableDateMissing && (
          <Button onClick={onClickDateMissing} variant={'secondary'}>
            {t('suggestionDatePicker.dateMissing')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
