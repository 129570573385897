import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { Modal } from '@get-fabric/fabric-design-system';
import type { ExceptionType } from '../exceptionTypes';
import { ExceptionSelection } from '../ExceptionSelection';
import { ReportExceptionProvider } from './ReportExceptionProvider';

interface ExceptionsModalProps {
  open: boolean;
  close: () => void;
  exceptions: ExceptionType[];
}

export const ExceptionsModal: FunctionComponent<ExceptionsModalProps> = ({ open, close, exceptions, children }) => {
  const [exception, setException] = useState<ExceptionType | null>(null);

  const cancelException = useCallback(() => {
    setException(null);
  }, []);

  const closeModal = useCallback(() => {
    close();
    cancelException();
  }, [close, cancelException]);

  return (
    <Modal open={open} onRequestClose={closeModal} size={'xl'} data-testid={'exceptionsModal'}>
      {!exception ? (
        <ExceptionSelection exceptions={exceptions} selectException={setException} />
      ) : (
        <ReportExceptionProvider exception={exception} resetExceptionState={cancelException}>
          {children}
        </ReportExceptionProvider>
      )}
    </Modal>
  );
};
