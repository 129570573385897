import { useEffect } from 'react';
import { createHandler } from '../services/keyscanner';

export const useInitializeHandScanner = () => {
  useEffect(() => {
    const keyScanHandle = createHandler();

    return () => {
      keyScanHandle.stop();
    };
  }, []);
};
