import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useRecoilAsync, toteByPositionState } from '../../../../shared/state';
import { useSubstationToteAllocations, useAllocationsByStatus } from '../../../../shared/hooks';
import { TasksDone } from '../../../../shared/components/TasksDone';
import { Loading } from '../../../../shared/components/Loading';
import { WaitingForRobots } from '../../../../shared/components/WaitingForRobots';
import { WaitingForTotes } from '../../../../shared/components/WaitingForTotes';
import { Merge } from './Merge';

export const MergePage: FunctionComponent = () => {
  const { data: toteInSourcePosition } = useRecoilAsync(toteByPositionState('left'));
  const { data: toteInTargetPosition } = useRecoilAsync(toteByPositionState('right'));

  const { currentAllocation: currentSourceAllocation, allocations: sourceAllocations } = useSubstationToteAllocations({
    position: 'left',
    toteInPositionId: toteInSourcePosition?.id,
  });

  const { allocations: targetAllocations, currentAllocation: currentTargetAllocation } = useSubstationToteAllocations({
    position: 'right',
    toteInPositionId: toteInTargetPosition?.id,
  });

  const { allocations: ongoingAllocations } = useAllocationsByStatus({
    allocations: [...targetAllocations, ...sourceAllocations],
    statuses: [AllocationStatus.Ongoing],
  });
  const { allocations: approvedAllocations } = useAllocationsByStatus({
    allocations: [...targetAllocations, ...sourceAllocations],
    statuses: [AllocationStatus.Approved],
  });

  const currentSourceBin = useMemo(
    () => toteInSourcePosition?.bins?.find((bin) => bin.id === currentSourceAllocation?.binId),
    [toteInSourcePosition, currentSourceAllocation],
  );

  const currentTargetBin = useMemo(
    () => toteInTargetPosition?.bins?.find((bin) => bin.id === currentTargetAllocation?.binId),
    [toteInTargetPosition, currentTargetAllocation],
  );

  if (!targetAllocations.length || !sourceAllocations.length) {
    return <TasksDone />;
  }

  if (!approvedAllocations.length && !ongoingAllocations.length) {
    return <WaitingForRobots />;
  }

  if (!toteInTargetPosition || !toteInSourcePosition || !currentTargetAllocation || !currentSourceAllocation) {
    return <WaitingForTotes />;
  }

  if (!currentSourceBin || !currentTargetBin) {
    return <Loading />;
  }

  return (
    <Merge
      targetTote={toteInTargetPosition}
      sourceTote={toteInSourcePosition}
      sourceBin={currentSourceBin}
      targetBin={currentTargetBin}
      allocation={currentSourceAllocation}
    />
  );
};
