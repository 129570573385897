import type { Allocation } from '@get-fabric/allocation-api-client';
import { useContext, useMemo } from 'react';
import type { Action } from '@get-fabric/action-api-client';
import { ActionContext } from '../providers/ActionsProvider';

export interface CurrentActionProps {
  currentAllocation: Allocation;
}

export const useCurrentAction = ({ currentAllocation }: CurrentActionProps) => {
  const { actions } = useContext(ActionContext);

  return useMemo<Action | undefined>(
    () => actions.find(({ id: actionId }) => actionId === currentAllocation?.actionId),
    [actions, currentAllocation],
  );
};
