import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { ModalHeader, ModalBody } from '@get-fabric/fabric-design-system';
import type { Bin, CatalogProduct } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { mfcIdState, stationIdState } from '../../../state';
import { exceptionApi } from '../../../clients/wmsApi';
import { useDatePicker } from '../../DatePickers';
import { ExceptionType } from '../exceptionTypes';
import { ExceptionModalFooter } from '../ExceptionsModal';
import { wmsErrorHandler, wmsErrorTypes } from '../wmsErrorHandler';
import { EnterExpiryDateStep } from '../AddNewStock/EnterExpiryDateStep';
import { EnterQuantityStep } from '../AddNewStock/EnterQuantityStep';
import { getExpirationDateValue } from '../../../services/expiration';
import { ScanProductStep } from './ScanProductStep';

interface BinIsEmptyExceptionProps {
  bin: Bin;
  actionId: string | undefined;
  onReportException: (exception: ExceptionType) => void;
}

export const WrongItemInBinException: FunctionComponent<BinIsEmptyExceptionProps> = ({ bin, actionId, onReportException }) => {
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);
  const [scannedItem, setScannedItem] = useState<CatalogProduct | null>(null);
  const [quantity, setQuantity] = useState<number>(0);
  const [{ pickedDate, dateMissing }, { onClickConfirmDate, onClickConfirmDateMissing, resetDatePicker }] = useDatePicker();
  const showExpiryPicker = scannedItem?.expiryDateMandatory && !pickedDate && !dateMissing;
  const missingRequiredExpiryDate = !!scannedItem?.expiryDateMandatory && dateMissing && !scannedItem?.shelfLife;

  const onClickDateCancel = useCallback(() => {
    resetDatePicker();
    setScannedItem(null);
  }, [resetDatePicker]);

  const { t } = useTranslation();

  const changeQuantity = useCallback(
    (inputQuantity: number) => {
      setQuantity(inputQuantity);

      if (inputQuantity <= 0) {
        toast.warn(t('exceptions.wrongItemInBin.greaterThanQuantity'), { toastId: 'greaterThanQuantity' });

        return;
      }
    },
    [t],
  );

  const submitException = useCallback(async () => {
    if (!scannedItem || !quantity) {
      return;
    }

    const correlationId = uuid();

    try {
      await exceptionApi.wrongItem({
        payload: {
          exception: {
            sku: scannedItem.sku,
            binId: bin.id,
            confirmedQuantity: quantity,
            expirationDate: getExpirationDateValue(scannedItem.expiryDateMandatory && !scannedItem.shelfLife, pickedDate)?.valueOf(),
            companyCode: scannedItem.companyCode,
          },
          stationId,
          mfcId,
          actionId,
        },
        meta: {
          correlationId,
        },
      });

      if (missingRequiredExpiryDate) {
        toast.warning(t('exceptions.addNewStock.missingExpiryReported'), { toastId: 'exceptionMissingExpiryDate' });
      }

      onReportException(ExceptionType.WrongItemInBin);
    } catch (error) {
      wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed to report wrong item in bin exception', {
        actionId,
        correlationId,
      });
    }
  }, [scannedItem, bin, actionId, mfcId, stationId, quantity, onReportException, pickedDate, missingRequiredExpiryDate, t]);

  let step: React.ReactNode | null = null;

  if (!scannedItem) {
    step = <ScanProductStep onScan={setScannedItem} bin={bin} />;
  } else if (showExpiryPicker) {
    step = (
      <EnterExpiryDateStep
        onClickConfirm={onClickConfirmDate}
        onClickConfirmDateMissing={onClickConfirmDateMissing}
        onClickCancel={onClickDateCancel}
      />
    );
  } else {
    step = (
      <EnterQuantityStep
        product={scannedItem}
        quantity={quantity}
        onChangeQuantity={changeQuantity}
        expiryDate={pickedDate}
        expiryDateMandatory={!!scannedItem.expiryDateMandatory}
        onClickExpiryDate={resetDatePicker}
      />
    );
  }

  return (
    <>
      <ModalHeader>{t(`exceptions.${ExceptionType.WrongItemInBin}.title`)}</ModalHeader>
      <ModalBody>{step}</ModalBody>
      {!showExpiryPicker && <ExceptionModalFooter reportException={submitException} disableReport={!quantity || !scannedItem} />}
    </>
  );
};
