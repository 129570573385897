import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import { BinIsEmptyException, ReportExceptionContext, WrongItemInBinException } from '../../../../shared/components/Exceptions';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';

interface ExtractStockExceptionsStepsProps {
  onReportException: (exception: ExceptionType) => void;
  bin: Bin;
  actionId: string;
}

export const ExtractStockExceptionsSteps: FunctionComponent<ExtractStockExceptionsStepsProps> = ({ onReportException, bin, actionId }) => {
  const { exception } = useContext(ReportExceptionContext);

  switch (exception) {
    case ExceptionType.BinIsEmpty: {
      return <BinIsEmptyException bin={bin} onReportException={onReportException} actionId={actionId} />;
    }
    case ExceptionType.WrongItemInBin: {
      return <WrongItemInBinException bin={bin} actionId={actionId} onReportException={onReportException} />;
    }
    default: {
      return null;
    }
  }
};
