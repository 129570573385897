import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@get-fabric/fabric-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CantReplaceToteModalProps {
  open: boolean;
  confirm: () => void;
}

export const CantReplaceToteModal = ({ open, confirm }: CantReplaceToteModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} closeOnOverlayClick={false} data-testid={'cantReplaceToteModal'}>
      <ModalHeader>{t('decant.cantReplaceToteModal.title')}</ModalHeader>
      <ModalBody>{t('decant.cantReplaceToteModal.instructions')}</ModalBody>
      <ModalFooter>
        <Button variant={'primary'} size={'s'} onClick={confirm}>
          {t('actions.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
