import { useEffect, useState } from 'react';

export const useActiveSubstationState = () => {
  const [activeSubstation, setActiveSubstation] = useState<'left' | 'right' | undefined>();
  const [leftReadySubstation, setLeftReadySubstation] = useState<boolean>(false);
  const [rightReadySubstation, setRightReadySubstation] = useState<boolean>(false);

  useEffect(() => {
    setActiveSubstation((prevState) => {
      if (!leftReadySubstation && !rightReadySubstation) {
        return;
      }

      if (leftReadySubstation && rightReadySubstation) {
        return prevState ?? 'left';
      }

      return leftReadySubstation ? 'left' : 'right';
    });
  }, [leftReadySubstation, rightReadySubstation]);

  return { activeSubstation, setLeftReadySubstation, setRightReadySubstation };
};
