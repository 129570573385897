import styled, { css } from 'styled-components';
import { Flex } from '@get-fabric/fabric-design-system';
import { CheckIcon } from './CheckIcon';

export const BinBase = styled(Flex)`
  justify-content: center;
  border-top: 2px solid #9fd6ff;
  border-left: 2px solid #9fd6ff;
  margin-top: -2px;
  margin-left: -2px;
  box-shadow: inset 0 0 15px black;
`;

export const StyledBin = styled(BinBase)<{ selected: boolean; animated?: boolean }>`
  align-items: center;
  background-color: ${({ selected }) => (selected ? '#9FD6FF' : '#0E1F41')};

  ${({ selected, animated }) =>
    selected &&
    animated &&
    css`
      animation: blink 1.5s ease-in-out infinite;
    `}

  @keyframes blink {
    70% {
      background-color: rgba(159, 214, 255, 1);
    }
    85% {
      background-color: #0e1f41;
    }
    100% {
      background-color: rgba(159, 214, 255, 1);
    }
  }
`;

export const StyledCheckIcon = styled(CheckIcon)<{ size?: number }>`
  position: absolute;
  fill: #9fd6ff;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

StyledCheckIcon.defaultProps = {
  size: 24,
};
