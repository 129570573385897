import { Configuration, SessionsApi } from '@get-fabric/session-api-client';
import { enhancedFetch } from '../../framework/enhancedFetch';
import { sessionApiBasePath } from '../../framework/environment';

const sessionsApi = new SessionsApi(
  new Configuration({
    basePath: `${sessionApiBasePath}/v1`,
    fetchApi: enhancedFetch,
  }),
);

export const setActive = async (sessionId: string, stationId: string, correlationId: string) => {
  await sessionsApi.setActive({ payload: { sessionId, stationId }, meta: { correlationId } });
};

export const setUser = async (sessionId: string, userId: string, correlationId: string) => {
  await sessionsApi.setUserSession({ payload: { sessionId, userId }, meta: { correlationId } });
};
