import i18n from '../../services/i18n/i18n';
import { logger } from '../../clients/loggingApi';

export const wmsErrorTypes = {
  wmsPickingError: 'wmsPickingError',
  wmsStockError: 'wmsStockError',
};

export class WmsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'WmsError';
  }
}

export const wmsErrorHandler = (wmsError: any, wmsErrorScope: string, logMessage: string, params?: Record<string, any>) => {
  let userFriendlyMessage = '';

  try {
    const {
      data: { type, message },
    } = wmsError;
    const errorMessageKey = `exceptions.${wmsErrorScope}.${type}`;

    logger.error(logMessage, { ...params, wmsError });

    userFriendlyMessage = i18n.t(errorMessageKey, message) ?? i18n.t('exceptions.wmsUnknownError');
  } catch (parseError) {
    if (parseError instanceof Error) {
      logger.error('failed to parse wms error', { parseError, wmsError });
    }
  }

  throw new WmsError(userFriendlyMessage);
};
