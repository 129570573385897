import React from 'react';
import type { FunctionComponent } from 'react';
import { Text } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ColoredTrayFlex } from './ColoredTrayFlex';

const StyledText = styled(Text)`
  max-width: 450px;
`;

interface RemoveOrderTotesProps {
  position: string;
}

export const RemoveOrderTotes: FunctionComponent<RemoveOrderTotesProps> = ({ position }) => {
  const { t } = useTranslation();

  return (
    <ColoredTrayFlex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={6} trayPosition={position}>
      <StyledText weight={300} size={64} textAlign={'center'}>
        {t('pickToTray.removeOrderTotes')}
      </StyledText>
    </ColoredTrayFlex>
  );
};
