import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex, Text, Button } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface ExpirationDateButtonProps {
  disabled: boolean;
  onClick: () => void;
  expiryDate: Date | null;
  required: boolean;
  expirationDateMissing: boolean;
}

export const ExpirationDateButton: FunctionComponent<ExpirationDateButtonProps> = ({
  disabled,
  onClick,
  expiryDate,
  required,
  expirationDateMissing,
}) => {
  const { t } = useTranslation();

  const getDateButtonLabel = () => {
    if (expiryDate) {
      return format(expiryDate, 'dd MMM yyyy');
    } else if (expirationDateMissing) {
      return t('decant.expirationDate.toggleButton.noDate');
    }

    return t('decant.expirationDate.toggleButton.addDate');
  };

  return (
    <Flex flexDirection={'column'} gap={2} justifyContent={'center'}>
      <Flex justifyContent={'center'}>
        {required ? (
          <Text size={18} color={'red.600'}>
            {t('decant.expirationDate.toggleButton.requiredLabel')}
          </Text>
        ) : (
          <Text size={18}>{t('decant.expirationDate.toggleButton.label')}</Text>
        )}
      </Flex>
      <Button size={'xs'} variant={'secondary'} disabled={disabled} onClick={onClick} aria-label={'expiration date button'}>
        <Text size={22}>{getDateButtonLabel()}</Text>
      </Button>
    </Flex>
  );
};
