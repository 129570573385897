import type { FunctionComponent } from 'react';
import React from 'react';
import { Button, Flex, Modal, ModalBody, ModalFooter, ModalHeader, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';

interface StationInfoModalProps {
  onConfirm: () => void;
  close: () => void;
  modalOpen: boolean;
}

export const ConfirmActiveSessionModal: FunctionComponent<StationInfoModalProps> = ({ modalOpen, close, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal open={modalOpen} onRequestClose={close}>
      <ModalHeader>{t('activeSession.title')}</ModalHeader>
      <ModalBody>
        <Text size={22}>{t('activeSession.instructions')}</Text>
      </ModalBody>
      <ModalFooter>
        <Flex gap={8}>
          <Button size={'s'} variant={'secondary'} onClick={close}>
            {t('actions.cancel')}
          </Button>
          <Button size={'s'} onClick={onConfirm}>
            {t('actions.confirm')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
