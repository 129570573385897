import type { Bin, CatalogProduct } from '@get-fabric/wms-api-client';
import { logger } from '../../../../shared/clients/loggingApi';
import { exceptionApi } from '../../../../shared/clients/wmsApi';
import { getExpirationDateValue } from '../../../../shared/services/expiration';

export interface BlindCheckInfo {
  product?: CatalogProduct;
  quantity?: number;
  expiryDate?: Date;
}

const binIsEmpty = async (mfcId: string, stationId: string, actionId: string, bin: Bin) =>
  exceptionApi.binIsEmpty({
    payload: {
      exception: {
        binId: bin.id,
        sku: bin.content[0].product.sku,
        companyCode: bin.content[0].product.companyCode,
      },
      stationId,
      mfcId,
      actionId,
    },
  });

const binIsNotEmpty = async (mfcId: string, stationId: string, bin: Bin, product: CatalogProduct, quantity: number, expiryDate?: Date) =>
  exceptionApi.binIsNotEmpty({
    payload: {
      exception: {
        binId: bin.id,
        sku: product.sku,
        confirmedQuantity: quantity,
        expirationDate: getExpirationDateValue(product.expiryDateMandatory && !product.shelfLife, expiryDate ?? null)?.valueOf(),
        companyCode: product.companyCode,
      },
      stationId,
      mfcId,
    },
  });

const wrongItem = async (
  mfcId: string,
  stationId: string,
  actionId: string,
  bin: Bin,
  product: CatalogProduct,
  quantity: number,
  expiryDate?: Date,
) =>
  exceptionApi.wrongItem({
    payload: {
      exception: {
        binId: bin.id,
        sku: product.sku,
        confirmedQuantity: quantity,
        expirationDate: getExpirationDateValue(product.expiryDateMandatory && !product.shelfLife, expiryDate ?? null)?.valueOf(),
        companyCode: product.companyCode,
      },
      stationId,
      mfcId,
      actionId,
    },
  });

const wrongQuantity = async (mfcId: string, stationId: string, actionId: string, bin: Bin, quantity: number) =>
  exceptionApi.wrongQuantity({
    payload: {
      exception: {
        binId: bin.id,
        sku: bin.content[0].product.sku,
        confirmedQuantity: quantity,
        companyCode: bin.content[0].product.companyCode,
      },
      mfcId,
      stationId,
      actionId,
    },
  });

const wrongExpiryDate = async (mfcId: string, stationId: string, actionId: string, bin: Bin, expiryDate: Date) =>
  exceptionApi.wrongExpirationDate({
    payload: {
      exception: {
        binId: bin.id,
        sku: bin.content[0].product.sku,
        expirationDate: expiryDate.getTime(),
        companyCode: bin.content[0].product.companyCode,
      },
      mfcId,
      stationId,
      actionId,
    },
  });

export const reportBlindCheckResults = async (
  mfcId: string,
  stationId: string,
  actionId: string,
  bin: Bin,
  { product: expectedProduct, quantity: expectedQuantity, expiryDate: expectedExpiryDate }: BlindCheckInfo,
  { product: reportedProduct, quantity: reportedQuantity, expiryDate: reportedExpiryDate }: BlindCheckInfo,
) => {
  let exceptionReported = false;

  if (expectedProduct && !reportedProduct) {
    logger.debug('reporting blind check', { mfcId, stationId, actionId, bin, exceptionType: 'binIsEmpty' });
    await binIsEmpty(mfcId, stationId, actionId, bin);
    exceptionReported = true;
  } else if (!expectedProduct && reportedProduct) {
    logger.debug('reporting blind check', {
      mfcId,
      stationId,
      bin,
      reportedProduct,
      reportedQuantity,
      reportedExpiryDate,
      exceptionType: 'binIsNotEmpty',
    });
    await binIsNotEmpty(mfcId, stationId, bin, reportedProduct, reportedQuantity!, reportedExpiryDate);
    exceptionReported = true;
  } else if (expectedProduct?.sku !== reportedProduct?.sku) {
    logger.debug('reporting blind check', {
      mfcId,
      stationId,
      actionId,
      bin,
      reportedProduct,
      reportedQuantity,
      reportedExpiryDate,
      exceptionType: 'wrongItem',
    });
    await wrongItem(mfcId, stationId, actionId, bin, reportedProduct!, reportedQuantity!, reportedExpiryDate);
    exceptionReported = true;
  } else {
    if (expectedQuantity !== reportedQuantity) {
      logger.debug('reporting blind check', { mfcId, stationId, actionId, bin, reportedQuantity, exceptionType: 'wrongQuantity' });
      await wrongQuantity(mfcId, stationId, actionId, bin, reportedQuantity!);
      exceptionReported = true;
    }

    if (reportedExpiryDate && expectedExpiryDate !== reportedExpiryDate) {
      logger.debug('reporting blind check', { mfcId, stationId, actionId, bin, reportedExpiryDate, exceptionType: 'wrongExpiryDate' });
      await wrongExpiryDate(mfcId, stationId, actionId, bin, reportedExpiryDate);
      exceptionReported = true;
    }
  }

  return exceptionReported;
};
