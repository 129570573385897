import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useAsync } from 'react-use';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { TasksDone } from '../../../../shared/components/TasksDone';
import { WaitingForTotes } from '../../../../shared/components/WaitingForTotes';
import { Loading } from '../../../../shared/components/Loading';
import { AllocationProvider } from '../providers/AllocationProvider';
import { ActionProvider } from '../providers/ActionProvider';
import { useRecoilAsync, toteByPositionState } from '../../../../shared/state';
import { useSubstationToteAllocations, useActiveSession, useAllocationsByStatus } from '../../../../shared/hooks';
import { getQcAction as _getQcAction } from '../../../../shared/clients/actionApi';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { WaitingForRobots } from '../../../../shared/components/WaitingForRobots';
import { logger } from '../../../../shared/clients/loggingApi';
import { ReleaseTote } from './ReleaseTote';
import { Qc } from './Qc';
import { CancelTask } from './CancelTask';

const position = 'right';

export const QcPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeSession } = useActiveSession();
  const { data: tote } = useRecoilAsync(toteByPositionState(position));
  const { allocations, currentToteAllocations } = useSubstationToteAllocations({
    position,
    toteInPositionId: tote?.id,
  });

  const binAllocation = useMemo(() => currentToteAllocations.find((allocation) => allocation.binId), [currentToteAllocations]);
  const toteAllocation = useMemo(() => currentToteAllocations.find((allocation) => !allocation.binId), [currentToteAllocations]);

  const { allocations: approvedAllocations } = useAllocationsByStatus({
    allocations,
    statuses: [AllocationStatus.Approved],
  });

  const { allocations: createdAllocations } = useAllocationsByStatus({
    allocations,
    statuses: [AllocationStatus.Created],
  });

  const { loading: markingToteAllocationOngoing } = useAsync(async () => {
    if (!toteAllocation || toteAllocation.status !== AllocationStatus.Approved || !activeSession) {
      return;
    }

    try {
      await allocationsApi.updateByAllocationId(toteAllocation.id, { payload: { status: AllocationStatus.Ongoing } });
    } catch (error) {
      logger.error('failed to mark allocation as ongoing', {
        error,
        tote,
        allocation: toteAllocation,
      });
      toast.error(t('qc.failedMarkingAllocationAsOngoing'));
    }
  }, [toteAllocation?.id, toteAllocation?.status]);

  const { loading: markingAllocationOngoing } = useAsync(async () => {
    if (!binAllocation || binAllocation.status !== AllocationStatus.Approved) {
      return;
    }

    try {
      await allocationsApi.updateByAllocationId(binAllocation.id, { payload: { status: AllocationStatus.Ongoing } });
    } catch (error) {
      logger.error('failed to mark bin allocation as ongoing', {
        error,
        tote,
        allocation: binAllocation,
      });
      toast.error(t('qc.failedMarkingAllocationAsOngoing'));
    }
  }, [binAllocation?.id, binAllocation?.status]);

  const getQcAction = useCallback(
    async (allocation: Allocation) => {
      try {
        return await _getQcAction(allocation.actionId!);
      } catch (error) {
        logger.error('failed to get action', {
          error,
          tote,
          allocation,
        });
        toast.error(t('qc.failedGettingAction'));
      }
    },
    [t, tote],
  );

  const { value: action } = useAsync(async () => {
    if (binAllocation) {
      return getQcAction(binAllocation);
    }
    if (toteAllocation) {
      return getQcAction(toteAllocation);
    }
  }, [binAllocation, toteAllocation]);

  if (!binAllocation && !toteAllocation && !createdAllocations.length && !approvedAllocations.length) {
    return <TasksDone />;
  }

  if ((binAllocation?.status === AllocationStatus.PendingCancel || toteAllocation?.status === AllocationStatus.PendingCancel) && tote) {
    return <CancelTask substationPosition={position} tote={tote} />;
  }

  if (!binAllocation && toteAllocation) {
    return <ReleaseTote allocation={toteAllocation} substationPosition={position} />;
  }

  if (!binAllocation && approvedAllocations.length) {
    return <WaitingForTotes />;
  }

  if (!binAllocation && !approvedAllocations.length && createdAllocations.length) {
    return <WaitingForRobots />;
  }

  if (!action || markingAllocationOngoing || markingToteAllocationOngoing) {
    return <Loading />;
  }

  return (
    <ActionProvider action={action}>
      <AllocationProvider allocation={binAllocation!}>
        <Qc tote={tote!} position={position} />
      </AllocationProvider>
    </ActionProvider>
  );
};
