import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { Button, Text } from '@get-fabric/fabric-design-system';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { motionApi } from '../../clients/motionApi';
import { mfcIdState, stationIdState, toteByPositionState, substationOverflowState } from '../../state';
import { logger } from '../../clients/loggingApi';

export interface OverflowDetectedProps {
  substationPosition: string;
  onDismissTote: () => void;
}

export const OverflowDetected: FunctionComponent<OverflowDetectedProps> = ({ substationPosition, onDismissTote }) => {
  const { t } = useTranslation();
  const mfcId = useRecoilValue(mfcIdState);
  const stationId = useRecoilValue(stationIdState);
  const tote = useRecoilValue(toteByPositionState(substationPosition));
  const overflow = useRecoilValue(substationOverflowState(substationPosition));

  const [{ loading }, dismissTote] = useAsyncFn(async () => {
    try {
      await motionApi.dismissTote({
        payload: {
          stationId,
          mfcId,
          substationPos: substationPosition,
          toteId: tote?.id,
        },
      });
    } catch (error) {
      logger.error('failed to dismiss tote', {
        error,
        substationPos: substationPosition,
        toteId: tote?.id,
      });
      toast.error(t('waitingForToteToGoDown.overflowDetected.failedToDismissTote'));
      throw error;
    }

    onDismissTote();
  }, [stationId, mfcId, substationPosition, onDismissTote, tote, t]);

  return (
    <>
      <Text weight={300} size={64} textAlign={'center'}>
        {t('waitingForToteToGoDown.overflowDetected.title', { substationPosition })}
      </Text>
      <Text weight={300} size={34} textAlign={'center'}>
        {t('waitingForToteToGoDown.overflowDetected.instructions')}
      </Text>
      <Button loading={loading} onClick={dismissTote} disabled={overflow}>
        {t('waitingForToteToGoDown.overflowDetected.dismissToteButton')}
      </Button>
    </>
  );
};
