import React from 'react';
import type { FunctionComponent } from 'react';
import { Flex, Text, Button } from '@get-fabric/fabric-design-system';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useAsyncFn } from 'react-use';
import { mfcIdState, stationIdState } from '../../../../../../shared/state';
import { logger } from '../../../../../../shared/clients/loggingApi';
import { releaseOrderHolder } from '../../../services';
import { ActionBar } from '../ActionBar';
import { OrderTote } from '../../../../../../shared/components/OrderTote/OrderTote';

const StyledFlex = styled(Flex)`
  max-width: 550px;
`;

interface ReleaseOrderHolderProps {
  position: string;
  orderToteId: string;
  title: string;
}

export const ReleaseOrderHolder: FunctionComponent<ReleaseOrderHolderProps> = ({ position, orderToteId, title }) => {
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);
  const { t } = useTranslation();

  const [{ loading }, clearOrderHolder] = useAsyncFn(async () => {
    const correlationId = uuid();

    try {
      await releaseOrderHolder({ position, stationId, orderToteId, mfcId, correlationId });
    } catch (error) {
      toast.error(t('releaseOrderHolder.failed'), { toastId: 'releaseOrderHolderFailed' });
      logger.error('failed to release order holder', { error, correlationId });
    }
  }, [mfcId, orderToteId, position, stationId, t]);

  return (
    <Flex flexGrow={1} flexDirection={'column'}>
      <ActionBar>
        <OrderTote orderToteId={orderToteId} />
      </ActionBar>
      <Flex flexGrow={1} alignItems={'center'} justifyContent={'center'} gap={4}>
        <StyledFlex alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={6}>
          <StyledFlex alignItems={'center'} flexDirection={'column'} gap={2}>
            <Text weight={300} size={64} textAlign={'center'}>
              {title}
            </Text>
            <Text weight={300} size={46} textAlign={'center'}>
              {t('releaseOrderHolder.instruction')}
            </Text>
          </StyledFlex>
          <Button variant={'secondary'} onClick={clearOrderHolder} loading={loading}>
            {t('releaseOrderHolder.action')}
          </Button>
        </StyledFlex>
      </Flex>
    </Flex>
  );
};
