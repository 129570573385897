import React, { useState, useCallback, useEffect } from 'react';
import type { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Text } from '@get-fabric/fabric-design-system';
import { format } from 'date-fns';
import { logger } from '../../../clients/loggingApi';
import { DatePicker } from '../DatePicker/DatePicker';
import { SuggestionButtonList, dateFormat } from './SuggestionButtonList';

interface SuggestionDatePickerProps {
  onClickConfirm: (dateValue: Date) => void;
  onClickCancel: () => void;
  dateSuggestions?: Date[];
  hasTodaySuggestion?: boolean;
  onClickConfirmDateMissing?: () => void;
  enableDateMissing?: boolean;
  title?: string;
  futureYearsOffset?: number;
  pastYearsOffset?: number;
}

export const SuggestionDatePicker: FunctionComponent<SuggestionDatePickerProps> = ({
  onClickConfirm,
  onClickConfirmDateMissing,
  onClickCancel,
  dateSuggestions = [],
  hasTodaySuggestion = false,
  enableDateMissing = false,
  title,
  futureYearsOffset,
  pastYearsOffset,
}) => {
  const { t } = useTranslation();
  const [dateValue, setDateValue] = useState(new Date());
  const [dateMissingSelected, setDateMissingSelected] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const onViewChange: (view: CalendarOrClockPickerView) => void = useCallback((view) => {
    if (view === 'day') {
      setDateSelected(true);
    }
  }, []);
  const onClickDateSuggestion = useCallback((date: Date) => {
    setDateValue(date);
    setDateSelected(true);
  }, []);

  let confirmationDisplayDate: string | null = null;

  if (dateMissingSelected) {
    confirmationDisplayDate = t('suggestionDatePicker.dateMissing');
  } else if (dateSelected) {
    confirmationDisplayDate = format(dateValue, dateFormat);
  }

  const hasSuggestionOptions = dateSuggestions.length || hasTodaySuggestion || enableDateMissing;

  const onClickDateMissing = useCallback(() => {
    setDateMissingSelected(true);
  }, []);

  useEffect(() => {
    setDateMissingSelected(false);
  }, [dateValue]);

  const useOnClickConfirm = useCallback(() => {
    if (dateMissingSelected && onClickConfirmDateMissing) {
      onClickConfirmDateMissing();
    } else if (dateMissingSelected) {
      logger.error('missing click-handler for date-missing option');
    } else {
      onClickConfirm(dateValue);
    }
  }, [dateValue, onClickConfirm, dateMissingSelected, onClickConfirmDateMissing]);

  return (
    <Flex flexDirection={'column'} gap={40} data-testid={'suggestionDatePicker'}>
      {title && <Text size={28}>{title}</Text>}
      <Flex flexDirection={'row'} gap={50}>
        <DatePicker
          dateValue={dateValue}
          setDateValue={setDateValue}
          futureYearsOffset={futureYearsOffset}
          pastYearsOffset={pastYearsOffset}
          onViewChange={onViewChange}
        />
        <Flex flexDirection={'column'} justifyContent={hasSuggestionOptions ? 'space-between' : 'end'}>
          {hasSuggestionOptions && (
            <SuggestionButtonList
              dateSuggestions={dateSuggestions}
              hasTodaySuggestion={hasTodaySuggestion}
              enableDateMissing={enableDateMissing}
              onClickDate={onClickDateSuggestion}
              onClickDateMissing={onClickDateMissing}
            />
          )}
          <Flex flexDirection={'column'} justifyContent={'end'} gap={20} alignItems={'flex-end'}>
            <Flex flexDirection={'column'} alignItems={'center'} gap={3}>
              {confirmationDisplayDate && (
                <Text color={'royalBlue.500'} size={28} weight={500}>
                  {confirmationDisplayDate}
                </Text>
              )}
              <Button onClick={useOnClickConfirm} disabled={!dateSelected && !dateMissingSelected}>
                {t('actions.confirm')}
              </Button>
            </Flex>
            <Button variant={'secondary'} onClick={onClickCancel}>
              {t('actions.cancel')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
