import styled from 'styled-components';
import { Flex } from '@get-fabric/fabric-design-system';

const getBackgroundColorForTray = (trayPosition?: string) => {
  switch (trayPosition) {
    case 'left-out-2': {
      return '#95DFFF';
    }
    case 'left-out-1': {
      return '#DDB2FF';
    }
    case 'right-out-1': {
      return '#89E8BA';
    }
    case 'right-out-2': {
      return '#FFC793';
    }
    default: {
      return '';
    }
  }
};

export const ColoredTrayFlex = styled(Flex)<{ trayPosition?: string }>`
  background-color: ${({ trayPosition }) => getBackgroundColorForTray(trayPosition)};
`;
