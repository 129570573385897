import type { FunctionComponent } from 'react';
import React, { useMemo, useState } from 'react';

interface TaskSelectionModalContextProps {
  lastSelectedTaskId: string;
  setLastSelectedTaskId: (taskId: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const TaskSelectionModalContext = React.createContext<TaskSelectionModalContextProps>(undefined as any);

export const TaskSelectionModalProvider: FunctionComponent = ({ children }) => {
  const [lastSelectedTaskId, setLastSelectedTaskId] = useState('');

  const taskSelectionModalState = useMemo(
    () => ({
      lastSelectedTaskId,
      setLastSelectedTaskId,
    }),
    [lastSelectedTaskId],
  );

  return <TaskSelectionModalContext.Provider value={taskSelectionModalState}>{children}</TaskSelectionModalContext.Provider>;
};
