import styled, { css } from 'styled-components';

export const ToteContainer = styled.div<{ bins: number; enabled: boolean }>`
  min-width: 350px;
  max-width: 500px;
  display: grid;
  border-radius: 8px;
  aspect-ratio: 16/10;

  ${({ bins }) =>
    (bins === 1 &&
      css`
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      `) ||
    (bins === 4 &&
      css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      `) ||
    (bins === 8 &&
      css`
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr;
      `)};

  ${({ onClick, enabled }) =>
    onClick &&
    enabled &&
    css`
      cursor: pointer;
    `};

  ${({ enabled }) =>
    !enabled &&
    css`
      pointer-events: none;
    `};
`;
