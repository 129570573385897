import React from 'react';
import { Avatar, Chip } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

interface UserButtonProps {
  onClick: () => void;
}

export const UserButton = React.forwardRef<HTMLDivElement, UserButtonProps>(({ onClick }, ref) => {
  const { user } = useAuth0();

  return <Chip ref={ref} onClick={onClick} avatar={<Avatar src={user?.picture} />} label={user?.name} />;
});
