import React from 'react';
import type { FunctionComponent } from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import { OrderTote } from '../../../../shared/components/OrderTote/OrderTote';
import { WaitingForTotes as BaseWaitingForTotes } from '../../../../shared/components/WaitingForTotes';
import { ActionBar } from './shared/ActionBar';

interface WaitingForTotesProps {
  orderToteId?: string;
}

export const WaitingForTotes: FunctionComponent<WaitingForTotesProps> = ({ orderToteId }) => (
  <Flex flexGrow={1} flexDirection={'column'}>
    {orderToteId && (
      <ActionBar>
        <OrderTote orderToteId={orderToteId} />
      </ActionBar>
    )}
    <BaseWaitingForTotes />
  </Flex>
);
