import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Flex, ModalBody, Spinner } from '@get-fabric/fabric-design-system';
import type { Bin } from '@get-fabric/wms-api-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';
import { useAsync } from 'react-use';
import { mfcIdState, stationIdState } from '../../../state';
import { exceptionApi } from '../../../clients/wmsApi';
import { getExpirationDateValue } from '../../../services/expiration';
import { ExceptionType } from '../exceptionTypes';
import { wmsErrorHandler, wmsErrorTypes } from '../wmsErrorHandler';
import { SelectDamagedItem } from './SelectDamagedItem';
import { MoveDamagedItem } from './MoveDamagedItem';

interface DamagedItemExceptionStepsProps {
  bin: Bin;
  actionId: string | undefined;
  onReportException: (exception: ExceptionType) => void;
}

export const DamagedItemExceptionSteps: FunctionComponent<DamagedItemExceptionStepsProps> = ({
  actionId,
  bin: {
    id: binId,
    content: [binContent],
  },
  onReportException,
}) => {
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);

  const { t } = useTranslation();
  const [quantity, setQuantity] = useState<number>(0);
  const [quantitySubmitted, setQuantitySubmitted] = useState<boolean>(false);

  const { value: damagedTrolleyLocation, loading: fetchingTrolleyLocation } = useAsync(async () => {
    const correlationId = uuid();

    try {
      const { destination } = await exceptionApi.damagedTrolley({
        payload: {
          mfcId,
          stationId,
          sku: binContent.product.sku,
        },
      });

      return destination;
    } catch (error) {
      wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'failed to fetch damaged trolley location', {
        mfcId,
        stationId,
        sky: binContent.product.sku,
        correlationId,
      });
    }
  }, [mfcId, stationId, binContent]);

  const disableConfirm = useMemo<boolean>(() => !(quantity > 0) || quantity > binContent.quantity, [quantity, binContent]);

  const changeQuantity = useCallback(
    (inputQuantity: number) => {
      setQuantity(inputQuantity);

      if (inputQuantity <= 0) {
        toast.warn(t('exceptions.damagedItem.greaterThanQuantity'), { toastId: 'greaterThanQuantity' });

        return;
      }

      if (inputQuantity > binContent.quantity) {
        toast.warn(t('exceptions.damagedItem.exceedingQuantity'), { toastId: 'exceedingQuantity' });

        return;
      }
    },
    [binContent, t],
  );

  const submitException = useCallback(async () => {
    if (!damagedTrolleyLocation) {
      return;
    }

    const correlationId = uuid();

    const {
      product: { expiryDate, expiryDateMandatory, sku, companyCode, shelfLife },
    } = binContent;

    try {
      await exceptionApi.damagedItem({
        payload: {
          exception: {
            sku,
            binId,
            confirmedQuantity: quantity,
            destinationHolder: damagedTrolleyLocation,
            companyCode,
            expirationDate: getExpirationDateValue(expiryDateMandatory && !shelfLife, expiryDate ? new Date(expiryDate) : null),
          },
          stationId,
          mfcId,
          actionId,
        },
        meta: {
          correlationId,
        },
      });

      onReportException(ExceptionType.DamagedItem);
    } catch (error) {
      wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'failed to report damaged item exception', {
        mfcId,
        stationId,
        binId,
        actionId,
        sku,
        quantity,
        damagedTrolleyLocation,
        correlationId,
      });
    }
  }, [binContent, binId, quantity, stationId, mfcId, actionId, onReportException, damagedTrolleyLocation]);

  const submitQuantity = useCallback(() => {
    setQuantitySubmitted(true);
  }, []);

  if (!quantitySubmitted) {
    return (
      <SelectDamagedItem
        quantity={quantity}
        submitQuantity={submitQuantity}
        changeQuantity={changeQuantity}
        disableConfirm={disableConfirm}
      />
    );
  }

  if (fetchingTrolleyLocation) {
    return (
      <Flex justifyContent={'center'}>
        <ModalBody>
          <Spinner size={'l'} />
        </ModalBody>
      </Flex>
    );
  }

  return <MoveDamagedItem submitException={submitException} damagedTrolleyLocation={damagedTrolleyLocation} />;
};
