import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text, Modal, Flex, ModalBody, ModalFooter, ModalHeader } from '@get-fabric/fabric-design-system';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import type { PickingOrderAction } from '@get-fabric/action-api-client/dist/generated/models/PickingOrderAction';
import { getActions } from '../../../../shared/clients/actionApi';
import { logger } from '../../../../shared/clients/loggingApi';
import { Loading } from '../../../../shared/components/Loading';

interface PickingActionDataModalProps {
  open: boolean;
  onClose: () => void;
  actionId: string;
}

export const PickingActionDataModal: FunctionComponent<PickingActionDataModalProps> = ({ open, onClose, actionId }) => {
  const { t } = useTranslation();
  const { status, data, error } = useQuery(['actions', actionId], () => getActions([actionId], uuid()));
  const [action, setAction] = useState<PickingOrderAction>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (status === 'loading') {
      setLoading(true);
    } else if (status === 'error') {
      setShowError(true);
      logger.error('Error loading actions', { actionId, error });
    } else if (data?.length === 0) {
      setShowError(true);
      logger.error('Fetching action returned an empty list', { actionId, error });
    } else {
      setAction(data[0] as PickingOrderAction);
      setLoading(false);
    }
  }, [status, data, setAction, actionId, error]);

  if (loading) {
    return (
      <Modal open={open} onRequestClose={onClose} size={'s'}>
        <ModalHeader>{t('orderData.orderDataTitle')}</ModalHeader>
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal open={open} onRequestClose={onClose} size={'s'}>
      <ModalHeader>{t('orderData.orderDataTitle')}</ModalHeader>
      <ModalBody>
        {showError && (
          <Flex>
            <Text textAlign={'left'} size={20}>
              {`${t('orderData.error')}`}
            </Text>
          </Flex>
        )}
        {!showError && (
          <Flex flexDirection={'column'} gap={4} pb={4}>
            <Text textAlign={'left'} size={20}>
              {`${t('orderData.pickingOrder')}: ${action?.task.pickingOrder}`}
            </Text>
            <Text textAlign={'left'} size={20}>
              {`${t('orderData.taskName')}: ${action?.task.taskName}`}
            </Text>
          </Flex>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={'secondary'} size={'xs'}>
          {t('actions.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
