import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ModalBody, ModalHeader } from '@get-fabric/fabric-design-system';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { CatalogProduct } from '@get-fabric/wms-api-client';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { mfcIdState, stationIdState } from '../../../state';
import { exceptionApi } from '../../../clients/wmsApi';
import { ExceptionModalFooter } from '../ExceptionsModal';
import { useDatePicker } from '../../DatePickers';
import { ExceptionType } from '../exceptionTypes';
import { wmsErrorHandler, wmsErrorTypes } from '../wmsErrorHandler';
import { EnterExpiryDateStep } from '../AddNewStock/EnterExpiryDateStep';
import { EnterQuantityStep } from '../AddNewStock/EnterQuantityStep';
import { getExpirationDateValue } from '../../../services/expiration';
import { ScanProductStep } from './ScanProductStep';

interface BinIsNotEmptyExceptionProps {
  allocation: Allocation;
  onReportException: (exception: ExceptionType) => void;
}

export const BinIsNotEmptyException: FunctionComponent<BinIsNotEmptyExceptionProps> = ({ allocation, onReportException }) => {
  const { t } = useTranslation();
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);
  const [product, setProduct] = useState<CatalogProduct | null>(null);
  const [quantity, setQuantity] = useState(0);
  const [{ pickedDate, dateMissing }, { onClickConfirmDate, onClickConfirmDateMissing, resetDatePicker }] = useDatePicker();

  const showExpiryPicker = !!product?.expiryDateMandatory && !pickedDate && !dateMissing;
  const missingRequiredExpiryDate = !!product?.expiryDateMandatory && dateMissing && !product?.shelfLife;
  const onClickDateCancel = useCallback(() => {
    resetDatePicker();
    setProduct(null);
  }, [resetDatePicker]);

  const submitException = useCallback(async () => {
    if (!product || !quantity) {
      return;
    }

    const correlationId = uuid();

    try {
      await exceptionApi.binIsNotEmpty({
        payload: {
          exception: {
            binId: allocation.binId!,
            confirmedQuantity: quantity,
            sku: product.sku,
            expirationDate: getExpirationDateValue(product.expiryDateMandatory && !product.shelfLife, pickedDate)?.valueOf(),
            companyCode: product.companyCode,
          },
          stationId,
          mfcId,
        },
        meta: {
          correlationId,
        },
      });

      if (missingRequiredExpiryDate) {
        toast.success(t('exceptions.addNewStock.missingExpiryReported'), { toastId: 'exceptionMissingExpiryDate' });
      }

      onReportException(ExceptionType.BinIsNotEmpty);
    } catch (error) {
      wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed to report bin is not empty exception', {
        mfcId,
        stationId,
        binId: allocation.binId,
        quantity,
        sku: product.sku,
        correlationId,
      });
    }
  }, [onReportException, stationId, mfcId, product, quantity, allocation, pickedDate, missingRequiredExpiryDate, t]);

  let step: React.ReactNode | null = null;

  if (!product) {
    step = <ScanProductStep onScan={setProduct} />;
  } else if (showExpiryPicker) {
    step = (
      <EnterExpiryDateStep
        onClickConfirm={onClickConfirmDate}
        onClickConfirmDateMissing={onClickConfirmDateMissing}
        onClickCancel={onClickDateCancel}
      />
    );
  } else {
    step = (
      <EnterQuantityStep
        product={product}
        quantity={quantity}
        onChangeQuantity={setQuantity}
        expiryDate={pickedDate}
        onClickExpiryDate={resetDatePicker}
        expiryDateMandatory={!!product.expiryDateMandatory}
      />
    );
  }

  return (
    <>
      <ModalHeader>{t('exceptions.binIsNotEmpty.title')}</ModalHeader>
      <ModalBody>{step}</ModalBody>
      {!showExpiryPicker && <ExceptionModalFooter reportException={submitException} disableReport={!product || !quantity} />}
    </>
  );
};
