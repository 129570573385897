import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { DecantAction } from '@get-fabric/action-api-client';
import { ActionOption } from './ActionOption';

interface ActionOptionsProps {
  actions: DecantAction[];
  onSelect: (actionId: string) => void;
}

export const ActionOptions: FunctionComponent<ActionOptionsProps> = ({ actions, onSelect }) => (
  <Flex flexWrap={'wrap'} gap={4}>
    {actions.map((action) => (
      <ActionOption key={action.id} action={action} onSelect={onSelect} />
    ))}
  </Flex>
);
