import type { Station } from '@get-fabric/station-api-client';
import { Configuration, StationKey, StationsApi, StationType, ToteOrder } from '@get-fabric/station-api-client';
import { v4 as uuid } from 'uuid';
import { enhancedFetch } from '../../framework/enhancedFetch';
import { stationApiBasePath } from '../../framework/environment';

export const stationApi = new StationsApi(
  new Configuration({
    basePath: `${stationApiBasePath}/v1`,
    fetchApi: enhancedFetch,
  }),
);

export type BasicTouchpoint = Pick<Station, 'id' | 'mfcId' | 'name' | 'type'>;

export const getAllTouchpoints = async (): Promise<BasicTouchpoint[]> => {
  const partialStations = await stationApi.getAll(
    undefined,
    undefined,
    undefined,
    [StationKey.Id, StationKey.MfcId, StationKey.Name, StationKey.Type],
    uuid(),
  );

  return partialStations.filter((station) => station.type === StationType.Touchpoint) as BasicTouchpoint[];
};

export const removeCurrentOrderTote = async (position: string, stationId: string, correlationId: string) => {
  await stationApi.removeTote(stationId, `${position}-out`, ToteOrder.Current, correlationId);
};

export const updateTotesRequestId = async (totesRequestId: string, position: string, stationId: string, correlationId: string) => {
  await stationApi.updateTote(stationId, position, ToteOrder.Current, {
    payload: { tote: { totesRequestId } },
    meta: { correlationId },
  });
};
