import { Flex, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { CatalogProduct } from '@get-fabric/wms-api-client';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';
import { productsApi } from '../../../clients/wmsApi';
import { logger } from '../../../clients/loggingApi';
import { BarcodeIcon } from '../../BarcodeIcon';
import { mfcIdState } from '../../../state';
import { useScanProduct } from '../../../hooks';

interface ScanProductStepProps {
  onScan: (product: CatalogProduct) => void;
}

export const ScanProductStep: FunctionComponent<ScanProductStepProps> = ({ onScan }) => {
  const { t } = useTranslation();
  const mfcId = useRecoilValue(mfcIdState);

  useScanProduct(async (barcode: string) => {
    const correlationId = uuid();

    let product: CatalogProduct;

    try {
      [product] = await productsApi.getProducts(barcode, mfcId, correlationId);
    } catch (error) {
      logger.error('failed to get scanned product for BinIsNotEmptyException', {
        error,
        barcode,
        correlationId,
      });

      toast.error(t('exceptions.binIsNotEmpty.failedToFetchProduct'), { toastId: 'failedToFetchProduct' });

      return;
    }

    if (!product) {
      logger.error('scanned product for BinIsNotEmptyException was not found in the catalog', {
        barcode,
        correlationId,
      });

      toast.error(t('exceptions.binIsNotEmpty.productDoesntExistInCatalog'), { toastId: 'productDoesntExistInCatalog' });

      return;
    }

    onScan(product);
  });

  return (
    <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={6} fullWidth>
      <BarcodeIcon sx={{ width: '75px', height: '75px' }} />
      <Text size={28}>{t('exceptions.binIsNotEmpty.scanItem')}</Text>
    </Flex>
  );
};
