import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { WaitingForToteToGoDown } from '../../../shared/components/WaitingForToteToGoDown';
import { substationState, useRecoilAsync } from '../../../shared/state';

export const Idle: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data: toteInRightPosition } = useRecoilAsync(substationState('right'));
  const { data: toteInLeftPosition } = useRecoilAsync(substationState('left'));

  const toteInPosition = useMemo(() => {
    if (toteInRightPosition?.totes?.current?.id) {
      return 'right';
    }
    if (toteInLeftPosition?.totes?.current?.id) {
      return 'left';
    }
  }, [toteInRightPosition, toteInLeftPosition]);

  if (toteInPosition) {
    return <WaitingForToteToGoDown substationPosition={toteInPosition} />;
  }

  return (
    <Flex flexGrow={1} justifyContent={'center'} alignItems={'center'}>
      <Text weight={300} size={64}>
        {t('idle.title')}
      </Text>
    </Flex>
  );
};
