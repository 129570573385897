import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { parseProductBarcode } from '../services/parseBarcode';
import { mfcIdState } from '../state';
import { useScan } from './useScan';

export const useScanProduct = (onScan: (productBarcode: string) => void) => {
  const mfcId = useRecoilValue(mfcIdState);

  const onProductScan = useCallback(
    async ({ barcode }: { barcode: string }) => {
      const parsedBarcode = parseProductBarcode(mfcId, barcode);

      onScan(parsedBarcode);
    },
    [mfcId, onScan],
  );

  useScan(onProductScan);
};
