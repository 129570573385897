import { create } from '@get-fabric/logging-api-client';
import { enhancedFetch } from '../../framework/enhancedFetch';
import { loggingApiBasePath } from '../../framework/environment';

export const logger = create({
  source: 'touchpoint',
  clientConfiguration: {
    basePath: `${loggingApiBasePath}/v1`,
    fetchApi: enhancedFetch,
  },
});
