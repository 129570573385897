import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import type { Bin, CatalogProduct } from '@get-fabric/wms-api-client';
import { ModalBody, ModalHeader } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import { ExceptionType } from '../exceptionTypes';
import { ExceptionModalFooter } from '../ExceptionsModal';
import { exceptionApi } from '../../../clients/wmsApi';
import { wmsErrorHandler, wmsErrorTypes } from '../wmsErrorHandler';
import { mfcIdState, stationIdState } from '../../../state';
import { EnterQuantityStep } from '../AddNewStock/EnterQuantityStep';

interface WrongQuantityInBinExceptionProps {
  bin: Bin;
  product: CatalogProduct;
  onReportException: (exception: ExceptionType) => void;
}

export const WrongQuantityInBinException: FunctionComponent<WrongQuantityInBinExceptionProps> = ({ bin, product, onReportException }) => {
  const { t } = useTranslation();
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);
  const [quantity, setQuantity] = useState<number>(0);

  const submitException = useCallback(async () => {
    if (!quantity) {
      return;
    }
    const correlationId = uuid();

    try {
      await exceptionApi.wrongQuantity({
        payload: {
          exception: {
            sku: product.sku,
            binId: bin.id,
            confirmedQuantity: quantity,
            companyCode: product.companyCode,
          },
          stationId,
          mfcId,
        },
        meta: {
          correlationId,
        },
      });

      onReportException(ExceptionType.WrongQuantityInBin);
    } catch (error) {
      wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed to report Wrong quantity in bin exception', {
        binId: bin.id,
        quantity,
        correlationId,
      });
    }
  }, [quantity, product, bin, stationId, mfcId, onReportException]);

  return (
    <>
      <ModalHeader>{t(`exceptions.${ExceptionType.WrongQuantityInBin}.title`)}</ModalHeader>
      <ModalBody>
        <EnterQuantityStep
          product={product}
          quantity={quantity}
          onChangeQuantity={setQuantity}
          expiryDate={null}
          expiryDateMandatory={false}
          onClickExpiryDate={null}
        />
      </ModalBody>
      <ExceptionModalFooter reportException={submitException} disableReport={!quantity} />
    </>
  );
};
