import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Flex, Button, Text, NumberInput } from '@get-fabric/fabric-design-system';
import { FetchError } from '@osskit/fetch-enhancers';
import styled from 'styled-components';
import type { DecantAction } from '@get-fabric/action-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { DecantActionProduct } from '@get-fabric/wms-api-client';
import { useRecoilValue } from 'recoil';
import { useAsyncFn, useSessionStorage } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductCard } from '../../../../shared/components/ProductCard';
import { useRecoilAsync, toteByPositionState, stationState } from '../../../../shared/state';
import { getDefaultQuantityToDecant, getProductPackaging, validateExpirationDate } from '../services';
import { ReportExceptionButton } from '../../../../shared/components/Exceptions';
import { useModalState } from '../../../../shared/hooks';
import { ToteOverflowModal } from '../../../../shared/components/ToteOverflowModal';
import { ToteOverweightModal } from '../../../../shared/components/ToteOverweightModal';
import { useDatePicker } from '../../../../shared/components/DatePickers';
import { DecantExceptionsModal } from './DecantExceptionsModal';
import { TargetTote } from './TargetTote';
import { ExpirationDateButton, ExpirationDateModal } from './ExpirationDate';
import { InvalidExpirationDateModal } from './ExpirationDate/InvalidExpirationDateModal';
import { MissingExpirationDateModal } from './ExpirationDate/MissingExpirationDateModal';
import { TaskIsNotActiveModal } from './TaskIsNotActiveModal';

const StyledNumberInput = styled(NumberInput)`
  width: 80px;
`;

interface MoveProductPageProps {
  process: {
    action: DecantAction;
    product: DecantActionProduct;
    allocation: Allocation;
  };
  cancelDecant: () => Promise<void>;
  completeDecant: (quantity: number, expiryDate: Date | null) => Promise<void>;
}

export const MoveProductPage: FunctionComponent<MoveProductPageProps> = ({
  process: { action, product, allocation },
  cancelDecant,
  completeDecant,
}) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(() => getDefaultQuantityToDecant(action, product));
  const [actionExpiries, setActionExpiries] = useSessionStorage<Record<string, Date>>('actionExpiries', {});

  const defaultExpiryDate = useMemo(() => {
    const previousExpiry = actionExpiries[action.id];

    if (product.expiryDate || action.results?.expiryDate || previousExpiry) {
      return new Date(product.expiryDate ?? action.results?.expiryDate ?? previousExpiry);
    }

    return null;
  }, [product.expiryDate, action.results?.expiryDate, actionExpiries, action.id]);

  const { data: leftTote } = useRecoilAsync(toteByPositionState('left'));
  const { data: rightTote } = useRecoilAsync(toteByPositionState('right'));
  const station = useRecoilValue(stationState);
  const taskIsNotActiveModal = useModalState();
  const overflowModal = useModalState();
  const exceptionsModal = useModalState();
  const toteOverweightModal = useModalState();
  const expirationDateModal = useModalState();
  const invalidExpirationDateModal = useModalState();
  const missingExpirationDateModal = useModalState();
  const [autoOpenedExpirationModal, setAutoOpenedExpirationModal] = useState(false);
  const [{ pickedDate: expiryDate, dateMissing: expirationDateMissing }, { onClickConfirmDate, onClickConfirmDateMissing }] =
    useDatePicker(defaultExpiryDate);

  const mustAddExpirationDate = !!product.expiryDateMandatory && !expiryDate && !expirationDateMissing;

  const { touchpointMaximumToteWeight: maxToteWeight = 18 } = useFlags();

  const onConfirmExpiration = useCallback(
    (date: Date) => {
      onClickConfirmDate(date);
      setActionExpiries({ ...actionExpiries, [action.id]: date });
      expirationDateModal.close();
    },
    [expirationDateModal, setActionExpiries, action.id, actionExpiries, onClickConfirmDate],
  );

  const onConfirmDateMissing = useCallback(() => {
    onClickConfirmDateMissing();
    expirationDateModal.close();
  }, [expirationDateModal, onClickConfirmDateMissing]);

  useEffect(() => {
    if (mustAddExpirationDate && !autoOpenedExpirationModal) {
      expirationDateModal.open();
      setAutoOpenedExpirationModal(true);
    }
  }, [mustAddExpirationDate, expirationDateModal, autoOpenedExpirationModal]);

  const changeQuantity = useCallback(
    (inputQuantity: number) => {
      if (!inputQuantity) {
        toast.warn(t('decant.moveProductPage.warnings.invalidQuantity'), { toastId: 'invalidQuantity' });

        return;
      }

      const { maxItemsPerBin } = getProductPackaging(product);

      if (maxItemsPerBin && inputQuantity > maxItemsPerBin) {
        setQuantity(0);

        toast.warn(t('decant.moveProductPage.warnings.selectedQuantityLargerThanMaxItemsPerBin'), {
          toastId: 'selectedQuantityLargerThanMaxItemsPerBin',
        });

        return;
      }

      const quantityOverage = inputQuantity + (action.results?.movedQuantity ?? 0) - action.requirements.quantity;

      if (quantityOverage > 0) {
        toast.warn(t('decant.moveProductPage.warnings.quantityOverage', { overage: quantityOverage }), {
          toastId: 'quantityOverage',
        });
      }

      setQuantity(inputQuantity);
    },
    [product, t, action.requirements.quantity, action.results?.movedQuantity],
  );

  const [{ loading: addingItemsToBin }, addItemsToBin] = useAsyncFn(async () => {
    if (!!expiryDate && !validateExpirationDate(expiryDate, product)) {
      invalidExpirationDateModal.open();

      return;
    }

    if (!product.shelfLife && expirationDateMissing) {
      missingExpirationDateModal.open();

      return;
    }

    if (station.substations[allocation.substationPosition].overflow) {
      overflowModal.open();

      return;
    }

    if ((station.substations[allocation.substationPosition].weight ?? 0) > maxToteWeight) {
      toteOverweightModal.open();

      return;
    }

    try {
      await completeDecant(quantity, expiryDate);
      toast.success(t('decant.moveProductPage.decantSuccessfully'), { toastId: 'decantSuccessfully' });
    } catch (error) {
      if (error instanceof FetchError && error.data?.type === 'TaskIsNotActive') {
        taskIsNotActiveModal.open();

        return;
      }

      toast.error(t('decant.moveProductPage.errors.moveItemsFailed'), { toastId: 'moveItemsFailed' });

      return;
    }
  }, [
    allocation,
    quantity,
    t,
    station.substations,
    overflowModal,
    toteOverweightModal,
    maxToteWeight,
    expiryDate,
    product,
    taskIsNotActiveModal,
    expirationDateMissing,
    completeDecant,
    invalidExpirationDateModal,
    missingExpirationDateModal,
  ]);

  const [{ loading: discardingProduct }, discardProduct] = useAsyncFn(async () => {
    try {
      await cancelDecant();
    } catch {
      toast.error(t('decant.moveProductPage.errors.cancelDecantFailed'), { toastId: 'cancelDecantFailed' });
    }
  }, [cancelDecant, t]);

  const loading = discardingProduct || addingItemsToBin;

  return (
    <>
      <Flex flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} fullWidth data-testid={'moveProductPage'}>
        <Flex flexDirection={'column'} gap={72} py={64}>
          <Flex alignItems={'center'} gap={72}>
            <ProductCard product={product} />
            <Flex gap={64} justifyContent={'space-between'} flexDirection={'column'}>
              <Flex gap={8} flexDirection={'column'}>
                <Flex gap={2} alignItems={'center'}>
                  <StyledNumberInput value={quantity} onChange={changeQuantity} aria-label={'quantity'} disabled={loading} />
                  <Text size={28}>{t(`decant.moveProductPage.${quantity <= 1 ? 'item' : 'items'}`)}</Text>
                </Flex>
                <ReportExceptionButton onClick={exceptionsModal.open} disabled={loading} />
                {product.expiryDateMandatory && (
                  <ExpirationDateButton
                    disabled={loading}
                    onClick={expirationDateModal.open}
                    required={mustAddExpirationDate}
                    expiryDate={expiryDate}
                    expirationDateMissing={expirationDateMissing}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={128}>
            <TargetTote tote={leftTote} allocation={allocation} position={'left'} />
            <TargetTote tote={rightTote} allocation={allocation} position={'right'} />
          </Flex>
        </Flex>
        <Flex justifyContent={'space-between'} fullWidth>
          <Button
            variant={'secondary'}
            onClick={discardProduct}
            data-testid={'discardProductButton'}
            disabled={loading}
            loading={discardingProduct}
          >
            {t('actions.cancel')}
          </Button>
          <Button
            disabled={!quantity || loading || mustAddExpirationDate}
            onClick={addItemsToBin}
            data-testid={'submitDecantButton'}
            loading={addingItemsToBin}
          >
            {t('decant.moveProductPage.addItems')}
          </Button>
        </Flex>
      </Flex>
      <TaskIsNotActiveModal open={taskIsNotActiveModal.modalOpen} confirm={discardProduct} />
      <InvalidExpirationDateModal open={invalidExpirationDateModal.modalOpen} confirm={invalidExpirationDateModal.close} />
      <MissingExpirationDateModal open={missingExpirationDateModal.modalOpen} confirm={missingExpirationDateModal.close} />
      <ToteOverflowModal open={overflowModal.modalOpen} confirm={overflowModal.close} />
      <ToteOverweightModal open={toteOverweightModal.modalOpen} confirm={toteOverweightModal.close} />
      <DecantExceptionsModal
        open={exceptionsModal.modalOpen}
        close={exceptionsModal.close}
        action={action}
        product={product}
        allocation={allocation}
        onReportException={exceptionsModal.close}
      />
      <ExpirationDateModal
        open={expirationDateModal.modalOpen}
        close={expirationDateModal.close}
        onClickConfirm={onConfirmExpiration}
        onClickConfirmDateMissing={onConfirmDateMissing}
        dateSuggestions={defaultExpiryDate ? [defaultExpiryDate] : []}
      />
    </>
  );
};
