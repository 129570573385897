import type { FunctionComponent } from 'react';
import React, { useEffect, useMemo } from 'react';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { toteByPositionState, useRecoilAsync } from '../../../../shared/state';
import { useSubstationToteAllocations, useCurrentAction, useAllocationsByStatus } from '../../../../shared/hooks';
import { TasksDone } from '../../../../shared/components/TasksDone';
import { WaitingForRobots } from '../../../../shared/components/WaitingForRobots';
import { WaitingForToteToGoDown } from '../../../../shared/components/WaitingForToteToGoDown';
import { Loading } from '../../../../shared/components/Loading';
import { TaskCancelled } from '../../../../shared/components/TaskCancelled/TaskCancelled';
import { ScanHolderInstruction } from './ScanHolderInstruction';
import { Instructions } from './Instructions';
import { OrderDoneInstruction } from './OrderDoneInstruction';
import { ReleaseEmptyOrderHolder } from './ReleaseEmptyOrderHolder';
import { WaitingForTotes } from './WaitingForTotes';
import { RpcInstructions } from './RpcInstructions';
import type { SubstationStepsProps } from './SubstationStepsProps';

export const SubstationSteps: FunctionComponent<SubstationStepsProps> = ({ position, setReady, activeSubstation }) => {
  const { data: inventoryToteInPosition } = useRecoilAsync(toteByPositionState(position));
  const { data: orderToteInPosition } = useRecoilAsync(toteByPositionState(`${position}-out`));

  const { allocations, currentAllocation } = useSubstationToteAllocations({
    position,
    toteInPositionId: inventoryToteInPosition?.id,
  });

  const action = useCurrentAction({ currentAllocation });

  const { allocations: allocationsToPick } = useAllocationsByStatus({
    allocations,
    statuses: [AllocationStatus.Ongoing, AllocationStatus.PendingCancel],
  });

  const { allocations: approvedAllocations } = useAllocationsByStatus({ allocations, statuses: [AllocationStatus.Approved] });

  const active = useMemo(() => activeSubstation === position, [position, activeSubstation]);

  const orderDone = useMemo(() => {
    if (!orderToteInPosition?.totesRequestId) {
      return false;
    }

    const orderAllocations = allocations?.filter((allocation) => allocation.totesRequestId === orderToteInPosition.totesRequestId);

    if (!orderAllocations?.length) {
      return true;
    }

    return !!(inventoryToteInPosition && orderToteInPosition.totesRequestId !== inventoryToteInPosition.totesRequestId);
  }, [allocations, orderToteInPosition?.totesRequestId, inventoryToteInPosition]);

  useEffect(() => {
    setReady(
      ((allocationsToPick.length || approvedAllocations.length) && !orderToteInPosition) ||
        !!inventoryToteInPosition ||
        !!currentAllocation ||
        orderDone,
    );
  }, [orderToteInPosition, inventoryToteInPosition, orderDone, setReady, currentAllocation, allocationsToPick, approvedAllocations]);

  if (orderDone && orderToteInPosition?.id) {
    return <OrderDoneInstruction position={position} orderToteId={orderToteInPosition.id} />;
  }

  if (!allocations?.length && orderToteInPosition?.id && !inventoryToteInPosition?.id) {
    return <ReleaseEmptyOrderHolder position={position} orderToteId={orderToteInPosition.id} />;
  }

  if (!currentAllocation && inventoryToteInPosition?.id) {
    return <WaitingForToteToGoDown substationPosition={position} />;
  }

  if (!allocations?.length) {
    return <TasksDone />;
  }

  if (!approvedAllocations.length && !allocationsToPick.length) {
    return <WaitingForRobots />;
  }

  if (!orderToteInPosition) {
    return <ScanHolderInstruction active={active} position={position} />;
  }

  if (!inventoryToteInPosition || !currentAllocation) {
    return <WaitingForTotes orderToteId={orderToteInPosition.id} />;
  }

  if (currentAllocation.status === AllocationStatus.PendingCancel) {
    return (
      <TaskCancelled allocation={currentAllocation} tote={inventoryToteInPosition} orderTote={orderToteInPosition} position={position} />
    );
  }

  if (!action) {
    return <Loading />;
  }

  if ('toteId' in action.requirements) {
    return (
      <RpcInstructions
        active={active}
        position={position}
        allocation={currentAllocation}
        toteId={inventoryToteInPosition.id}
        orderToteId={orderToteInPosition.id}
      />
    );
  }

  return (
    <Instructions
      action={action}
      active={active}
      position={position}
      allocation={currentAllocation}
      tote={inventoryToteInPosition}
      orderTote={orderToteInPosition}
    />
  );
};
