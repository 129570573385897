import type { FunctionComponent } from 'react';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import type { DecantAction } from '@get-fabric/action-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { CatalogProduct, DecantActionProduct } from '@get-fabric/wms-api-client';
import { useRecoilValue } from 'recoil';
import { useAsyncFn } from 'react-use';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import {
  findAllocationForProduct,
  productHasMissingProperties,
  productHasMissingCriticalProperties,
  isProductSuitableToMode,
  getProductPackaging,
} from '../services';
import type { ButtonPressEvent } from '../../../../shared/hooks';
import { useButtonPress, useModalState, useScanProduct, useActiveSession } from '../../../../shared/hooks';
import { stationState, currentToteIdsState } from '../../../../shared/state';
import { useCurrentTotes } from '../hooks/useCurrentTotes';
import { useUnfulfilledToteAllocations } from '../hooks/useTotesAllocations';
import { productsApi } from '../../../../shared/clients/wmsApi';
import { getCreatedDecantActions } from '../../../../shared/clients/actionApi';
import { logger } from '../../../../shared/clients/loggingApi';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { TaskSelectionModal } from './TaskSelectionModal';
import { ProductPropertiesModal } from './ProductPropertiesModal';
import { ActionSelectionModal } from './ActionSelectionModal';
import { CantReplaceToteModal } from './CantReplaceToteModal';

interface ScanProductPageProps {
  startDecant: (action: DecantAction, product: DecantActionProduct, allocationId: Allocation['id']) => Promise<void>;
}

enum ErrorType {
  failedToFetchProducts = 'failedToFetchProducts',
  failedToFetchActions = 'failedToFetchActions',
  failedToStartDecant = 'failedToStartDecant',
  failedToDismissTote = 'failedToDismissTote',
  productDoesntHaveCriticalProperties = 'productDoesntHaveCriticalProperties',
  productDoesntBelongToOpenASN = 'productDoesntBelongToOpenASN',
  productDoesntMatchStationOptions = 'productDoesntMatchStationOptions',
  productDoesntHaveAvailableAllocation = 'productDoesntHaveAvailableAllocation',
  productDoesntExistInCatalog = 'productDoesntExistInCatalog',
  multipleProductsForBarcode = 'multipleProductsForBarcode',
}

export const ScanProductPage: FunctionComponent<ScanProductPageProps> = ({ startDecant }) => {
  const { t } = useTranslation();
  const station = useRecoilValue(stationState);
  const toteIds = useRecoilValue(currentToteIdsState);
  const totes = useCurrentTotes();
  const allocations = useUnfulfilledToteAllocations(toteIds);
  const activeSession = useActiveSession();
  const [sku, setSku] = useState('');
  const [decantProduct, setDecantProduct] = useState<DecantActionProduct>();
  const [actions, setActions] = useState<DecantAction[]>([]);
  const [productActions, setProductActions] = useState<DecantAction[]>([]);

  const taskSelectionModal = useModalState();
  const actionSelectionModal = useModalState();
  const productPropertiesModal = useModalState();
  const cantReplaceToteModal = useModalState();

  const dismissProduct = useCallback(() => {
    setSku('');
    setActions([]);
    setProductActions([]);
  }, []);

  const showError = useCallback(
    (error: ErrorType, logParams: Record<string, any>) => {
      toast.error(t(`decant.scanProductPage.errors.${error}`, logParams), { toastId: error });
      logger.error(`encountered error of type ${error} while decanting`, logParams);
      dismissProduct();
    },
    [dismissProduct, t],
  );

  const selectProductToDecant = useCallback(
    async (actions: DecantAction[]) => {
      const [action] = actions;

      let product: DecantActionProduct;

      try {
        product = await productsApi.getDecantActionProduct(action.id, station.mfcId, uuid());
      } catch (error) {
        showError(ErrorType.failedToFetchProducts, {
          error,
          action,
        });

        return;
      }

      if (productHasMissingCriticalProperties(product)) {
        showError(ErrorType.productDoesntHaveCriticalProperties, {
          product,
          action,
        });

        return;
      }

      if (productHasMissingProperties(product)) {
        setDecantProduct(product);
        productPropertiesModal.open();

        return;
      }

      if (!isProductSuitableToMode(product, station.mode.current)) {
        const { toteHeight: height, partitions } = getProductPackaging(product);

        showError(ErrorType.productDoesntMatchStationOptions, {
          product,
          mode: station.mode.current,
          height,
          partitions,
        });

        return;
      }

      const allocation = findAllocationForProduct(totes, allocations, product);

      if (!allocation) {
        showError(ErrorType.productDoesntHaveAvailableAllocation, {
          product,
          allocations,
          totes: toteIds,
          action,
        });

        return;
      }

      const taskIds = new Set(actions.map(({ task: { id: taskId } }) => taskId));

      if (taskIds.size > 1) {
        taskSelectionModal.open();

        return;
      }

      if (actions.length > 1) {
        actionSelectionModal.open();

        return;
      }

      try {
        await startDecant(action, product, allocation.id);
      } catch (error) {
        showError(ErrorType.failedToStartDecant, {
          error,
          action,
          product,
          allocation,
        });
      }
    },
    [
      totes,
      station.mode,
      station.mfcId,
      toteIds,
      allocations,
      startDecant,
      showError,
      taskSelectionModal,
      actionSelectionModal,
      productPropertiesModal,
    ],
  );

  const [{ loading: scanProductLoading }, scanProduct] = useAsyncFn(
    async (barcode: string) => {
      const correlationId = uuid();

      if (sku || !activeSession) {
        return;
      }

      let products: CatalogProduct[];

      try {
        products = await productsApi.getProducts(barcode, station.mfcId, correlationId);
      } catch (error) {
        showError(ErrorType.failedToFetchProducts, {
          error,
          barcode,
          correlationId,
        });

        return;
      }

      if (!products.length) {
        showError(ErrorType.productDoesntExistInCatalog, {
          barcode,
          correlationId,
        });

        return;
      }

      if (products.length > 1) {
        showError(ErrorType.multipleProductsForBarcode, {
          barcode,
          correlationId,
        });

        return;
      }

      const [selectedProduct] = products;
      let actions: DecantAction[];

      try {
        actions = await getCreatedDecantActions(station.mfcId, { correlationId });
      } catch (error) {
        showError(ErrorType.failedToFetchActions, {
          error,
          correlationId,
        });

        return;
      }

      const productActions = actions.filter(
        ({ requirements }) =>
          requirements?.product.sku === selectedProduct.sku && requirements?.product.companyCode === selectedProduct.companyCode,
      );

      if (!productActions.length) {
        showError(ErrorType.productDoesntBelongToOpenASN, {
          barcode,
          sku: selectedProduct.sku,
          companyCode: selectedProduct.companyCode,
          correlationId,
        });

        return;
      }

      setSku(selectedProduct.sku);
      setActions(actions);
      setProductActions(productActions);
      await selectProductToDecant(productActions);
    },
    [sku, selectProductToDecant, activeSession, showError, station.mfcId],
  );

  useScanProduct(scanProduct);

  const [{ loading: loadingTaskSelection }, selectTask] = useAsyncFn(
    async (taskId: string) => {
      taskSelectionModal.close();
      const actions = productActions.filter((action) => action.task.id === taskId);

      setProductActions(actions);
      await selectProductToDecant(actions);
    },
    [productActions, selectProductToDecant, taskSelectionModal],
  );

  const [{ loading: loadingActionSelection }, selectAction] = useAsyncFn(
    async (actionId: string) => {
      actionSelectionModal.close();
      const actions = productActions.filter((action) => action.id === actionId);

      setProductActions(actions);
      await selectProductToDecant(actions);
    },
    [productActions, selectProductToDecant, actionSelectionModal],
  );

  const cancelTaskSelection = useCallback(() => {
    taskSelectionModal.close();
    dismissProduct();
  }, [dismissProduct, taskSelectionModal]);

  const cancelActionSelection = useCallback(() => {
    actionSelectionModal.close();
    dismissProduct();
  }, [dismissProduct, actionSelectionModal]);

  const cancelProductPropertiesSelection = useCallback(() => {
    productPropertiesModal.close();
    dismissProduct();
  }, [dismissProduct, productPropertiesModal]);

  const [{ loading: loadingProductProperties }, onUpdateProductProperties] = useAsyncFn(async () => {
    productPropertiesModal.close();
    await selectProductToDecant(productActions);
  }, [productPropertiesModal, productActions, selectProductToDecant]);

  const loading = scanProductLoading || loadingTaskSelection || loadingActionSelection || loadingProductProperties;

  const onButtonPress = useCallback(
    async ({ position }: ButtonPressEvent) => {
      if (loading || sku) {
        cantReplaceToteModal.open();

        return;
      }
      const toteIdToRelease = station.substations[position].totes?.current?.id;

      const allocationIds = allocations.filter(({ toteId }) => toteId === toteIdToRelease).map(({ id }) => id);

      if (!allocationIds.length) {
        logger.warn('button was pressed during decant but matching allocations were not found and therefore cannot be canceled', {
          toteIdToRelease,
          allocations,
          allocationIds,
          substationPosition: position,
        });

        return;
      }

      try {
        await Promise.all(
          allocationIds.map((allocationId) =>
            allocationsApi.updateByAllocationId(allocationId, {
              payload: {
                status: AllocationStatus.Cancelled,
              },
            }),
          ),
        );
      } catch (error) {
        showError(ErrorType.failedToDismissTote, {
          error,
          allocationIds,
          toteIdToRelease,
          position,
        });
      }
    },
    [loading, sku, allocations, station, cantReplaceToteModal, showError],
  );

  useButtonPress(onButtonPress);

  return (
    <>
      <Flex flexGrow={1} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={32} data-testid={'scanProductPage'}>
        {!toteIds.length ? (
          <Spinner size={'l'} />
        ) : (
          <Text weight={300} size={64}>
            {t('decant.scanProductPage.instruction')}
          </Text>
        )}
        {loading && <Spinner size={'l'} />}
      </Flex>
      <TaskSelectionModal
        open={taskSelectionModal.modalOpen}
        close={cancelTaskSelection}
        selectTask={selectTask}
        sku={sku}
        actions={actions}
      />
      <ActionSelectionModal
        open={actionSelectionModal.modalOpen}
        close={cancelActionSelection}
        selectAction={selectAction}
        actions={productActions}
      />
      <ProductPropertiesModal
        open={productPropertiesModal.modalOpen}
        close={cancelProductPropertiesSelection}
        product={decantProduct}
        onUpdate={onUpdateProductProperties}
      />
      <CantReplaceToteModal open={cantReplaceToteModal.modalOpen} confirm={cantReplaceToteModal.close} />
    </>
  );
};
