import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { Bin } from '@get-fabric/wms-api-client';
import { useCompleteBin } from '../hooks/useCompleteBin';
import { Loading } from '../../../../shared/components/Loading';
import { ProductCard } from '../../../../shared/components/ProductCard';
import { BinIsNotEmptySteps } from './BinIsNotEmptySteps';

interface BinIsNotEmptyProps {
  bin: Bin;
}

export const BinIsNotEmpty: FunctionComponent<BinIsNotEmptyProps> = ({ bin }) => {
  const { completeBin, completingBin } = useCompleteBin();

  if (completingBin) {
    return <Loading />;
  }

  return (
    <Flex alignItems={'center'} gap={10}>
      <ProductCard product={bin.content[0].product} />
      <BinIsNotEmptySteps bin={bin} onComplete={completeBin} />
    </Flex>
  );
};
