import { Flex, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Bin, CatalogProduct } from '@get-fabric/wms-api-client';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';
import { productsApi } from '../../../clients/wmsApi';
import { BarcodeIcon } from '../../BarcodeIcon';
import { mfcIdState } from '../../../state';
import { useScanProduct } from '../../../hooks';
import { logger } from '../../../clients/loggingApi';

interface ScanProductStepProps {
  onScan: (product: CatalogProduct) => void;
  bin: Bin;
}

export const ScanProductStep: FunctionComponent<ScanProductStepProps> = ({ onScan, bin }) => {
  const { t } = useTranslation();
  const mfcId = useRecoilValue(mfcIdState);

  useScanProduct(async (barcode: string) => {
    let product: CatalogProduct | undefined;

    try {
      [product] = await productsApi.getProducts(barcode, mfcId, uuid());
    } catch (error) {
      logger.error('failed to get scanned product', {
        error,
        barcode,
      });

      toast.error(t('exceptions.failedToGetProduct'), { toastId: 'failedToGetProduct' });

      return;
    }

    if (!product) {
      logger.error('scanned product was not found in the catalog', {
        barcode,
      });
      toast.error(t('exceptions.wrongItemInBin.productNotFound'), { toastId: 'productNotFound' });

      return;
    }

    if (bin.content[0].product.barcodes.includes(barcode)) {
      logger.warn('scanned product is already in the bin', {
        toteId: bin.parentId,
        binId: bin.id,
        barcode,
      });
      toast.error(t('exceptions.wrongItemInBin.productAlreadyInBin'), { toastId: 'productAlreadyInBin' });

      return;
    }

    onScan(product);
  });

  return (
    <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={6} fullWidth>
      <BarcodeIcon sx={{ width: '75px', height: '75px' }} />
      <Text size={28}>{t('exceptions.wrongItemInBin.text')}</Text>
    </Flex>
  );
};
