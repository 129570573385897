export const actionApiBasePath = process.env.VITE_ACTION_API_URL?.toString() ?? 'https://action-api.prod.get-fabric.net';
export const allocationApiBasePath = process.env.VITE_ALLOCATION_API_URL?.toString() ?? 'https://allocation-api.prod.get-fabric.net';
export const liveAllocationApiBasePath =
  process.env.VITE_LIVE_ALLOCATION_API_URL?.toString() ?? 'https://live-allocation-api.prod.get-fabric.net';
export const liveSessionApiBasePath = process.env.VITE_LIVE_SESSION_API_URL?.toString() ?? 'https://live-session-api.prod.get-fabric.net';
export const liveStationApiBasePath = process.env.VITE_LIVE_STATION_API_URL?.toString() ?? 'https://live-station-api.prod.get-fabric.net';
export const loggingApiBasePath = process.env.VITE_LOGGING_API_URL?.toString() ?? 'https://logging-api.prod.get-fabric.net';
export const motionApiBasePath = process.env.VITE_MOTION_API_URL?.toString() ?? 'https://motion-api.prod.get-fabric.net';
export const sessionApiBasePath = process.env.VITE_SESSION_API_URL?.toString() ?? 'https://session-api.prod.get-fabric.net';
export const stationApiBasePath = process.env.VITE_STATION_API_URL?.toString() ?? 'https://station-api.prod.get-fabric.net';
export const wmsApiBasePath = process.env.VITE_WMS_API_URL?.toString() ?? 'https://wms-api.prod.get-fabric.net';
export const mixpanelProjectToken = 'f896824dbda5f3839f6c203354212e0d';
