import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleGroup } from '@get-fabric/fabric-design-system';
import type { ProductFormProperties } from './useProductFormProperties';
import { otherToteType, useToteTypes } from './useToteTypes';
import { FormField } from './FormField';

interface ToteTypeSelectionProps {
  value: ProductFormProperties['toteType'];
  onChange: (value: ProductFormProperties['toteType']) => void;
}

export const ToteTypeSelection: FunctionComponent<ToteTypeSelectionProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const toteTypes = useToteTypes();
  const options = useMemo(
    () =>
      toteTypes.map((toteType) => ({
        label:
          toteType.name === otherToteType.name
            ? t('decant.productPropertiesModal.otherType')
            : t('decant.productPropertiesModal.toteType', {
                partitions: toteType.partitions,
                toteHeight: toteType.toteHeight,
              }),
        value: toteType,
      })),
    [t, toteTypes],
  );

  return (
    <FormField text={t('decant.productPropertiesModal.toteTypeSubtitle')}>
      <ToggleGroup options={options} value={value} onChange={onChange} size={'s'} />
    </FormField>
  );
};
