import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { CatalogProduct } from '@get-fabric/wms-api-client';
import { FetchError } from '@osskit/fetch-enhancers';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Flex, NumberInput, Spinner, Text } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';
import type { Action, OrderAction } from '@get-fabric/action-api-client';
import type { Tote } from '../../../../shared/state';
import { stationIdState } from '../../../../shared/state';
import { pick } from '../services';
import { logger } from '../../../../shared/clients/loggingApi';
import { updateTotesRequestId } from '../../../../shared/clients/stationApi';
import type { ScanEvent } from '../../../../shared/hooks';
import { useScan, useActiveSession } from '../../../../shared/hooks';
import { ProductCard } from '../../../../shared/components/ProductCard';
import { SubstationToteBins } from '../../../../shared/components/SubstationToteBins';
import { WmsError } from '../../../../shared/components/Exceptions/wmsErrorHandler';

const StyledNumberInput = styled(NumberInput)`
  width: 80px;
`;

interface PickingOutStepProps {
  action: Action;
  allocation: Allocation;
  position: 'left' | 'right';
  tote: Tote;
  orderTote?: Tote;
  product: CatalogProduct;
  active: boolean;
  modalOpen: boolean;
}

export const PickProductInstructions: FunctionComponent<PickingOutStepProps> = ({
  action,
  allocation,
  position,
  tote,
  orderTote,
  product,
  active,
  modalOpen,
}) => {
  const stationId = useRecoilValue(stationIdState);
  const { t } = useTranslation();
  const { activeSession } = useActiveSession();
  const { pickingScanless } = useFlags();

  const remainingQuantity = useMemo(
    () => allocation.requiredQuantity! - allocation.quantity,
    [allocation.requiredQuantity, allocation.quantity],
  );
  const [quantity, setQuantity] = useState<number>(remainingQuantity);

  const [{ loading: pickingInProgress }, pickProduct] = useAsyncFn(
    async (pickedAmount: number) => {
      if (!active || !activeSession || modalOpen || !product || !orderTote) {
        return;
      }

      const correlationId = uuid();

      try {
        const movedQuantity = (action as OrderAction)?.results?.movedQuantity ?? 0;
        await pick(allocation, pickedAmount, movedQuantity, orderTote, stationId, correlationId);
      } catch (error) {
        logger.error('failed to pick items', { error, correlationId });

        if (error instanceof WmsError) {
          toast.error(error.message, { toastId: 'failedPicking' });

          return;
        }

        if (error instanceof FetchError) {
          toast.error((error as FetchError).data?.message ?? t('pickProduct.failedPicking'), { toastId: 'failedPicking' });

          return;
        }
      }

      if (orderTote?.totesRequestId) {
        return;
      }

      const substationPosition = `${position}-out`;

      logger.debug('picking third step started', { correlationId });
      try {
        await updateTotesRequestId(allocation.totesRequestId, substationPosition, stationId, correlationId);
      } catch (error) {
        logger.error('failed to update station tote request id', {
          error,
          toteId: orderTote.id,
          toteRequestId: allocation.totesRequestId,
          substationPosition,
          correlationId,
        });
      }
      logger.debug('picking third step completed', { correlationId });
    },
    [action, active, modalOpen, product, orderTote, allocation, position, stationId, t, activeSession],
  );

  const validateScanBeforePick = useCallback(
    (barcode: string) => {
      if (!active || modalOpen || !product || pickingScanless) {
        return false;
      }

      if (pickingInProgress) {
        toast.warn(t('scanProduct.scanInProgress'), { toastId: 'scanInProgress' });

        return false;
      }

      if (!product?.barcodes.includes(barcode)) {
        toast.error(t('scanProduct.wrongItem'), { toastId: 'wrongItem' });

        return false;
      }

      return true;
    },
    [active, modalOpen, product, pickingScanless, pickingInProgress, t],
  );

  const onScan = useCallback(
    ({ barcode }: ScanEvent) => {
      if (!validateScanBeforePick(barcode)) {
        return;
      }

      void pickProduct(1);
    },
    [pickProduct, validateScanBeforePick],
  );

  const addItemsDisabled = useMemo(
    () => !activeSession || pickingInProgress || quantity > remainingQuantity || quantity <= 0,
    [activeSession, pickingInProgress, quantity, remainingQuantity],
  );

  const changeQuantity = useCallback(
    (inputQuantity: number) => {
      setQuantity(inputQuantity);

      if (inputQuantity <= 0) {
        toast.warn(t('pickingOut.pickingQuantity.greaterThanQuantity'), { toastId: 'greaterThanQuantity' });

        return;
      }

      if (inputQuantity > remainingQuantity) {
        toast.warn(t('pickingOut.pickingQuantity.exceedingQuantity'), { toastId: 'exceedingQuantity' });

        return;
      }
    },
    [remainingQuantity, t],
  );

  const scanlessPicking = useCallback(() => {
    void pickProduct(quantity);
  }, [pickProduct, quantity]);

  useEffect(() => {
    setQuantity(remainingQuantity);
  }, [setQuantity, remainingQuantity]);

  useScan(onScan);

  return (
    <Flex flexGrow={1} justifyContent={'center'} flexDirection={'column'} gap={45}>
      <Flex alignItems={'center'} justifyContent={'space-evenly'} gap={15} fullWidth>
        <ProductCard product={product} />
        <Flex gap={32} alignItems={'center'} justifyContent={'space-between'} flexDirection={'column'}>
          <Flex gap={5}>
            <Text size={64}>{t('pickingOut.pickingQuantity.pickAllocation', { count: remainingQuantity })}</Text>
            {pickingInProgress && <Spinner size={'l'} />}
          </Flex>
          {pickingScanless && <StyledNumberInput value={quantity} onChange={changeQuantity} />}
        </Flex>
      </Flex>
      <Flex gap={15} justifyContent={'space-evenly'}>
        <SubstationToteBins
          toteId={tote.id}
          bins={tote.bins}
          orientation={tote.orientation}
          position={position}
          selectedBin={allocation.binId}
        />
        {pickingScanless && (
          <Button size={'l'} variant={'secondary'} loading={pickingInProgress} disabled={addItemsDisabled} onClick={scanlessPicking}>
            {t('pickProduct.addItems')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
