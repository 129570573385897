import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { stockApi } from '../../../../shared/clients/wmsApi';
import { createTrace } from '../../../../framework/correlation';

export const merge = async (allocationId: string, stationId: string, sourceBinId: string, targetBinId: string, mfcId: string) => {
  const traced = createTrace();

  await traced(stockApi).merge({ payload: { stationId, mfcId, operator: '', sourceBinId, targetBinId } });
  await traced(allocationsApi).updateByAllocationId(allocationId, { payload: { status: AllocationStatus.Completed } });
};

export const skipMerge = async (allocationId: string) => {
  const traced = createTrace();

  await traced(allocationsApi).updateByAllocationId(allocationId, { payload: { status: AllocationStatus.Completed } });
};
