import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { exceptionApi } from '../clients/wmsApi';
import { logger } from '../clients/loggingApi';

export const useDamagedItemsLocation = (mfcId: string, stationId: string) => {
  const { t } = useTranslation();

  const { value: destination, loading } = useAsync(async () => {
    try {
      const { destination } = await exceptionApi.damagedTrolley({
        payload: {
          mfcId,
          stationId,
        },
      });

      return destination;
    } catch (error) {
      logger.error('failed to get damaged trolley location', {
        error,
      });
      toast.error(t('exceptions.damagedItem.noDamagedTrolleyToast'));
    }
  }, [mfcId, stationId, t]);

  return useMemo(
    () => ({
      destination,
      loading,
    }),
    [destination, loading],
  );
};
