import type { CatalogProduct } from '@get-fabric/wms-api-client';
import { useCallback, useState } from 'react';

export const useBinBlindCheckSteps = () => {
  const [checkQuantityCompleted, setCheckQuantityCompleted] = useState(false);
  const [reportedQuantity, setReportedQuantity] = useState<number>();
  const completeCheckQuantity = useCallback(
    (reportedQuantity?: number) => {
      setReportedQuantity(reportedQuantity);
      setCheckQuantityCompleted(true);
    },
    [setReportedQuantity, setCheckQuantityCompleted],
  );

  const [checkExpiryDateCompleted, setCheckExpiryDateCompleted] = useState(false);
  const [reportedExpiryDate, setReportedExpiryDate] = useState<Date>();
  const completeCheckExpiryDate = useCallback(
    (reportedExpiryDate?: Date) => {
      setReportedExpiryDate(reportedExpiryDate);
      setCheckExpiryDateCompleted(true);
    },
    [setReportedExpiryDate, setCheckExpiryDateCompleted],
  );

  const [verifyProductCompleted, setVerifyProductCompleted] = useState(false);
  const [reportedProduct, setReportedProduct] = useState<CatalogProduct>();
  const completeVerifyProduct = useCallback(
    (reportedProduct?: CatalogProduct) => {
      setReportedProduct(reportedProduct);
      setVerifyProductCompleted(true);

      if (!reportedProduct) {
        setCheckQuantityCompleted(true);
        setCheckExpiryDateCompleted(true);
      } else if (!reportedProduct.expiryDateMandatory || reportedProduct.shelfLife) {
        setCheckExpiryDateCompleted(true);
      }
    },
    [setReportedProduct, setVerifyProductCompleted, setCheckQuantityCompleted, setCheckExpiryDateCompleted],
  );

  return {
    verifyProductCompleted,
    reportedProduct,
    completeVerifyProduct,

    checkQuantityCompleted,
    reportedQuantity,
    completeCheckQuantity,

    checkExpiryDateCompleted,
    reportedExpiryDate,
    completeCheckExpiryDate,
  };
};
