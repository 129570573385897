import React from 'react';
import type { FunctionComponent } from 'react';
import type { QcAction } from '@get-fabric/action-api-client';

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const ActionContext = React.createContext<QcAction>(undefined as any);

export const ActionProvider: FunctionComponent<{ action: QcAction }> = ({ action, children }) => (
  <ActionContext.Provider value={action}>{children}</ActionContext.Provider>
);
