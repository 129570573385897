import { Flex } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined';
import { grey } from '@mui/material/colors';
import type { Tote } from '../../../../shared/state';
import { Substation } from './Substation';

interface SubstationsProps {
  sourceTote: Tote;
  targetTote: Tote;
  targetBinId: string;
  sourceBinId: string;
}

const InstructionsIcon = styled(ArrowForwardOutlined).attrs({
  sx: { color: grey[600], fontSize: '64px' },
})``;

export const Substations: FunctionComponent<SubstationsProps> = ({ targetTote, targetBinId, sourceTote, sourceBinId }) => (
  <Flex>
    <Substation position={'left'} binId={sourceBinId} tote={sourceTote} />
    <Flex alignItems={'center'}>
      <InstructionsIcon />
    </Flex>
    <Substation position={'right'} binId={targetBinId} tote={targetTote} />
  </Flex>
);
