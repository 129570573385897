import type { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Flex } from '@get-fabric/fabric-design-system';
import Star from '@mui/icons-material/Star';

export const Ribbon = styled(Flex)`
  position: absolute;
  right: 12px;
  background-color: ${({ theme }) => theme.colors.purple[700]};
  width: 24px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 6px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const PreviouslySelectedRibbon: FunctionComponent = () => (
  <Ribbon>
    <Star />
  </Ribbon>
);
