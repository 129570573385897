import type { FunctionComponent } from 'react';
import React from 'react';
import { ModalBody, Modal, Flex, ModalHeader } from '@get-fabric/fabric-design-system';
import { YesNoQuestion } from './YesNoQuestion';

interface YesNoQuestionModalProps {
  open: boolean;
  question: string;
  onYes: () => Promise<void> | void;
  onNo: () => Promise<void> | void;
}

export const YesNoQuestionModal: FunctionComponent<YesNoQuestionModalProps> = ({ question, onYes, onNo, open }) => (
  <Modal open={open} size={'l'}>
    <ModalHeader />
    <ModalBody>
      <Flex flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={6} fullWidth>
        <YesNoQuestion question={question} onYes={onYes} onNo={onNo} />
      </Flex>
    </ModalBody>
  </Modal>
);
