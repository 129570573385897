import type { FunctionComponent } from 'react';
import React from 'react';
import { Modal, ModalHeader, ModalBody } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { SuggestionDatePicker } from '../../../../../shared/components/DatePickers';

interface ExpirationDateModalProps {
  open: boolean;
  close: () => void;
  onClickConfirm: (dateValue: Date) => void;
  onClickConfirmDateMissing: () => void;
  canClose?: boolean;
  dateSuggestions: Date[];
}

export const ExpirationDateModal: FunctionComponent<ExpirationDateModalProps> = ({
  open,
  close,
  onClickConfirm,
  onClickConfirmDateMissing,
  dateSuggestions,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onRequestClose={close} size={'xl'}>
      <ModalHeader>{t('decant.expirationDate.modal.title')}</ModalHeader>
      <ModalBody>
        <SuggestionDatePicker
          onClickConfirm={onClickConfirm}
          onClickConfirmDateMissing={onClickConfirmDateMissing}
          onClickCancel={close}
          futureYearsOffset={4}
          pastYearsOffset={1}
          dateSuggestions={dateSuggestions}
          enableDateMissing
        />
      </ModalBody>
    </Modal>
  );
};
