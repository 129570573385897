import { useEffect } from 'react';
import type { ScanUpdate } from '@get-fabric/live-station-api-types';
import { SocketEvents } from '@get-fabric/live-station-api-types';
import { emitter as socketEmitter } from '../clients/liveStationApi';
import { emitter as keyScannerEmitter } from '../services/keyscanner';
import { emitter as manualScannerEmitter } from '../services/manualScanner';
import { useActiveSession } from './useActiveSession';

export interface ScanEvent {
  source: 'keyscanner' | 'manualScanner' | 'socket';
  barcode: string;
}

export const useScan = (onScan: (scan: ScanEvent) => void) => {
  const { activeSession } = useActiveSession();

  useEffect(() => {
    if (!activeSession) {
      return;
    }

    const socketUnsubscribe = socketEmitter.on(SocketEvents.ScanUpdate, (data: ScanUpdate) => {
      onScan({ source: 'socket', barcode: data.value.barcode });
    });
    const keyScannerUnsubscribe = keyScannerEmitter.on('scan', (barcode: string) => {
      onScan({ source: 'keyscanner', barcode });
    });
    const manualScannerUnsubscribe = manualScannerEmitter.on('scan', (barcode: string) => {
      onScan({ source: 'manualScanner', barcode });
    });

    return () => {
      manualScannerUnsubscribe();
      keyScannerUnsubscribe();
      socketUnsubscribe();
    };
  }, [onScan, activeSession]);
};
