import { Flex, Spinner } from '@get-fabric/fabric-design-system';
import React from 'react';
import styled from 'styled-components';
import { TouchpointLogo } from '../../App/TouchpointSelection/TouchpointLogo';

const StyledFlex = styled(Flex)`
  width: 100%;
  height: 100%;
  margin-bottom: 150px;
`;

export const LogoLoadingPage = () => (
  <StyledFlex flexDirection={'column'} gap={100} justifyContent={'center'} alignItems={'center'}>
    <TouchpointLogo size={325} />
    <Spinner size={'l'} />
  </StyledFlex>
);
