import type { FunctionComponent } from 'react';
import React from 'react';
import { QcCheckResult } from '@get-fabric/wms-api-client';
import { useAsyncFn } from 'react-use';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { ExceptionsModal } from '../../../../shared/components/Exceptions';
import { useCompleteBin } from '../hooks/useCompleteBin';
import { BinIsEmptyExceptions } from './BinIsEmptyExceptions';

interface BinIsEmptyExceptionsModalProps {
  open: boolean;
  close: () => void;
}

export const BinIsEmptyExceptionsModal: FunctionComponent<BinIsEmptyExceptionsModalProps> = ({ open, close }) => {
  const { completeBin } = useCompleteBin();

  const [, onReportException] = useAsyncFn(async () => {
    close();
    await completeBin(QcCheckResult.Exception);
  }, [close, completeBin]);

  return (
    <ExceptionsModal open={open} close={close} exceptions={[ExceptionType.BinIsNotEmpty]}>
      <BinIsEmptyExceptions onReportException={onReportException} />
    </ExceptionsModal>
  );
};
