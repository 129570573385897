import { useAsyncFn } from 'react-use';
import type { FunctionComponent } from 'react';
import React from 'react';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import type { Bin } from '@get-fabric/wms-api-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import type { Tote } from '../../../../shared/state';
import { useModalState } from '../../../../shared/hooks';
import { ReportExceptionButton } from '../../../../shared/components/Exceptions';
import { ActionBar } from '../../PickingOut/components/shared/ActionBar';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { logger } from '../../../../shared/clients/loggingApi';
import { InstructionContainer } from '../../../../shared/components/InstructionContainer';
import { MergeExceptionsModal } from './MergeExceptionsModal';
import { MergeInstructions } from './MergeInstructions';

interface MergeProps {
  sourceBin: Bin;
  targetBin: Bin;
  targetTote: Tote;
  sourceTote: Tote;
  allocation: Allocation;
}

export const Merge: FunctionComponent<MergeProps> = ({ sourceBin, targetBin, sourceTote, targetTote, allocation }) => {
  const { t } = useTranslation();
  const { close, modalOpen, open } = useModalState();

  const [, cancelTask] = useAsyncFn(
    async (exceptionType) => {
      if (exceptionType === ExceptionType.DamagedItem) {
        close();

        return;
      }

      try {
        await allocationsApi.updateByAllocationId(allocation.id, { payload: { status: AllocationStatus.Cancelled } });
      } catch (error) {
        logger.error('failed to cancel allocation', {
          error,
          allocationId: allocation.id,
        });
        toast.error(t('merge.failedToCancelAllocation'));

        return;
      }

      close();
    },
    [t, allocation.id, close],
  );

  return (
    <>
      <InstructionContainer flexDirection={'column'}>
        <ActionBar>
          <ReportExceptionButton onClick={open} />
        </ActionBar>
        <MergeInstructions
          sourceBin={sourceBin}
          sourceBinId={sourceBin.id}
          targetBinId={targetBin.id}
          sourceTote={sourceTote}
          targetTote={targetTote}
          allocationId={allocation.id}
        />
      </InstructionContainer>
      <MergeExceptionsModal
        open={modalOpen}
        close={close}
        onReportException={cancelTask}
        sourceBin={sourceBin}
        targetBin={targetBin}
        actionId={allocation.actionId!}
      />
    </>
  );
};
