import Emittery from 'emittery';
import io from 'socket.io-client';
import type { AllocationCreated, AllocationsSnapshot, AllocationUpdate } from '@get-fabric/live-allocation-api-types';
import { SocketEvents } from '@get-fabric/live-allocation-api-types';
import { getToken } from '../../framework/getToken';
import { liveAllocationApiBasePath } from '../../framework/environment';
import { initSocketEvents } from './initSocketEvents';
import { logger } from './loggingApi';

export const emitter = new Emittery<{
  [SocketEvents.AllocationsUpdate]: AllocationUpdate;
  [SocketEvents.AllocationsCreated]: AllocationCreated;
  [SocketEvents.Snapshot]: AllocationsSnapshot;
}>();

export const liveAllocationApi = io(liveAllocationApiBasePath, {
  reconnection: true,
  autoConnect: false,
  query: {
    clientVersion: '1',
    clientApi: 'touchpoint',
  },
  transports: ['websocket'],
  async auth(cb) {
    const token = await getToken();

    cb({ token: `Bearer ${token}` });
  },
});

const initLiveAllocationApiEvents = () => {
  initSocketEvents(liveAllocationApi);

  liveAllocationApi.on(SocketEvents.AllocationsCreated, (data: AllocationCreated) => {
    logger.info('Allocation Created event received', { data });
    void emitter.emit(SocketEvents.AllocationsCreated, data);
  });

  liveAllocationApi.on(SocketEvents.AllocationsUpdate, (data: AllocationUpdate) => {
    logger.info('Allocation Update event received', { data });
    void emitter.emit(SocketEvents.AllocationsUpdate, data);
  });

  liveAllocationApi.on(SocketEvents.Snapshot, (data: AllocationsSnapshot) => {
    logger.info('Allocation Snapshot event received', { data });
    void emitter.emit(SocketEvents.Snapshot, data);
  });
};

export const init = () => {
  liveAllocationApi.connect();
  initLiveAllocationApiEvents();

  return () => {
    liveAllocationApi.removeAllListeners();
    liveAllocationApi.disconnect();
  };
};

export const subscribe = (stationId: string) => {
  const emit = () => {
    liveAllocationApi.emit(SocketEvents.Subscribe, { stationId }, () => liveAllocationApi.emit(SocketEvents.Snapshot, { stationId }));
  };
  const unsubscribe = () => {
    liveAllocationApi.off('connect', emit);
  };

  liveAllocationApi.on('connect', emit);
  emit();

  return () => {
    unsubscribe();
    liveAllocationApi.emit(SocketEvents.Unsubscribe);
  };
};
