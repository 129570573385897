import { AllocationStatus } from '@get-fabric/allocation-api-client';
import type { UUID } from '@get-fabric/station-api-types';
import { useContext, useMemo } from 'react';
import { LiveAllocationContext } from '../providers/LiveAllocationsProvider';
import type { Tote } from '../state';

export const useCompletedToteBins = (tote?: Tote) => {
  const { allocations: allAllocations } = useContext(LiveAllocationContext);

  return useMemo(() => {
    if (!tote?.id || !tote.totesRequestId) {
      return new Set<UUID>();
    }

    return new Set<UUID>(
      allAllocations
        .filter(
          ({ binId, toteId, totesRequestId, status }) =>
            binId && toteId === tote.id && totesRequestId === tote.totesRequestId && status === AllocationStatus.Completed,
        )
        .map(({ binId }) => binId!),
    );
  }, [allAllocations, tote]);
};
