import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';

interface BinContentPropertyProps {
  label: string;
  value: number | string;
}

export const BinContentProperty: FunctionComponent<BinContentPropertyProps> = ({ label, value }) => (
  <Flex>
    <Text size={16} weight={500} marginRight={1}>
      {label}
    </Text>
    <Text size={16}>{value}</Text>
  </Flex>
);
