import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { BinIsEmptyException, ReportExceptionContext, WrongItemInBinException } from '../../../../shared/components/Exceptions';
import { AllocationContext } from '../providers/AllocationProvider';

interface BinIsNotEmptyExceptionsProps {
  bin: Bin;
  onReportException: (exception: ExceptionType) => void;
}

export const BinIsNotEmptyExceptions: FunctionComponent<BinIsNotEmptyExceptionsProps> = ({ bin, onReportException }) => {
  const allocation = useContext(AllocationContext);
  const { exception } = useContext(ReportExceptionContext);

  switch (exception) {
    case ExceptionType.WrongItemInBin: {
      return <WrongItemInBinException bin={bin} actionId={allocation.actionId} onReportException={onReportException} />;
    }
    case ExceptionType.BinIsEmpty: {
      return <BinIsEmptyException bin={bin} actionId={allocation.actionId} onReportException={onReportException} />;
    }
    default: {
      return null;
    }
  }
};
