import type { FunctionComponent } from 'react';
import React from 'react';
import { Text } from '@get-fabric/fabric-design-system';
import { StyledBinBrief, TextMultilineEllipsis } from './ToteContentBins.styles';

interface BinBriefProps {
  quantity: number;
  name?: string;
}
export const BinBrief: FunctionComponent<BinBriefProps> = ({ quantity, name }) => (
  <StyledBinBrief>
    <Text size={28} weight={700}>
      {quantity}
    </Text>
    <TextMultilineEllipsis size={18} weight={700} numOfLines={4}>
      {name}
    </TextMultilineEllipsis>
  </StyledBinBrief>
);
