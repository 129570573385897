import type { FunctionComponent } from 'react';
import React from 'react';
import { ModalHeader } from '@get-fabric/fabric-design-system';
import type { Bin } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { ExceptionType } from '../exceptionTypes';
import { DamagedItemExceptionSteps } from './DamagedItemExceptionSteps';

interface DamagedItemExceptionProps {
  bin: Bin;
  actionId: string | undefined;
  onReportException: (exception: ExceptionType) => void;
}

export const DamagedItemException: FunctionComponent<DamagedItemExceptionProps> = ({ actionId, bin, onReportException }) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader> {t(`exceptions.${ExceptionType.DamagedItem}.title`)}</ModalHeader>
      <DamagedItemExceptionSteps bin={bin} actionId={actionId} onReportException={onReportException} />
    </>
  );
};
