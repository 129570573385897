import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex, Text, Button } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';

interface YesNoQuestionProps {
  question: string;
  onNo: () => Promise<void> | void;
  onYes: () => Promise<void> | void;
}

export const YesNoQuestion: FunctionComponent<YesNoQuestionProps> = ({ question, onNo, onYes }) => {
  const { t } = useTranslation();

  const [{ loading: loadingYes }, onYesClick] = useAsyncFn(async () => onYes(), [onYes]);
  const [{ loading: loadingNo }, onNoClick] = useAsyncFn(async () => onNo(), [onNo]);

  return (
    <Flex justifyContent={'center'} flexDirection={'column'} gap={30}>
      <Text size={28}>{question}</Text>
      <Flex alignItems={'center'} justifyContent={'center'} gap={20} fullWidth>
        <Button size={'s'} onClick={onYesClick} loading={loadingYes}>
          {t('actions.yes')}
        </Button>
        <Button size={'s'} variant={'secondary'} onClick={onNoClick} loading={loadingNo}>
          {t('actions.no')}
        </Button>
      </Flex>
    </Flex>
  );
};
