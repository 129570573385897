import React from 'react';
import { Text } from '@get-fabric/fabric-design-system';
import { t } from 'i18next';
import { logger } from '../shared/clients/loggingApi';

export const errorHandler = (error: Error) => {
  logger.error('Error boundary', { error });
};

export const errorFallback = () => (
  <Text weight={500} size={40}>
    {t('ErrorBoundary.errorMessage')}
  </Text>
);
