import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { Card, CardHeader } from '@mui/material';
import styled from 'styled-components';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import type { ExceptionType } from '../exceptionTypes';
import { exceptionOptionLogos } from '../exceptionTypes';

interface ExceptionOptionProps {
  exception: ExceptionType;
  onClick: (exception: ExceptionType) => void;
}

const OptionButton = styled(Card).attrs({
  role: 'button',
})`
  width: 420px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ExceptionOption: FunctionComponent<ExceptionOptionProps> = ({ exception, onClick }) => {
  const { t } = useTranslation();
  const Logo = exceptionOptionLogos[exception];

  const selectException = useCallback(() => {
    onClick(exception);
  }, [exception, onClick]);

  return (
    <OptionButton onClick={selectException}>
      <Flex px={6} gap={2} alignItems={'center'}>
        <Logo style={{ width: '36px', height: '36px' }} />
        <CardHeader title={<Text size={22}>{t(`exceptions.${exception}.title`)}</Text>} />
      </Flex>
    </OptionButton>
  );
};
