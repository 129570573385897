import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, CardContent, CardHeader, Slide, Tooltip, IconButton } from '@mui/material';
import ExitToAppOutlined from '@mui/icons-material/ExitToAppOutlined';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';
import { useAsync } from 'react-use';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import type { BasicTouchpoint } from '../../shared/clients/stationApi';
import { getAllTouchpoints } from '../../shared/clients/stationApi';
import { logger } from '../../shared/clients/loggingApi';
import { MfcSelectionAutoComplete } from './MfcSelectionAutoComplete';

const StyledCard = styled(Card)`
  width: 450px;
  padding: 16px;
  min-height: 150px;
  max-height: 550px;
  background-color: #bdbdbd;
`;

const StyledFlex = styled(Flex)`
  overflow: auto;
  max-height: 350px;
`;

const getMfcTps = (touchpoints: BasicTouchpoint[], mfcId: string): BasicTouchpoint[] =>
  touchpoints
    .filter((tp) => tp.mfcId === mfcId)
    .sort((tp1, tp2) => Number.parseInt(tp1.name.slice(2)) - Number.parseInt(tp2.name.slice(2)));

export const TouchpointSelectionCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const [selectedMfc, setSelectedMfc] = useState('');
  const { value: touchpoints, loading } = useAsync(async () => {
    try {
      return await getAllTouchpoints();
    } catch (error) {
      logger.error('failed to get touchpoints', {
        error,
      });
      toast.error(t('mfcSelection.failedToGetTouchpoints'));
    }
  }, []);
  const mfcs = useMemo(() => (touchpoints ? [...new Set(touchpoints.map(({ mfcId }) => mfcId))] : []), [touchpoints]);
  const mfcTouchpoints = useMemo(() => (touchpoints ? getMfcTps(touchpoints, selectedMfc) : []), [touchpoints, selectedMfc]);
  const { logout } = useAuth0();

  const logoutUser = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <Slide direction={'up'} in mountOnEnter unmountOnExit>
      <StyledCard elevation={8} variant={'elevation'}>
        <CardHeader
          title={<Text size={28}>{t('mfcSelection.title')}</Text>}
          action={
            <IconButton size={'large'} color={'primary'} onClick={logoutUser}>
              <Tooltip title={t('actions.logout')}>
                <ExitToAppOutlined />
              </Tooltip>
            </IconButton>
          }
        />
        <CardContent>
          <Flex flexDirection={'column'} gap={20}>
            <MfcSelectionAutoComplete loading={loading} mfcs={mfcs} onSelect={setSelectedMfc} />
            {mfcTouchpoints.length > 0 && selectedMfc && (
              <StyledFlex gap={5} flexWrap={'wrap'}>
                {mfcTouchpoints.map(({ name, id }) => (
                  <Button key={name} to={`/${id}`} component={Link} size={'large'} variant={'outlined'} data-testid={`stationButton-${id}`}>
                    {name}
                  </Button>
                ))}
              </StyledFlex>
            )}
          </Flex>
        </CardContent>
      </StyledCard>
    </Slide>
  );
};
