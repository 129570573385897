import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import type { DecantAction } from '@get-fabric/action-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { DecantActionProduct } from '@get-fabric/wms-api-client';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { ReportExceptionContext } from '../../../../shared/components/Exceptions';
import { RejectedItemException } from '../../../../shared/components/Exceptions/RejectedItemException';
import { BinIsNotEmptyException } from '../../../../shared/components/Exceptions/BinIsNotEmptyException';

interface DecantExceptionsStepsProps {
  action: DecantAction;
  product: DecantActionProduct;
  allocation: Allocation;
  onReportException: (exception: ExceptionType) => void;
}

export const DecantExceptionsSteps: FunctionComponent<DecantExceptionsStepsProps> = ({
  action,
  product,
  allocation,
  onReportException,
}) => {
  const { exception } = useContext(ReportExceptionContext);

  switch (exception) {
    case ExceptionType.RejectedItem: {
      return <RejectedItemException action={action} product={product} onReportException={onReportException} />;
    }
    case ExceptionType.BinIsNotEmpty: {
      return <BinIsNotEmptyException allocation={allocation} onReportException={onReportException} />;
    }
    default: {
      return null;
    }
  }
};
