import type { FunctionComponent } from 'react';
import React, { useEffect, useMemo } from 'react';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useRecoilValue } from 'recoil';
import { WaitingForRobots } from '../../../../shared/components/WaitingForRobots';
import { useAllocationsByStatus, useSubstationToteAllocations } from '../../../../shared/hooks';
import { useRecoilAsync, currentModeState, nextModeState, toteByPositionState } from '../../../../shared/state';
import { WaitingForTotes } from '../../../../shared/components/WaitingForTotes';
import { TasksDone } from '../../../../shared/components/TasksDone';
import { WaitingForToteToGoDown } from '../../../../shared/components/WaitingForToteToGoDown';
import type { SubstationStepsProps } from '../../PickingOut/components/SubstationStepsProps';
import { useTraysVacancy } from '../hooks/useTraysVacancy';
import { useOrderDoneInPosition } from '../hooks/useOrderDoneInPosition';
import { ScanToteInTray } from './ScanToteInTray';
import { Instructions } from './Instructions';
import { OrderDone } from './OrderDone';
import { TaskCancelledWrapper } from './TaskCancelledWrapper';
import { RemoveOrderTotes } from './RemoveOrderTotes';

export const SubstationSteps: FunctionComponent<SubstationStepsProps> = ({ position, setReady, activeSubstation }) => {
  const { data: inventoryToteInPosition } = useRecoilAsync(toteByPositionState(position as string));
  const { options: modeOptions } = useRecoilValue(currentModeState);
  const nextMode = useRecoilValue(nextModeState);

  const enabledPickingOutSubstations: string[] = useMemo(
    () => modeOptions?.enabledPickingOutSubstations?.[position] ?? [],
    [modeOptions, position],
  );

  const { allocations, currentAllocation } = useSubstationToteAllocations({
    position,
    toteInPositionId: inventoryToteInPosition?.id,
  });

  const { allocations: allocationsToPick } = useAllocationsByStatus({
    allocations,
    statuses: [AllocationStatus.Ongoing, AllocationStatus.PendingCancel],
  });

  const { allocations: approvedAllocations } = useAllocationsByStatus({
    allocations,
    statuses: [AllocationStatus.Approved],
  });

  const active = useMemo(() => activeSubstation === position, [position, activeSubstation]);

  const { emptyTray, notEmptyTray } = useTraysVacancy(position);
  const orderDoneTray = useOrderDoneInPosition(position);

  const otherPosition = position === 'left' ? 'right' : 'left';
  const { emptyTray: emptyTrayInOtherSide } = useTraysVacancy(otherPosition);
  const orderDoneTrayInOtherSide = useOrderDoneInPosition(otherPosition);

  const scanOrderTote = useMemo(
    () => !!enabledPickingOutSubstations.length && !!emptyTray && !nextMode?.name,
    [enabledPickingOutSubstations, emptyTray, nextMode],
  );

  useEffect(() => {
    const readyToPick =
      (allocationsToPick.length || approvedAllocations.length) &&
      !!inventoryToteInPosition &&
      !emptyTrayInOtherSide &&
      !orderDoneTrayInOtherSide;

    setReady(readyToPick || scanOrderTote || !!orderDoneTray);
  }, [
    orderDoneTray,
    scanOrderTote,
    emptyTrayInOtherSide,
    orderDoneTrayInOtherSide,
    inventoryToteInPosition,
    setReady,
    allocationsToPick,
    approvedAllocations,
  ]);

  if (!currentAllocation && inventoryToteInPosition?.id) {
    return <WaitingForToteToGoDown substationPosition={position} />;
  }

  if (nextMode?.name && !currentAllocation && notEmptyTray) {
    return <RemoveOrderTotes position={notEmptyTray.substationKey} />;
  }

  if (orderDoneTray) {
    return <OrderDone position={orderDoneTray.substationKey} />;
  }

  if (scanOrderTote) {
    return <ScanToteInTray position={emptyTray!.substationKey} />;
  }

  if (!allocations?.length) {
    return <TasksDone />;
  }

  if (!approvedAllocations.length && !allocationsToPick.length) {
    return <WaitingForRobots />;
  }

  if (!inventoryToteInPosition || !currentAllocation) {
    return <WaitingForTotes />;
  }

  if (currentAllocation.status === AllocationStatus.PendingCancel) {
    return <TaskCancelledWrapper position={position} inventoryTote={inventoryToteInPosition} />;
  }

  return <Instructions active={active} position={position} allocation={currentAllocation} inventoryTote={inventoryToteInPosition} />;
};
