import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { FunctionComponent } from 'react';
import type { AllocationCreated, AllocationUpdate, AllocationsSnapshot } from '@get-fabric/live-allocation-api-types';
import { SocketEvents as AllocationSocketEvents } from '@get-fabric/live-allocation-api-types/dist/generated/events';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sortBy } from 'lodash';
import { emitter as allocationEmitter, init as initLiveAllocationApi, subscribe } from '../clients/liveAllocationApi';
import { stationIdState, allocationsAtom } from '../state';

interface LiveAllocationContextProps {
  allocations: Allocation[];
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const LiveAllocationContext = React.createContext<LiveAllocationContextProps>(undefined as any);

export const LiveAllocationProvider: FunctionComponent = ({ children }) => {
  const stationId = useRecoilValue(stationIdState);
  const [, setAllocationState] = useRecoilState(allocationsAtom);
  const [allocations, setAllocations] = useState<Allocation[]>([]);

  const handleAllocationUpdate = useCallback(({ value: { allocation } }: AllocationUpdate) => {
    setAllocations((allocations) => [...allocations.filter(({ id }) => id !== allocation.id), allocation]);
  }, []);

  const handleCreatedAllocations = useCallback(({ value }: AllocationCreated) => {
    setAllocations((allocations) => [...allocations, ...value]);
  }, []);

  const handleAllocationSnapshot = useCallback(({ value }: AllocationsSnapshot) => {
    setAllocations(value);
  }, []);

  useEffect(() => {
    setAllocationState(allocations);
  }, [allocations, setAllocationState]);

  useEffect(() => {
    const disposeLiveAllocationApi = initLiveAllocationApi();
    const unsubscribe = subscribe(stationId);

    return () => {
      unsubscribe();
      disposeLiveAllocationApi();
    };
  }, [stationId]);

  useEffect(() => {
    const unsubscribeSnapshot = allocationEmitter.on(AllocationSocketEvents.Snapshot, handleAllocationSnapshot);
    const unsubscribeCreates = allocationEmitter.on(AllocationSocketEvents.AllocationsCreated, handleCreatedAllocations);
    const unsubscribeUpdates = allocationEmitter.on(AllocationSocketEvents.AllocationsUpdate, handleAllocationUpdate);

    return () => {
      unsubscribeUpdates();
      unsubscribeCreates();
      unsubscribeSnapshot();
    };
  }, [handleAllocationSnapshot, handleCreatedAllocations, handleAllocationUpdate]);

  const liveAllocationsState = useMemo(
    () => ({
      allocations: sortBy(allocations, ({ id }) => id),
    }),
    [allocations],
  );

  return <LiveAllocationContext.Provider value={liveAllocationsState}>{children}</LiveAllocationContext.Provider>;
};
