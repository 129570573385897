import { QcCheckResult } from '@get-fabric/wms-api-client';
import { useCallback, useState } from 'react';
import { useAsync } from 'react-use';

export const useBinIsNotEmptySteps = (
  onComplete: (result: QcCheckResult) => Promise<void>,
  { initialStepsState }: { initialStepsState: { damagedItems: boolean; checkQuantity: boolean; checkExpirationDate: boolean } },
) => {
  const [exceptionReported, setExceptionReported] = useState(false);

  const [verifyProductCompleted, setVerifyProductCompleted] = useState(false);
  const completeVerifyProduct = useCallback(() => {
    setVerifyProductCompleted(true);
  }, [setVerifyProductCompleted]);
  const exceptionVerifyProduct = useCallback(() => onComplete(QcCheckResult.Exception), [onComplete]);

  const [damagedItemsCompleted, setDamagedItemsCompleted] = useState(!initialStepsState.damagedItems);
  const completedDamagedItems = useCallback(() => {
    setDamagedItemsCompleted(true);
  }, [setDamagedItemsCompleted]);
  const exceptionDamagedItems = useCallback(() => {
    setExceptionReported(true);
    setDamagedItemsCompleted(true);
  }, [setExceptionReported]);

  const [checkQuantityCompleted, setCheckQuantityCompleted] = useState(!initialStepsState.checkQuantity);
  const completeCheckQuantity = useCallback(async () => {
    setCheckQuantityCompleted(true);
  }, [setCheckQuantityCompleted]);
  const exceptionCheckQuantity = useCallback(() => {
    setExceptionReported(true);
    setCheckQuantityCompleted(true);
  }, [setExceptionReported]);

  const [checkExpiryDateCompleted, setCheckExpiryDateCompleted] = useState(!initialStepsState.checkExpirationDate);
  const completeCheckExpiryDate = useCallback(() => {
    setCheckExpiryDateCompleted(true);
  }, [setCheckExpiryDateCompleted]);
  const exceptionCheckExpiryDate = useCallback(() => onComplete(QcCheckResult.Exception), [onComplete]);

  useAsync(async () => {
    if (verifyProductCompleted && damagedItemsCompleted && checkQuantityCompleted && checkExpiryDateCompleted) {
      await onComplete(exceptionReported ? QcCheckResult.Exception : QcCheckResult.NoException);
    }
  }, [checkExpiryDateCompleted, checkQuantityCompleted, damagedItemsCompleted, verifyProductCompleted]);

  return {
    verifyProductCompleted,
    completeVerifyProduct,
    exceptionVerifyProduct,

    damagedItemsCompleted,
    completedDamagedItems,
    exceptionDamagedItems,

    checkQuantityCompleted,
    completeCheckQuantity,
    exceptionCheckQuantity,

    checkExpiryDateCompleted,
    completeCheckExpiryDate,
    exceptionCheckExpiryDate,
  };
};
