import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Modal, ModalBody, ModalFooter, ModalHeader } from '@get-fabric/fabric-design-system';
import React, { useCallback } from 'react';
import type { Bin } from '@get-fabric/wms-api-client';

interface SkipMoveModalProps {
  open: boolean;
  close: () => void;
  submit: () => void;
  sourceBin: Bin;
}

export const SkipMoveModal: FunctionComponent<SkipMoveModalProps> = ({ open, close, submit, sourceBin }) => {
  const { t } = useTranslation();
  const confirm = useCallback(() => {
    submit();
    close();
  }, [close, submit]);

  return (
    <Modal open={open} onRequestClose={close} size={'s'}>
      <ModalHeader>{t('merge.skip.title')}</ModalHeader>
      <ModalBody>{t('merge.skip.message', { quantity: sourceBin.content?.[0]?.quantity })}</ModalBody>
      <ModalFooter>
        <Flex gap={8}>
          <Button onClick={confirm} size={'s'}>
            {t('actions.confirm')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
