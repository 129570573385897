import type { FunctionComponent } from 'react';
import React from 'react';
import { ModalBody, ModalFooter, ModalHeader, Button, Modal } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';

interface InvalidExpirationDateModalProps {
  confirm: () => void;
  open: boolean;
}

export const InvalidExpirationDateModal: FunctionComponent<InvalidExpirationDateModalProps> = ({ open, confirm }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} closeOnOverlayClick={false} data-testid={'toteOverflowModal'}>
      <ModalHeader>{t('invalidExpirationDateModal.title')}</ModalHeader>
      <ModalBody>{t('invalidExpirationDateModal.instructions')}</ModalBody>
      <ModalFooter>
        <Button variant={'primary'} size={'s'} data-testid={'confirmInvalidExpirationDateButton'} onClick={confirm}>
          {t('actions.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
