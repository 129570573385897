import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { ModalHeader, ModalBody, Text } from '@get-fabric/fabric-design-system';
import type { Bin } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';
import { mfcIdState, stationIdState } from '../../state';
import { exceptionApi } from '../../clients/wmsApi';
import { ExceptionType } from './exceptionTypes';
import { ExceptionModalFooter } from './ExceptionsModal';
import { wmsErrorHandler, wmsErrorTypes } from './wmsErrorHandler';

interface BinIsEmptyExceptionProps {
  bin: Bin;
  actionId: string | undefined;
  onReportException: (exception: ExceptionType) => void;
}

export const BinIsEmptyException: FunctionComponent<BinIsEmptyExceptionProps> = ({
  bin: {
    id: binId,
    content: [{ product }],
  },
  actionId,
  onReportException,
}) => {
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);

  const { t } = useTranslation();
  const submitException = useCallback(async () => {
    const correlationId = uuid();

    try {
      await exceptionApi.binIsEmpty({
        payload: {
          exception: {
            binId,
            companyCode: product.companyCode,
            sku: product.sku,
          },
          stationId,
          mfcId,
          actionId,
        },
        meta: { correlationId },
      });

      onReportException(ExceptionType.BinIsEmpty);
    } catch (error) {
      wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed to report bin is empty exception', {
        mfcId,
        stationId,
        binId,
        actionId,
        correlationId,
      });
    }
  }, [actionId, binId, mfcId, onReportException, stationId, product]);

  return (
    <>
      <ModalHeader>{t(`exceptions.${ExceptionType.BinIsEmpty}.title`)}</ModalHeader>
      <ModalBody>
        <Text size={34}>{t('exceptions.binIsEmpty.text')}</Text>
      </ModalBody>
      <ExceptionModalFooter reportException={submitException} disableReport={false} />
    </>
  );
};
