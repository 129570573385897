import type { FunctionComponent } from 'react';
import React from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const BarcodeIcon: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      stroke={'null'}
      d={
        'm4,4.93443l2,0l0,14.21311l-2,0l0,-14.21311m3,0l1,0l0,14.21311l-1,0l0,-14.21311m2,0l3,0l0,14.21311l-3,0l0,-14.21311m4,0l1,0l0,14.21311l-1,0l0,-14.21311m3,0l2,0l0,14.21311l-2,0l0,-14.21311m3,0l1,0l0,14.21311l-1,0l0,-14.21311m-17,-2.36885l0,4.7377l-2,0l0,-4.7377a2,2.36885 0 0 1 2,-2.36885l4,0l0,2.36885l-4,0m20,-2.36885a2,2.36885 0 0 1 2,2.36885l0,4.7377l-2,0l0,-4.7377l-4,0l0,-2.36885l4,0m-20,16.58196l0,4.7377l4,0l0,2.36885l-4,0a2,2.36885 0 0 1 -2,-2.36885l0,-4.7377l2,0m20,4.7377l0,-4.7377l2,0l0,4.7377a2,2.36885 0 0 1 -2,2.36885l-4,0l0,-2.36885l4,0z'
      }
    />
  </SvgIcon>
);
