import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import type { Tote } from '@get-fabric/station-api-types';
import { sortBy } from 'lodash';
import { currentModeState, substationsState } from '../state';

export interface Tray {
  substationKey: string;
  orderTote?: Tote;
  previousTote?: Tote;
}

export const useOrderTotesInPosition = (position: 'left' | 'right'): Tray[] => {
  const substations = useRecoilValue(substationsState);
  const { options: modeOptions } = useRecoilValue(currentModeState);

  const enabledPickingOutSubstations: string[] = useMemo(
    () => modeOptions?.enabledPickingOutSubstations?.[position] ?? [],
    [modeOptions, position],
  );

  return useMemo(() => {
    const orderTotesInPosition = enabledPickingOutSubstations.map((key) => ({
      substationKey: key,
      orderTote: substations[key]?.totes?.current,
      previousTote: substations[key]?.totes?.previous,
    }));

    return sortBy(orderTotesInPosition, (orderTote) => orderTote.substationKey);
  }, [enabledPickingOutSubstations, substations]);
};
