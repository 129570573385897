import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex, Text, NumberInput } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';

interface QuantitySelectionProps {
  text: string;
  changeQuantity: (inputQuantity: number) => void;
  quantity: number;
}

const StyledNumberInput = styled(NumberInput)`
  width: 80px;
`;

export const QuantitySelection: FunctionComponent<QuantitySelectionProps> = ({ text, changeQuantity, quantity }) => (
  <Flex gap={32} justifyContent={'space-between'} flexDirection={'column'}>
    <Text size={28}>{text}</Text>
    <StyledNumberInput value={quantity} onChange={changeQuantity} aria-label={'quantity'} />
  </Flex>
);
