import type { FunctionComponent } from 'react';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from '@get-fabric/fabric-design-system';
import { ToteContent } from './ToteContent';

interface ToteContentModalProps {
  open: boolean;
  onClose: () => void;
  toteId: string;
  reverse: boolean;
}

export const ToteContentModal: FunctionComponent<ToteContentModalProps> = ({ open, onClose, toteId, reverse }) => (
  <Modal open={open} onRequestClose={onClose}>
    <ModalHeader>{`Tote Id: ${toteId}`}</ModalHeader>
    <ModalBody>
      <ToteContent toteId={toteId} reverse={reverse} />
    </ModalBody>
  </Modal>
);
