import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { ReportExceptionContext } from '../../../../shared/components/Exceptions';
import { BinIsNotEmptyException } from '../../../../shared/components/Exceptions/BinIsNotEmptyException';
import { AllocationContext } from '../providers/AllocationProvider';

interface BinIsEmptyExceptionsProps {
  onReportException: (exception: ExceptionType) => void;
}

export const BinIsEmptyExceptions: FunctionComponent<BinIsEmptyExceptionsProps> = ({ onReportException }) => {
  const { exception } = useContext(ReportExceptionContext);
  const allocation = useContext(AllocationContext);

  switch (exception) {
    case ExceptionType.BinIsNotEmpty: {
      return <BinIsNotEmptyException allocation={allocation} onReportException={onReportException} />;
    }
    default: {
      return null;
    }
  }
};
