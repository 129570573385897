import type { FunctionComponent } from 'react';
import React from 'react';
import type { UUID } from '@get-fabric/station-api-types';
import type { Bin as BinType } from '@get-fabric/wms-api-client';
import { useModalState } from '../../hooks';
import { ToteContainer } from './ToteBins.styles';
import { Bin } from './Bin';
import { ToteContentModal } from './ToteContent/ToteContentModal';
import { useSortedBins } from './hooks/useSortedBins';

interface StockHolderProps {
  selectedBin?: UUID;
  completedBins?: Set<UUID>;
  toteId: UUID;
  bins: BinType[];
  reverse: boolean;
  enabled?: boolean;
}

export const ToteBins: FunctionComponent<StockHolderProps> = ({ selectedBin, completedBins, toteId, bins, reverse, enabled = true }) => {
  const sortedBins = useSortedBins(bins, reverse);
  const { modalOpen, close, open } = useModalState();

  return (
    <>
      <ToteContainer bins={sortedBins.length} data-testid={`tote-${toteId}`} onClick={open} enabled={enabled}>
        {sortedBins.map(({ id }) => (
          <Bin key={id} selected={id === selectedBin} completed={completedBins?.has(toteId)} />
        ))}
      </ToteContainer>
      <ToteContentModal open={modalOpen} onClose={close} toteId={toteId} reverse={reverse} />
    </>
  );
};
