import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { Bin } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { useActiveSession } from '../../../../shared/hooks';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { logger } from '../../../../shared/clients/loggingApi';
import { wmsActionApi } from '../../../../shared/clients/wmsApi';
import { createTrace } from '../../../../framework/correlation';
import { SubstationToteBins } from '../../../../shared/components/SubstationToteBins';
import { toteByPositionState, useRecoilAsync } from '../../../../shared/state';
import { Instructions } from './Instructions';

interface ExtractStockInstructionsProps {
  bin: Bin;
  allocation: Allocation;
  position: 'left' | 'right';
  moveLocation: string | undefined;
}

export const ExtractStockInstructions: FunctionComponent<ExtractStockInstructionsProps> = ({ bin, allocation, position, moveLocation }) => {
  const { data: tote } = useRecoilAsync(toteByPositionState(position));
  const { t } = useTranslation();
  const { activeSession } = useActiveSession();
  const product = useMemo(() => bin.content[0]?.product, [bin]);

  const [{ loading: confirmationInProgress }, confirm] = useAsyncFn(async () => {
    if (!activeSession) {
      toast.error(t('extractStock.notActiveSession'));

      return;
    }

    const traced = createTrace();

    try {
      await traced(wmsActionApi).updateAction({
        payload: {
          actionId: allocation.actionId!,
          mfcId: allocation.mfcId,
          stationId: allocation.stationId,
          targetHolderId: moveLocation,
        },
      });

      await traced(allocationsApi).updateByAllocationId(allocation.id, {
        payload: { status: AllocationStatus.Completed },
      });
    } catch (error) {
      toast.error(t('extractStock.failedToComplete'), { toastId: 'completeExtractStock' });

      logger.error('Failed to complete allocation for extract stock operation', { allocation, error });
    }
  }, [allocation, activeSession, moveLocation, t]);

  return (
    <Flex flexDirection={'column'} justifyContent={'space-between'} gap={45}>
      <Instructions loading={confirmationInProgress} product={product} confirm={confirm} />
      {tote ? (
        <Flex gap={15} justifyContent={'space-evenly'}>
          <SubstationToteBins
            toteId={tote.id}
            bins={tote.bins}
            orientation={tote.orientation}
            position={position}
            selectedBin={allocation.binId}
          />
        </Flex>
      ) : (
        <Flex />
      )}
    </Flex>
  );
};
