import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import { useToggle } from 'react-use';
import { useActiveSession } from '../../../hooks';
import { ManualScanButton } from './ManualScanButton';
import { ManualScanPopover } from './ManualScanPopover';

export const ManualScan: FunctionComponent = () => {
  const [menuOpen, toggleMenu] = useToggle(false);
  const manualScanButtonRef = useRef(null);
  const { activeSession } = useActiveSession();

  const openMenu = useCallback(() => {
    toggleMenu(true);
  }, [toggleMenu]);

  const closeMenu = useCallback(() => {
    toggleMenu(false);
  }, [toggleMenu]);

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.ctrlKey && e.altKey) {
        toggleMenu();
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [toggleMenu]);

  return (
    <>
      <ManualScanButton ref={manualScanButtonRef} onClick={openMenu} disabled={!activeSession} />
      <ManualScanPopover open={menuOpen} close={closeMenu} anchorEl={manualScanButtonRef.current} />
    </>
  );
};
