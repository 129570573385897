import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { ExceptionReasonCode } from '@get-fabric/wms-api-client';
import { ReasonCodeOption } from './ReasonCodeOption';
import { SelectedReasonCodeContext } from './SelectedReasonCodeContext';

interface ReasonCodeSelectionProps {
  reasonCodes: ExceptionReasonCode[];
  onSelect: (reasonCode: ExceptionReasonCode) => void;
}

export const ReasonCodeSelection: FunctionComponent<ReasonCodeSelectionProps> = ({ reasonCodes, onSelect }) => {
  const [selectedReasonCode, setSelectedReasonCode] = useState<ExceptionReasonCode>();

  const selectReasonCode = useCallback(
    (reasonCode: ExceptionReasonCode) => {
      setSelectedReasonCode(reasonCode);
      onSelect(reasonCode);
    },
    [onSelect],
  );

  return (
    <SelectedReasonCodeContext.Provider value={selectedReasonCode}>
      <Flex flexWrap={'wrap'} gap={6}>
        {reasonCodes.map((reasonCode) => (
          <ReasonCodeOption key={reasonCode} reasonCode={reasonCode} selectReasonCode={selectReasonCode} />
        ))}
      </Flex>
    </SelectedReasonCodeContext.Provider>
  );
};
