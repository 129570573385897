import { useCallback, useState } from 'react';

export const useDatePicker = (defaultDate?: Date | null) => {
  const [pickedDate, setPickedDate] = useState<Date | null>(defaultDate ?? null);
  const [dateMissing, setDateMissing] = useState(false);

  const onClickConfirmDate = useCallback((date: Date) => {
    setPickedDate(date);
    setDateMissing(false);
  }, []);
  const onClickConfirmDateMissing = useCallback(() => {
    setPickedDate(null);
    setDateMissing(true);
  }, []);
  const resetDatePicker = useCallback(() => {
    setPickedDate(null);
    setDateMissing(false);
  }, []);

  return [
    { pickedDate, dateMissing },
    {
      onClickConfirmDate,
      onClickConfirmDateMissing,
      resetDatePicker,
    },
  ] as const;
};
