import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useContext, useMemo } from 'react';
import { LiveAllocationContext } from '../providers/LiveAllocationsProvider';

const unfulfilledAllocationStatuses = new Set([
  AllocationStatus.Created,
  AllocationStatus.Approved,
  AllocationStatus.PendingCancel,
  AllocationStatus.Ongoing,
]);

export const useUnfulfilledAllocations = () => {
  const { allocations: allAllocations } = useContext(LiveAllocationContext);

  return useMemo<Allocation[]>(() => allAllocations.filter(({ status }) => unfulfilledAllocationStatuses.has(status)), [allAllocations]);
};
