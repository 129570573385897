import React from 'react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReleaseOrderHolder } from './shared/ReleaseOrderHolder';

interface ReleaseEmptyOrderHolderProps {
  position: string;
  orderToteId: string;
}

export const ReleaseEmptyOrderHolder: FunctionComponent<ReleaseEmptyOrderHolderProps> = ({ position, orderToteId }) => {
  const { t } = useTranslation();

  return <ReleaseOrderHolder position={position} orderToteId={orderToteId} title={t('releaseOrderHolder.pickingDone')} />;
};
