import type { FunctionComponent } from 'react';
import React from 'react';
import type { DecantAction } from '@get-fabric/action-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { DecantActionProduct } from '@get-fabric/wms-api-client';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { ExceptionsModal } from '../../../../shared/components/Exceptions';
import { DecantExceptionsSteps } from './DecantExceptionsSteps';

interface DecantExceptionsModalProps {
  open: boolean;
  close: () => void;
  action: DecantAction;
  allocation: Allocation;
  onReportException: (exception: ExceptionType) => void;
  product: DecantActionProduct;
}

const decantExceptions = [ExceptionType.RejectedItem, ExceptionType.BinIsNotEmpty];

export const DecantExceptionsModal: FunctionComponent<DecantExceptionsModalProps> = ({
  open,
  close,
  action,
  allocation,
  onReportException,
  product,
}) => (
  <ExceptionsModal open={open} close={close} exceptions={decantExceptions}>
    <DecantExceptionsSteps action={action} product={product} allocation={allocation} onReportException={onReportException} />
  </ExceptionsModal>
);
