import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';

const translations = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'en-US': enTranslation,
};

void i18n.use(initReactI18next).init({
  resources: translations,
  lng: 'en-US',
  fallbackLng: 'en-US',

  interpolation: {
    escapeValue: false,
  },
});

export { default } from 'i18next';
