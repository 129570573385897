import type { FunctionComponent } from 'react';
import React, { useCallback, useContext } from 'react';
import type { ExceptionReasonCode } from '@get-fabric/wms-api-client';
import { Card } from '@mui/material';
import { Text, lightTheme } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { SelectedReasonCodeContext } from './SelectedReasonCodeContext';

interface ReasonCodeOptionProps {
  reasonCode: ExceptionReasonCode;
  selectReasonCode: (reasonCode: ExceptionReasonCode) => void;
}

export const ReasonCodeOption: FunctionComponent<ReasonCodeOptionProps> = ({ reasonCode, selectReasonCode }) => {
  const { t } = useTranslation();
  const selectedReasonCode = useContext(SelectedReasonCodeContext);

  const select = useCallback(() => {
    selectReasonCode(reasonCode);
  }, [reasonCode, selectReasonCode]);

  return (
    <Card
      sx={{
        width: '150px',
        height: '150px',
        boxSizing: 'border-box',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        border: selectedReasonCode === reasonCode ? `${lightTheme.colors.purple[700]} 2px solid` : '',
        opacity: !!selectedReasonCode && selectedReasonCode !== reasonCode ? '0.6' : '',
        transition: 'all 100ms ease-in-out',
      }}
      onClick={select}
      data-testid={`reasonCode-${reasonCode}`}
    >
      <Text size={22} textAlign={'center'}>
        {t(`exceptions.reasonCodes.${reasonCode}`)}
      </Text>
    </Card>
  );
};
