import type { FunctionComponent } from 'react';
import React from 'react';
import { ModalBody, ModalFooter, ModalHeader, Button, Modal } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';

interface ToteOverflowModalProps {
  confirm: () => void;
  open: boolean;
}

export const ToteOverflowModal: FunctionComponent<ToteOverflowModalProps> = ({ open, confirm }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} closeOnOverlayClick={false} data-testid={'toteOverflowModal'}>
      <ModalHeader>{t('toteOverflowModal.title')}</ModalHeader>
      <ModalBody>{t('toteOverflowModal.instructions')}</ModalBody>
      <ModalFooter>
        <Button variant={'primary'} size={'s'} onClick={confirm}>
          {t('toteOverflowModal.confirmButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
