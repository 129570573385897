import React from 'react';
import type { FunctionComponent } from 'react';
import type { ButtonProps } from '@get-fabric/fabric-design-system';
import { Button, Flex } from '@get-fabric/fabric-design-system';
import Report from '@mui/icons-material/Report';
import { useActiveSession } from '../../hooks';

interface ReportExceptionButtonProps extends ButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const ReportExceptionButton: FunctionComponent<ReportExceptionButtonProps> = ({ onClick, disabled, ...props }) => {
  const { activeSession } = useActiveSession();

  return (
    <Button
      size={'xs'}
      variant={'secondary'}
      disabled={!activeSession || disabled}
      onClick={onClick}
      aria-label={'report exception'}
      {...props}
    >
      <Flex alignItems={'center'} gap={2}>
        <Report />
      </Flex>
    </Button>
  );
};
