import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import type { DecantAction } from '@get-fabric/action-api-client';
import type { DecantActionProduct, ExceptionReasonCode } from '@get-fabric/wms-api-client';
import { v4 as uuid } from 'uuid';
import { ModalHeader } from '@get-fabric/fabric-design-system';
import { stationIdState, mfcIdState } from '../../../state';
import { exceptionApi } from '../../../clients/wmsApi';
import { ExceptionType } from '../exceptionTypes';
import { getExpirationDateValue } from '../../../services/expiration';
import { logger } from '../../../clients/loggingApi';
import { wmsErrorHandler, wmsErrorTypes } from '../wmsErrorHandler';
import { MoveRejectedItemsStep } from './MoveRejectedItemsStep';
import { RejectedItemExceptionForm } from './RejectedItemExceptionForm';

interface RejectedItemExceptionProps {
  action: DecantAction;
  product: DecantActionProduct;
  onReportException: (exception: ExceptionType) => void;
}

interface RejectedItemParams {
  reasonCode: ExceptionReasonCode;
  quantity: number;
}

export const RejectedItemException: FunctionComponent<RejectedItemExceptionProps> = ({ action, product, onReportException }) => {
  const { t } = useTranslation();
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);
  const [rejectedItemParams, setRejectedItemParams] = useState<RejectedItemParams>();
  const [rejectedTrolleyLocation, setRejectedTrolleyLocation] = useState<string>();

  const submitExceptionForm = useCallback(
    async (exceptionProps: RejectedItemParams) => {
      try {
        const { destination } = await exceptionApi.damagedTrolley({
          payload: {
            mfcId,
            stationId,
            sku: action.requirements.product.sku,
          },
          meta: {
            correlationId: uuid(),
          },
        });

        if (!destination) {
          logger.error('no rejected items destination was found', {
            action,
          });
          toast.error(t('exceptions.rejectedItem.noDamagedTrolleyError'), { toastId: 'noDamagedTrolleyError' });

          return;
        }

        setRejectedTrolleyLocation(destination);
        setRejectedItemParams(exceptionProps);
      } catch (error) {
        wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed to get rejected items destination', {
          mfcId,
          stationId,
          action,
        });
      }
    },
    [setRejectedTrolleyLocation, setRejectedItemParams, mfcId, stationId, action, t],
  );

  const submitException = useCallback(async () => {
    if (!rejectedItemParams) {
      return;
    }

    try {
      await exceptionApi.rejectedItem({
        payload: {
          exception: {
            actionId: action.id,
            targetBinId: rejectedTrolleyLocation,
            sku: action.requirements.product.sku,
            expirationDate: getExpirationDateValue(product.expiryDateMandatory && !product.shelfLife, null),
            ...rejectedItemParams,
          },
          stationId,
          mfcId,
        },
        meta: { correlationId: uuid() },
      });

      onReportException(ExceptionType.RejectedItem);
    } catch (error) {
      wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed to report rejected item exception', {
        mfcId,
        stationId,
        action,
        rejectedItemParams,
        rejectedTrolleyLocation,
      });
    }
  }, [onReportException, stationId, mfcId, action, rejectedTrolleyLocation, rejectedItemParams, product]);

  return (
    <>
      <ModalHeader>{t('exceptions.rejectedItem.title')}</ModalHeader>
      {!rejectedItemParams || !rejectedTrolleyLocation ? (
        <RejectedItemExceptionForm onSubmit={submitExceptionForm} />
      ) : (
        <MoveRejectedItemsStep submitException={submitException} damagedTrolleyLocation={rejectedTrolleyLocation} />
      )}
    </>
  );
};
