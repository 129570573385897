import type { FunctionComponent } from 'react';
import React from 'react';
import { Button } from '@get-fabric/fabric-design-system';
import { useAsyncFn } from 'react-use';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import type { UUID } from '@get-fabric/station-api-types';
import { useTranslation } from 'react-i18next';
import { releaseOrderHolder } from '../services';
import { logger } from '../../../../shared/clients/loggingApi';
import { mfcIdState, stationIdState } from '../../../../shared/state';
import { useActiveSession } from '../../../../shared/hooks';

interface NewOrderToteButtonProps {
  orderToteId: UUID;
  position: string;
}

export const NewOrderToteButton: FunctionComponent<NewOrderToteButtonProps> = ({ orderToteId, position }) => {
  const { t } = useTranslation();
  const { activeSession } = useActiveSession();
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);

  const [{ loading: releasingHolder }, clearOrderHolder] = useAsyncFn(async () => {
    const correlationId = uuid();

    try {
      await releaseOrderHolder({ position, stationId, orderToteId, mfcId, correlationId });
    } catch (error) {
      toast.error(t('releaseOrderHolder.failed'), { toastId: 'releaseOrderHolderFailed' });
      logger.error('failed to release order holder', { error, correlationId });
    }
  }, [position, stationId, orderToteId, mfcId, t]);

  return (
    <Button size={'l'} variant={'secondary'} onClick={clearOrderHolder} loading={releasingHolder} disabled={!activeSession}>
      {t('pickingOut.newTote')}
    </Button>
  );
};
