import React from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SubstationContainer } from '../../../../shared/components/Substations';
import type { Tote } from '../../../../shared/state';
import { SubstationToteBins } from '../../../../shared/components/SubstationToteBins';

interface SubstationStepsProps {
  position: 'left' | 'right';
  tote: Tote;
  binId: string;
}

const StyledSubstationContainer = styled(SubstationContainer)`
  position: relative;
`;

export const Substation: FunctionComponent<SubstationStepsProps> = ({ position, binId, tote: { id, orientation, bins } }) => (
  <StyledSubstationContainer inactive={false} data-testid={`${position}-substation`} active>
    <SubstationToteBins toteId={id} bins={bins} position={position} selectedBin={binId} orientation={orientation} />
  </StyledSubstationContainer>
);
