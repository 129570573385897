import type { FunctionComponent } from 'react';
import React from 'react';
import type { Tote } from '@get-fabric/wms-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { ExceptionsModal } from '../../../../shared/components/Exceptions';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { PickingOutExceptionsSteps } from './PickingOutExceptionsSteps';

interface PickingOutExceptionsModalProps {
  open: boolean;
  close: () => void;
  onReportException: (exception: ExceptionType) => void;
  tote: Tote;
  allocation: Allocation;
}

const pickingOutExceptions = [ExceptionType.BinIsEmpty, ExceptionType.DamagedItem, ExceptionType.WrongItemInBin];

export const PickingOutExceptionsModal: FunctionComponent<PickingOutExceptionsModalProps> = ({
  open,
  close,
  onReportException,
  tote,
  allocation,
}) => (
  <ExceptionsModal open={open} close={close} exceptions={pickingOutExceptions}>
    <PickingOutExceptionsSteps onReportException={onReportException} tote={tote} allocation={allocation} />
  </ExceptionsModal>
);
