import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HandScannerIcon } from './HandScannerIcon';

interface ManualScanButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export const ManualScanButton = React.forwardRef<HTMLButtonElement, ManualScanButtonProps>(({ onClick, disabled }, ref) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('manualScan.tooltip') ?? ''}>
      <span>
        <IconButton ref={ref} onClick={onClick} aria-label={'manual scan'} disabled={disabled}>
          <HandScannerIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
});
