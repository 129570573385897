import type { FunctionComponent } from 'react';
import React, { useMemo, useContext } from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { Tote } from '../../../../shared/state';
import { AllocationContext } from '../providers/AllocationProvider';
import { Loading } from '../../../../shared/components/Loading';
import { SubstationToteBins } from '../../../../shared/components/SubstationToteBins';
import { useCompletedToteBins } from '../../../../shared/hooks';
import { ActionContext } from '../providers/ActionProvider';
import { BinIsNotEmpty } from './BinIsNotEmpty';
import { BinIsEmpty } from './BinIsEmpty';
import { BinBlindCheck } from './BinBlindCheck';

interface QcProps {
  tote: Tote;
  position: 'left' | 'right';
}

export const Qc: FunctionComponent<QcProps> = ({ tote, position }) => {
  const { binId } = useContext(AllocationContext);
  const {
    task: { blindCheck },
  } = useContext(ActionContext);
  const bin = useMemo(() => tote.bins.find(({ id }) => id === binId), [tote.bins, binId]);

  const completedBins = useCompletedToteBins(tote);

  if (!bin) {
    return <Loading />;
  }

  return (
    <Flex alignItems={'center'} justifyContent={'center'} gap={80} flexDirection={'column'} fullWidth>
      {blindCheck ? <BinBlindCheck bin={bin} /> : bin.content.length ? <BinIsNotEmpty bin={bin} /> : <BinIsEmpty />}
      <SubstationToteBins
        toteId={tote.id}
        bins={tote.bins}
        orientation={tote.orientation}
        position={position}
        selectedBin={binId}
        completedBins={completedBins}
        enabled={!blindCheck}
      />
    </Flex>
  );
};
