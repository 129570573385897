import type { FunctionComponent } from 'react';
import React from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { ExceptionsModal } from '../../../../shared/components/Exceptions';
import { BinIsNotEmptyExceptions } from './BinIsNotEmptyExceptions';

interface BinIsNotEmptyExceptionModalProps {
  bin: Bin;
  open: boolean;
  close: () => void;
  onReportException: (exception: ExceptionType) => void;
}

export const BinIsNotEmptyExceptionModal: FunctionComponent<BinIsNotEmptyExceptionModalProps> = ({
  bin,
  open,
  close,
  onReportException,
}) => (
  <ExceptionsModal open={open} close={close} exceptions={[ExceptionType.BinIsEmpty, ExceptionType.WrongItemInBin]}>
    <BinIsNotEmptyExceptions bin={bin} onReportException={onReportException} />
  </ExceptionsModal>
);
