import { Flex } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';

export const Content = styled(Flex).attrs({ role: 'main' })`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
`;
