import type { FunctionComponent } from 'react';
import React from 'react';
import { ModalBody, ModalHeader } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import type { ExceptionType } from '../exceptionTypes';
import { ExceptionOptions } from './ExceptionOptions';

interface SelectExceptionStepProps {
  exceptions: ExceptionType[];
  selectException: (exception: ExceptionType | null) => void;
}

export const ExceptionSelection: FunctionComponent<SelectExceptionStepProps> = ({ exceptions, selectException }) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader>{t('exceptions.title')}</ModalHeader>
      <ModalBody>
        <ExceptionOptions exceptions={exceptions} onOptionClick={selectException} />
      </ModalBody>
    </>
  );
};
