import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import { Button, Flex, ModalFooter } from '@get-fabric/fabric-design-system';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { logger } from '../../../clients/loggingApi';
import { ReportExceptionContext } from './ReportExceptionProvider';

interface ExceptionModalFooterProps {
  reportException: () => Promise<void>;
  disableReport?: boolean;
  successToast?: boolean;
}

export const ExceptionModalFooter: FunctionComponent<ExceptionModalFooterProps> = ({
  reportException,
  disableReport,
  successToast = true,
}) => {
  const { resetExceptionState } = useContext(ReportExceptionContext);
  const { t } = useTranslation();

  const [{ loading }, submitException] = useAsyncFn(async () => {
    try {
      await reportException();

      if (successToast) {
        toast.success(t('exceptions.exceptionReportedSuccessfully'), { toastId: 'exceptionsReported' });
      }

      resetExceptionState();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : null;

      toast.error(t('exceptions.exceptionReportedFailed', { errorMessage: errorMessage ? `: ${errorMessage}` : '' }), {
        toastId: 'exceptionReportFail',
      });
      logger.error('failed to report exception', { error });
    }
  }, [reportException, resetExceptionState, successToast, t]);

  return (
    <ModalFooter>
      <Flex gap={8}>
        <Button size={'s'} variant={'secondary'} onClick={resetExceptionState} disabled={loading}>
          {t('actions.cancel')}
        </Button>
        <Button disabled={disableReport} loading={loading} onClick={submitException} size={'s'}>
          {t('exceptions.report')}
        </Button>
      </Flex>
    </ModalFooter>
  );
};
