import type { FunctionComponent } from 'react';
import React from 'react';
import { ModalBody, ModalFooter, ModalHeader, Button, Modal } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';

interface ToteOverweightModalProps {
  confirm: () => void;
  open: boolean;
}

export const ToteOverweightModal: FunctionComponent<ToteOverweightModalProps> = ({ open, confirm }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} closeOnOverlayClick={false} data-testid={'toteOverweightModal'}>
      <ModalHeader>{t('toteOverweightModal.title')}</ModalHeader>
      <ModalBody>{t('toteOverweightModal.instructions')}</ModalBody>
      <ModalFooter>
        <Button variant={'primary'} size={'s'} onClick={confirm}>
          {t('actions.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
