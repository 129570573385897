import React from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Flex } from '@get-fabric/fabric-design-system';
import type { PaperProps } from '@mui/material';
import { Chip, Paper } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { stationState } from '../../state';
import { useActiveSession } from '../../hooks';
import { User } from './User';
import { StationInfo } from './DevTools/StationInfo';
import { TouchpointMode } from './TouchpointMode';
import { ManualScan } from './ManualScan';
import { ActiveSession } from './ActiveSession';

interface TopNavProps extends PaperProps {
  $activeSession: boolean;
}

const topNavInactiveShadow = '1px 1px 1px 1px rgba(244, 67, 54, 0.7), 1px 0px 1px 1px red, 1px 1px 1px 3px rgba(244, 67, 54, 0.7)';

const StyledTopNav = styled<FunctionComponent<TopNavProps>>(Paper).attrs(({ $activeSession }) => ({
  sx: $activeSession ? {} : { boxShadow: topNavInactiveShadow },
}))`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  position: relative;
`;

export const TopNav: FunctionComponent = () => {
  const { mfcId, name, mode } = useRecoilValue(stationState);
  const { activeSession } = useActiveSession();

  return (
    <StyledTopNav $activeSession={activeSession} elevation={2}>
      <TouchpointMode mode={mode} />
      <Flex alignItems={'center'} flexDirection={'row'} gap={15} px={15} justifyContent={'space-between'} fullWidth>
        <Flex gap={4}>
          <Chip variant={'outlined'} color={'primary'} label={mfcId} />
          <Chip variant={'outlined'} color={'primary'} label={name.toUpperCase()} />
        </Flex>
        <Flex gap={2} alignItems={'center'}>
          <ActiveSession />
          <StationInfo />
          <ManualScan />
          <User />
        </Flex>
      </Flex>
    </StyledTopNav>
  );
};
