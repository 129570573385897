import { Button, Flex, ModalBody, ModalFooter, NumberInput, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExceptionReasonCode } from '@get-fabric/wms-api-client';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useAsyncFn } from 'react-use';
import { ReasonCodeSelection } from '../ReasonCodeSelection';

interface RejectedItemExceptionFormProps {
  onSubmit: (rejectedItemParams: { reasonCode: ExceptionReasonCode; quantity: number }) => Promise<void>;
}

const QuantityInput = styled(NumberInput)`
  width: 200px;
`;

const reasonCodes = [ExceptionReasonCode.DamagedBySupplier, ExceptionReasonCode.DamagedByFabric];

export const RejectedItemExceptionForm: FunctionComponent<RejectedItemExceptionFormProps> = ({
  onSubmit,
}: RejectedItemExceptionFormProps) => {
  const [reasonCode, setReasonCode] = useState<ExceptionReasonCode>();
  const [quantity, setQuantity] = useState<number>(0);
  const { t } = useTranslation();

  const isFormValid = useMemo(() => !!(reasonCode && quantity), [reasonCode, quantity]);

  const quantityChanged = useCallback(
    (quantity: number) => {
      setQuantity(quantity);

      if (!quantity) {
        toast.warn(t('exceptions.rejectedItem.greaterThanQuantity'), { toastId: 'greaterThanQuantity' });

        return;
      }
    },
    [t],
  );

  const [{ loading }, onSubmitClick] = useAsyncFn(async () => {
    if (!isFormValid) {
      return;
    }

    await onSubmit({ quantity, reasonCode: reasonCode! });
  }, [isFormValid, quantity, reasonCode, onSubmit]);

  return (
    <>
      <ModalBody>
        <Flex gap={64} justifyContent={'space-between'} flexDirection={'column'}>
          <Flex flexDirection={'column'} gap={4}>
            <Text size={28}>{t('exceptions.rejectedItem.selectReason')}</Text>
            <ReasonCodeSelection reasonCodes={reasonCodes} onSelect={setReasonCode} />
          </Flex>
          <Flex flexDirection={'column'} gap={4}>
            <Text size={28}>{t('exceptions.rejectedItem.selectQuantity')}</Text>
            <QuantityInput value={quantity} onChange={quantityChanged} aria-label={'quantity'} />
          </Flex>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!isFormValid} onClick={onSubmitClick} size={'s'} loading={loading}>
          {t('exceptions.rejectedItem.formSubmitButton')}
        </Button>
      </ModalFooter>
    </>
  );
};
