import type { FunctionComponent } from 'react';
import React from 'react';
import { SvgIcon } from '@mui/material';

interface TouchpointLogoProps {
  size: number;
}

export const TouchpointLogo: FunctionComponent<TouchpointLogoProps> = ({ size }) => (
  <SvgIcon style={{ width: size, height: size }}>
    <svg version={'1.1'} xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 64 64'} enableBackground={'new 0 0 64 64'}>
      <defs>
        <filter id={'iconShadow'} x={'-100%'} y={'-100%'} width={'300%'} height={'300%'}>
          <feDropShadow dx={'0'} dy={'6'} stdDeviation={'12'} floodColor={'#000000'} floodOpacity={'0.48'} />
        </filter>
      </defs>
      <g>
        <path fill={'#5C41E4'} d={'M60,64H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h56c2.2,0,4,1.8,4,4v56C64,62.2,62.2,64,60,64z'} />
        <path
          filter={'url(#iconShadow)'}
          fill={'#FFFFFF'}
          d={
            'M26.6,28.2V22c0-2.3,1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2v6.2c2-1.4,3.3-3.6,3.3-6.2c0-4.1-3.3-7.5-7.5-7.5s-7.5,3.4-7.5,7.5C23.2,24.6,24.6,26.9,26.6,28.2z M43,36l-7.6-3.8c-0.3-0.1-0.6-0.2-0.9-0.2h-1.3V22c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5v17.9l-5.7-1.2c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.2-1.3,0.5l-1.3,1.3l8.2,8.2c0.5,0.5,1.1,0.7,1.8,0.7h11.3c1.2,0,2.2-0.9,2.4-2.1l1.2-8.8c0-0.1,0-0.2,0-0.3C44.5,37.2,43.9,36.3,43,36z'
          }
        />
      </g>
    </svg>
  </SvgIcon>
);
