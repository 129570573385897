import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import { logger } from '../../../../shared/clients/loggingApi';
import { useOrderTotesInPosition } from '../../../../shared/hooks';
import { updateTotesRequestId } from '../../../../shared/clients/stationApi';
import { stationIdState } from '../../../../shared/state';

export const useAssignOrderToTray = (
  position: 'left' | 'right',
  allocation: Allocation,
  pickingTrayKey: string | undefined,
  activeSession: boolean,
) => {
  const { t } = useTranslation();
  const stationId = useRecoilValue(stationIdState);
  const orderTotesInPosition = useOrderTotesInPosition(position);

  return useAsync(async () => {
    if (pickingTrayKey || !activeSession) {
      return;
    }

    const availableTray = orderTotesInPosition.find(({ orderTote }) => !orderTote?.totesRequestId);

    if (!availableTray) {
      logger.error(`No available order tote for allocation id: ${allocation.id}, position: ${position}.`);
      toast.error(t('pickToTray.noAvailableOrderTote'), { toastId: 'noAvailableOrderTote' });

      return;
    }

    const correlationId = uuid();
    try {
      await updateTotesRequestId(allocation.totesRequestId, availableTray.substationKey, stationId, correlationId);
    } catch (error) {
      logger.error('During pickToTray, failed to update TotesRequestId', {
        error,
        stationId,
        allocationId: allocation.id,
        toteRequestId: allocation.totesRequestId,
        position,
        correlationId,
      });
      toast.error(t('pickingOut.failedMarkingAllocationAsOngoing'));
    }
  }, [allocation, orderTotesInPosition, pickingTrayKey, activeSession, stationId]);
};
