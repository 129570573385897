import type { FunctionComponent } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { Modal, ModalBody, ModalHeader, Text, Flex } from '@get-fabric/fabric-design-system';
import type { DecantAction } from '@get-fabric/action-api-client';
import { useTranslation } from 'react-i18next';
import { TaskOptions } from './TaskOptions';
import { TaskSelectionModalContext } from './TaskSelectionModalContext';

interface TaskSelectionModalProps {
  open: boolean;
  sku: string;
  actions: DecantAction[];
  close: () => void;
  selectTask: (taskId: string) => void;
}

export const TaskSelectionModal: FunctionComponent<TaskSelectionModalProps> = ({ open, sku, actions, close, selectTask }) => {
  const { t } = useTranslation();
  const { setLastSelectedTaskId } = useContext(TaskSelectionModalContext);

  const taskIds = useMemo<string[]>(() => {
    const skuActions = actions.filter((action) => action.requirements?.product?.sku === sku);

    return [...new Set(skuActions.map(({ task: { id: taskId } }) => taskId))];
  }, [sku, actions]);

  const tasksActions = useMemo(() => actions.filter(({ task: { id: taskId } }) => taskId && taskIds.includes(taskId)), [actions, taskIds]);

  const tasks = useMemo(() => {
    if (!open) {
      return [];
    }

    return [...new Map(tasksActions.map(({ task }) => [task.id, task])).values()];
  }, [open, tasksActions]);

  const onSelectTask = useCallback(
    (taskId: string) => {
      setLastSelectedTaskId(taskId);
      selectTask(taskId);
    },
    [selectTask, setLastSelectedTaskId],
  );

  return (
    <Modal open={open} onRequestClose={close} size={'l'} data-testid={'taskSelectionModal'} px={2} pb={8}>
      <ModalHeader>
        <Flex flexDirection={'column'} gap={4} pb={4}>
          {t('decant.taskSelectionModal.title')}
          <Text size={22}>{t('decant.taskSelectionModal.subtitle')}</Text>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <TaskOptions tasks={tasks} actions={tasksActions} sku={sku} onSelect={onSelectTask} />
      </ModalBody>
    </Modal>
  );
};
