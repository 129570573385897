import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { DecantAction } from '@get-fabric/action-api-client';
import { Flex, Modal, ModalBody, ModalHeader, Text } from '@get-fabric/fabric-design-system';
import { ActionOptions } from './ActionOptions';

interface ActionSelectionModalProps {
  open: boolean;
  actions: DecantAction[];
  close: () => void;
  selectAction: (actionId: string) => void;
}

export const ActionSelectionModal: FunctionComponent<ActionSelectionModalProps> = ({ open, close, actions, selectAction }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onRequestClose={close} size={'l'} data-testid={'actionSelectionModal'} px={2} pb={8}>
      <ModalHeader>
        <Flex flexDirection={'column'} gap={4} pb={4}>
          {t('decant.actionSelectionModal.title')}
          <Text size={22}>{t('decant.actionSelectionModal.subtitle')}</Text>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <ActionOptions actions={actions} onSelect={selectAction} />
      </ModalBody>
    </Modal>
  );
};
