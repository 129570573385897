import React from 'react';
import type { FunctionComponent } from 'react';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { Bin } from '@get-fabric/wms-api-client';
import { useAsyncFn } from 'react-use';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ActionBar } from '../../PickingOut/components/shared/ActionBar';
import { ReportExceptionButton } from '../../../../shared/components/Exceptions';
import { useModalState, useDamagedItemsLocation } from '../../../../shared/hooks';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { InstructionContainer } from '../../../../shared/components/InstructionContainer';
import { logger } from '../../../../shared/clients/loggingApi';
import { ExtractStockExceptionsModal } from './ExtractStockExceptionModal';
import { ExtractStockInstructions } from './ExtractStockInstructions';

interface ExtractStockProps {
  bin: Bin;
  allocation: Allocation;
  position: 'left' | 'right';
}

export const ExtractStock: FunctionComponent<ExtractStockProps> = ({ bin, allocation, position }) => {
  const { close, modalOpen, open } = useModalState();
  const { t } = useTranslation();
  const { destination: damagedTrolleyLocation } = useDamagedItemsLocation(allocation.mfcId, allocation.stationId);

  const [, onException] = useAsyncFn(async () => {
    try {
      return await allocationsApi.updateByAllocationId(allocation.id, { payload: { status: AllocationStatus.Cancelled } });
    } catch (error) {
      logger.error('Failed to cancel allocation after reporting extract stock exception ', {
        error,
        allocation,
        bin,
        position,
      });
      toast.error(t('extractStock.failedToCancelAllocation'), { toastId: 'cancelExtractStock' });
    }
  }, [allocation, bin, position, t]);

  return (
    <>
      <InstructionContainer p={3} flexDirection={'column'} fullWidth>
        <ActionBar>
          <ReportExceptionButton onClick={open} />
        </ActionBar>
        <ExtractStockInstructions bin={bin} allocation={allocation} position={position} moveLocation={damagedTrolleyLocation} />
      </InstructionContainer>
      <ExtractStockExceptionsModal
        open={modalOpen}
        close={close}
        onReportException={onException}
        bin={bin}
        actionId={allocation.actionId!}
      />
    </>
  );
};
