import { AllocationStatus } from '@get-fabric/allocation-api-client';
import type { QcCheckResult } from '@get-fabric/wms-api-client';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { operatorApi } from '../../../../shared/clients/wmsApi';
import { AllocationContext } from '../providers/AllocationProvider';
import { createTrace } from '../../../../framework/correlation';
import { logger } from '../../../../shared/clients/loggingApi';

export const useCompleteBin = () => {
  const allocation = useContext(AllocationContext);
  const { t } = useTranslation();
  const [{ loading: completingBin }, completeBin] = useAsyncFn(
    async (qcCheckResult: QcCheckResult) => {
      const traced = createTrace();

      try {
        await traced(operatorApi).confirmQc({
          payload: { actionId: allocation.actionId!, qcCheckResult },
        });
      } catch (error) {
        logger.error('failed updating QC action', {
          error,
          allocationId: allocation.id,
          actionId: allocation.actionId,
          qcCheckResult,
        });
        toast.error(t('qc.failedConfirmingQc'));

        return;
      }
      try {
        await traced(allocationsApi).updateByAllocationId(allocation.id, {
          payload: { status: AllocationStatus.Completed },
        });
      } catch (error) {
        logger.error('failed updating allocation status to completed', {
          error,
          allocationId: allocation.id,
          actionId: allocation.actionId,
          qcCheckResult,
        });
        toast.error(t('qc.failedMarkingAllocationAsCompleted'));

        return;
      }
    },
    [allocation, t],
  );

  return { completeBin, completingBin };
};
