import pDefer from 'p-defer';

const deferred = pDefer<() => Promise<string>>();

export const setTokenGenerator = (tokenGenerator: () => Promise<string>) => {
  deferred.resolve(tokenGenerator);
};

export const getToken = async (): Promise<string> => {
  const tokenGenerator = await deferred.promise;

  return tokenGenerator();
};
