import type { Station } from '@get-fabric/station-api-types';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAsync } from 'react-use';
import { useState } from 'react';
import { logger } from '../clients/loggingApi';

export const useLaunchDarklyIdentification = (station: Station | undefined) => {
  const [identified, setIdentified] = useState(false);
  const ldClient = useLDClient();
  const { id: stationId, mfcId, name } = station ?? {};

  useAsync(async () => {
    if (!name || !mfcId) {
      return;
    }

    try {
      await ldClient?.identify({ key: stationId, custom: { mfcId, station: name } });
      setIdentified(true);
    } catch (error) {
      logger.error('failed to identify for launch darkly', { error });
    }
  }, [name, mfcId, stationId, ldClient]);

  return identified;
};
