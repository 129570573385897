import type { Allocation, AllocationStatus } from '@get-fabric/allocation-api-client';
import { useMemo } from 'react';

export interface AllocationsByStatusProps {
  allocations: Allocation[];
  statuses: AllocationStatus[];
}

export const useAllocationsByStatus = ({ allocations, statuses }: AllocationsByStatusProps) => {
  const filteredAllocations = useMemo<Allocation[]>(
    () => allocations.filter(({ status }) => statuses.includes(status)),
    [allocations, statuses],
  );

  return { allocations: filteredAllocations };
};
