import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import type { Tote } from '../../../../shared/state';
import { productByAllocationIdState, useRecoilAsync } from '../../../../shared/state';
import { useActiveSession, useModalState, useOrderTotesInPosition } from '../../../../shared/hooks';
import { ReportExceptionButton } from '../../../../shared/components/Exceptions';
import { Loading } from '../../../../shared/components/Loading';
import { logger } from '../../../../shared/clients/loggingApi';
import { ActionBar } from '../../PickingOut/components/shared/ActionBar';
import { useMarkAllocationOngoing } from '../hooks/useMarkAllocationOngoing';
import { useAssignOrderToTray } from '../hooks/useAssignOrderToTray';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { ColoredTrayFlex } from './ColoredTrayFlex';
import { PickProductInstructions } from './PickProductInstructions';
import { PickToTrayExceptionsModal } from './PickToTrayExceptionsModal';
import { NothingToDo } from './NothingToDo';

interface InstructionsProps {
  position: 'left' | 'right';
  active: boolean;
  allocation: Allocation;
  inventoryTote: Tote;
}

export const Instructions: FunctionComponent<InstructionsProps> = ({ position, active, allocation, inventoryTote }) => {
  const { activeSession } = useActiveSession();
  const exceptionsModal = useModalState();
  const { t } = useTranslation();
  const orderTotesInPosition = useOrderTotesInPosition(position);

  const { data: product } = useRecoilAsync(productByAllocationIdState(allocation?.id));

  const pickingTray = useMemo(
    () => orderTotesInPosition.find(({ orderTote }) => orderTote?.totesRequestId === allocation.totesRequestId),
    [allocation.totesRequestId, orderTotesInPosition],
  );

  const { loading: markingAllocationOngoingOnTray } = useMarkAllocationOngoing(allocation, activeSession, active);

  const { loading: assigningOrderToTray } = useAssignOrderToTray(position, allocation, pickingTray?.substationKey, activeSession);

  const [, cancelAllocation] = useAsyncFn(async () => {
    try {
      return await allocationsApi.updateByAllocationId(allocation.id, { payload: { status: AllocationStatus.Cancelled } });
    } catch (error) {
      logger.error('Failed to cancel allocation after reporting wrong quantity exception ', {
        error,
        allocation,
      });
      toast.error(t('pickToTray.failedToCancelAllocation'), { toastId: 'cancelExtractStock' });
    }
  }, [allocation, t]);

  const onReportException = useCallback(
    async (exception: ExceptionType) => {
      if (exception === ExceptionType.WrongQuantityInBin) {
        await cancelAllocation();
      }
      exceptionsModal.close();
    },
    [cancelAllocation, exceptionsModal],
  );

  if (!product || markingAllocationOngoingOnTray || assigningOrderToTray) {
    return <Loading />;
  }

  if (allocation.status !== AllocationStatus.Ongoing) {
    return <NothingToDo active={active} activeSession={activeSession} />;
  }

  return (
    <>
      <ColoredTrayFlex
        flexGrow={1}
        flexDirection={'column'}
        trayPosition={pickingTray?.substationKey}
        data-testid={`pickingInstruction-${pickingTray?.substationKey}`}
      >
        <ActionBar>
          <ReportExceptionButton onClick={exceptionsModal.open} />
        </ActionBar>
        <PickProductInstructions allocation={allocation} position={position} inventoryTote={inventoryTote} product={product} />
      </ColoredTrayFlex>
      <PickToTrayExceptionsModal
        open={exceptionsModal.modalOpen}
        close={exceptionsModal.close}
        onReportException={onReportException}
        tote={inventoryTote}
        allocation={allocation}
        product={product}
      />
    </>
  );
};
