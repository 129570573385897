import { Configuration, WmsActionApi, ToteApi, ExceptionApi, ProductsApi, StockApi, OperatorApi } from '@get-fabric/wms-api-client';
import { enhancedFetchNoRetry } from '../../framework/enhancedFetch';
import { wmsApiBasePath } from '../../framework/environment';

const wmsApiConfiguration = new Configuration({
  basePath: `${wmsApiBasePath}/v1`,
  fetchApi: enhancedFetchNoRetry,
});

export const wmsActionApi = new WmsActionApi(wmsApiConfiguration);
export const toteApi = new ToteApi(wmsApiConfiguration);
export const exceptionApi = new ExceptionApi(wmsApiConfiguration);
export const productsApi = new ProductsApi(wmsApiConfiguration);
export const stockApi = new StockApi(wmsApiConfiguration);
export const operatorApi = new OperatorApi(wmsApiConfiguration);
