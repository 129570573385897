import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import { VerticalDivider } from '../../../shared/components/Substations';
import { Substation } from './components/Substation';

export const ExtractStock: FunctionComponent = () => (
  <Flex flexGrow={1} flexShrink={0} flexBasis={'auto'} fullWidth>
    <Substation position={'left'} />
    <VerticalDivider />
    <Substation position={'right'} />
  </Flex>
);
