import type { FunctionComponent } from 'react';
import React from 'react';
import { useModalState } from '../../../../hooks';
import { StationInfoButton } from './StationInfoButton';
import { StationInfoModal } from './StationInfoModal';

export const StationInfo: FunctionComponent = () => {
  const { open, modalOpen, close } = useModalState();

  return (
    <>
      <StationInfoButton onClick={open} />
      <StationInfoModal open={open} modalOpen={modalOpen} close={close} />
    </>
  );
};
