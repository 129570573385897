import type { FunctionComponent } from 'react';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import type { Bin, CatalogProduct } from '@get-fabric/wms-api-client';
import { useRecoilValue } from 'recoil';
import type { FetchError } from '@osskit/fetch-enhancers';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { useAsyncFn } from 'react-use';
import { productsApi } from '../../../../shared/clients/wmsApi';
import { mfcIdState } from '../../../../shared/state';
import { useModalState, useScanProduct } from '../../../../shared/hooks';
import { logger } from '../../../../shared/clients/loggingApi';
import { ActionContext } from '../providers/ActionProvider';
import { BinIsNotEmptyExceptionModal } from './BinIsNotEmptyExceptionModal';

interface VerifyProductProps {
  bin: Bin;
  onComplete: (reportedProduct?: CatalogProduct) => void;
  onException: () => void;
}

export const VerifyProduct: FunctionComponent<VerifyProductProps> = ({ bin, onComplete, onException }) => {
  const {
    task: { blindCheck },
  } = useContext(ActionContext);
  const { t } = useTranslation();
  const { open, close, modalOpen } = useModalState();
  const mfcId = useRecoilValue(mfcIdState);

  const blindCheckBinIsEmpty = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const [{ loading: scanningItem }, onScan] = useAsyncFn(
    async (barcode: string) => {
      if (!bin || modalOpen) {
        return;
      }

      let products: CatalogProduct[] = [];
      try {
        products = await productsApi.getProducts(barcode, mfcId, uuid());
      } catch (error) {
        if ((error as FetchError).status !== 404) {
          logger.error('failed to fetch scanned products for verification', {
            error,
            barcode,
          });
          throw error;
        }
      }

      if (!products.length) {
        toast.error(t('qc.productNotFound'));

        return;
      }

      if (blindCheck) {
        onComplete(products[0]);

        return;
      }

      if (!bin.content[0].product.barcodes.includes(barcode)) {
        toast.error(t('qc.wrongProductScanned'));

        return;
      }

      onComplete();
    },
    [bin, mfcId, onComplete, t, modalOpen, blindCheck],
  );

  useScanProduct(onScan);

  return (
    <>
      <Flex flexDirection={'column'} alignItems={'center'} gap={30}>
        <Text size={40}>{t('qc.scanProduct')}</Text>
        <Flex gap={4}>
          {blindCheck ? (
            <Button disabled={scanningItem} size={'s'} onClick={blindCheckBinIsEmpty}>
              {t('qc.binIsEmpty')}
            </Button>
          ) : (
            <Button disabled={scanningItem} size={'s'} onClick={open}>
              {t('qc.reportProblemButton')}
            </Button>
          )}
          {scanningItem && <Spinner />}
        </Flex>
      </Flex>
      <BinIsNotEmptyExceptionModal open={modalOpen} close={close} onReportException={onException} bin={bin} />
    </>
  );
};
