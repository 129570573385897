import { atom, selector } from 'recoil';
import type { Allocation } from '@get-fabric/allocation-api-client';

export const allocationsAtom = atom<Allocation[]>({
  key: 'allocations',
  default: [],
});

export const allocationsState = selector<Allocation[]>({
  key: 'allocationsState',
  get: ({ get }) => {
    const allocations = get(allocationsAtom);

    if (!allocations) {
      throw new Error('no allocations');
    }

    return allocations;
  },
});
