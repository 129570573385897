import { Button, Flex, NumberInput, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React, { useContext, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { useAsyncFn } from 'react-use';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import type { Bin } from '@get-fabric/wms-api-client';
import { useRecoilAsync, mfcIdState, stationIdState, toteByPositionState } from '../../../../shared/state';
import { ActionContext } from '../providers/ActionProvider';
import { exceptionApi } from '../../../../shared/clients/wmsApi';
import { Loading } from '../../../../shared/components/Loading';
import { useModalState } from '../../../../shared/hooks';
import { wmsErrorHandler, wmsErrorTypes } from '../../../../shared/components/Exceptions/wmsErrorHandler';
import { YesNoQuestionModal } from './YesNoQuestionModal';

interface Props {
  binId: string;
  onComplete: (reportedQuantity?: number) => void;
  onException: () => void;
}

const QuantityInput = styled(NumberInput)`
  width: 100px;
`;

export const CheckQuantity: FunctionComponent<Props> = ({ binId, onComplete, onException }) => {
  const { t } = useTranslation();
  const {
    id: actionId,
    task: { blindCheck },
  } = useContext(ActionContext);
  const mfcId = useRecoilValue(mfcIdState);
  const stationId = useRecoilValue(stationIdState);
  const { data: tote } = useRecoilAsync(toteByPositionState('right'));
  const [quantity, setQuantity] = useState<number>(0);
  const { open, close, modalOpen } = useModalState();
  const bin = useMemo(() => tote?.bins.find((b) => b.id === binId) as Bin | null, [tote, binId]);

  const [{ loading: modalOpening }, confirm] = useAsyncFn(
    async (quantity: number) => {
      if (!bin) {
        return;
      }

      if (blindCheck) {
        onComplete(quantity);

        return;
      }

      if (!modalOpen && quantity.toString() !== bin.content[0]?.quantity?.toString()) {
        open();
      } else {
        onComplete();
      }
    },
    [bin, modalOpen, onComplete, open, blindCheck],
  );

  const [, report] = useAsyncFn(
    async (quantity: number) => {
      if (!bin) {
        return;
      }

      try {
        await exceptionApi.wrongQuantity({
          payload: {
            exception: {
              sku: bin.content[0].product.sku,
              binId: bin.id,
              confirmedQuantity: quantity,
              companyCode: bin.content[0].product.companyCode,
            },
            mfcId,
            stationId,
            actionId,
          },
        });
      } catch (error) {
        try {
          wmsErrorHandler(error, wmsErrorTypes.wmsStockError, 'Failed updating bin quantity for QC', {
            bin,
            outdatedQuantity: bin.content[0]?.quantity,
            confirmedQuantity: quantity,
            mfcId,
            stationId,
          });
        } catch {
          toast.error(t('qc.failedUpdatingBinQuantity'));
        }

        return;
      }

      onException();
    },
    [bin, onException, t, mfcId, stationId, actionId],
  );

  const onConfirm = useCallback(() => confirm(quantity), [confirm, quantity]);
  const onReport = useCallback(() => report(quantity), [report, quantity]);

  if (!bin) {
    return <Loading />;
  }

  return (
    <>
      <Flex flexDirection={'column'} alignItems={'center'} gap={60}>
        <Flex gap={6}>
          <Text size={40}>{t('qc.enterBinQuantity')}</Text>
          <QuantityInput value={quantity} onChange={setQuantity} aria-label={'quantity'} />
        </Flex>
        <Button width={20} size={'s'} onClick={onConfirm} loading={modalOpening} disabled={quantity === 0}>
          {t('actions.confirm')}
        </Button>
      </Flex>
      <YesNoQuestionModal
        open={modalOpen}
        question={
          !blindCheck
            ? t('qc.availableQuantity', { quantity, expectedQuantity: bin.content[0]?.quantity })
            : t('qc.blindCheckQuantity', { quantity })
        }
        onYes={onReport}
        onNo={close}
      />
    </>
  );
};
