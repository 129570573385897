import React from 'react';
import type { FunctionComponent } from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import DoNotDisturb from '@mui/icons-material/DoNotDisturb';
import { ActiveSession } from '../../../../shared/components/TopNav/ActiveSession';

interface NothingToDoProps {
  active: boolean;
  activeSession: boolean;
}

export const NothingToDo: FunctionComponent<NothingToDoProps> = ({ active, activeSession }) => {
  const { t } = useTranslation();

  return (
    <Flex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={20}>
      <DoNotDisturb sx={{ fontSize: '100px' }} />
      {active && !activeSession ? (
        <>
          <Text size={28}>{t('pickToTray.notActiveSessionTitle')}</Text>
          <Text size={20}>{t('pickToTray.notActiveSessionSuggestion')}</Text>
          <ActiveSession />
        </>
      ) : null}
    </Flex>
  );
};
