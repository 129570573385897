import type { FunctionComponent } from 'react';
import React from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';

interface TaskCompletionProps {
  title: string;
  completed: number;
  required: number;
  allowOverflow?: boolean;
}

export const TaskCompletion: FunctionComponent<TaskCompletionProps> = ({ title, completed, required, allowOverflow = false }) => {
  const { t } = useTranslation();
  let percentage = Math.round((completed / required) * 100);
  if (!allowOverflow) {
    percentage = Math.min(percentage, 100);
  }

  return (
    <Flex flexDirection={'column'}>
      <Typography letterSpacing={0.8}>{title.toUpperCase()}</Typography>
      <Flex alignItems={'center'} flexDirection={'column'} gap={1}>
        <Flex justifyContent={'space-between'} alignItems={'flex-end'} fullWidth>
          <Text size={14} color={'gray.700'}>
            {t('decant.taskSelectionModal.taskOption.itemProgress', { completed, required })}
          </Text>
          <Text size={20}>{t('decant.taskSelectionModal.taskOption.percentage', { percentage })}</Text>
        </Flex>
        <LinearProgress variant={'determinate'} sx={{ width: '100%' }} value={Math.min(percentage, 100)} />
      </Flex>
    </Flex>
  );
};
