import type { Socket } from 'socket.io-client';
import { logger } from './loggingApi';

export const initSocketEvents = (socket: Socket) => {
  socket.on('connect_error', (error) => {
    logger.error('socket connect_error', { error });

    setTimeout(() => {
      socket.connect();
    }, 1000);
  });

  socket.on('error', (error) => {
    logger.error('socket error', { error });
  });

  socket.on('reconnect_error', (error) => {
    logger.error('socket reconnect_error', { error });
  });

  socket.on('reconnect_failed', () => {
    logger.error('socket reconnect_failed');
  });
};
