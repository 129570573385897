import { selector, selectorFamily } from 'recoil';
import type { CatalogProduct } from '@get-fabric/wms-api-client';
import { v4 as uuid } from 'uuid';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { logger } from '../clients/loggingApi';
import { queryClient } from '../../App/App';
import { productsApi } from '../clients/wmsApi';
import { allocationsState } from './allocations';

interface AllocationIdToProduct {
  allocationId: string;
  product: CatalogProduct;
}

const getProduct = async (allocation: Allocation, correlationId: string): Promise<AllocationIdToProduct> => {
  let product: CatalogProduct;

  try {
    product = await productsApi.getPickingActionProduct(allocation.actionId!, allocation.mfcId, correlationId);
  } catch (error) {
    logger.error('failed to fetch product for allocation', {
      error,
      allocation,
      correlationId,
    });
    throw error;
  }

  return { allocationId: allocation.id, product };
};

export const allocationsToProductsState = selector({
  key: 'allocationsToProductsState',

  async get({ get }) {
    const correlationId = uuid();

    const allocationStatusesToFetch = new Set([AllocationStatus.Approved, AllocationStatus.Ongoing, AllocationStatus.PendingCancel]);
    const allocationStatusesToInvalidate = new Set([AllocationStatus.Completed, AllocationStatus.Cancelled, AllocationStatus.Rejected]);

    const allocations = get(allocationsState).filter((allocation) => !!allocation.actionId);

    const [allocationToInvalidate, allocationsToFetch] = allocations.reduce(
      (partitionedAllocations, allocation) => {
        if (allocationStatusesToInvalidate.has(allocation.status)) {
          partitionedAllocations[0].push(allocation);
        }

        if (allocationStatusesToFetch.has(allocation.status)) {
          partitionedAllocations[1].push(allocation);
        }

        return partitionedAllocations;
      },
      [[] as Allocation[], [] as Allocation[]],
    );

    await Promise.allSettled(allocationToInvalidate.map((allocation) => queryClient.invalidateQueries(['products', allocation.id])));

    return Promise.all(
      allocationsToFetch.map((allocation) =>
        queryClient.fetchQuery(['products', allocation.id], () => getProduct(allocation, correlationId), { staleTime: 3_600_000 }),
      ),
    );
  },
});

export const productByAllocationIdState = selectorFamily({
  key: 'productByAllocationIdState',
  get:
    (allocationId: string | undefined) =>
    async ({ get }): Promise<CatalogProduct | undefined> =>
      get(allocationsToProductsState).find(({ allocationId: allocationIdFromState }) => allocationIdFromState === allocationId)?.product,
});
