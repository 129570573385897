import { useCallback, useMemo, useState } from 'react';
import type { DecantAction } from '@get-fabric/action-api-client';
import type { DecantActionProduct } from '@get-fabric/wms-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import { createTrace } from '../../../../framework/correlation';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { logger } from '../../../../shared/clients/loggingApi';
import { operatorApi } from '../../../../shared/clients/wmsApi';
import { stationState } from '../../../../shared/state';
import { useAllocation } from './useAllocation';

export const useDecantProcess = () => {
  const station = useRecoilValue(stationState);
  const [action, setAction] = useState<DecantAction>();
  const [product, setProduct] = useState<DecantActionProduct>();
  const [allocationId, setAllocationId] = useState<Allocation['id']>('');
  const allocation = useAllocation(allocationId);

  const startDecant = useCallback(async (action: DecantAction, product: DecantActionProduct, allocationId: Allocation['id']) => {
    const status = AllocationStatus.Ongoing;
    const correlationId = uuid();

    await allocationsApi.updateByAllocationId(allocationId, {
      payload: { status },
      meta: { correlationId },
    });
    setAction(action);
    setProduct(product);
    setAllocationId(allocationId);
  }, []);

  const completeDecant = useCallback(
    async (quantity: number, expiryDate: Date | null) => {
      if (!action || !allocation) {
        return;
      }

      const correlationId = uuid();
      const traced = createTrace();

      try {
        await traced(operatorApi).confirmDecant({
          payload: {
            quantity,
            actionId: action.id,
            targetHolderId: allocation.binId,
            ...(expiryDate ? { expiryDate: expiryDate.valueOf() } : {}),
          },
          meta: {
            correlationId,
          },
        });

        await traced(allocationsApi).updateByAllocationId(allocation.id, {
          payload: { quantity, status: AllocationStatus.Completed, actionId: action.id },
          meta: {
            correlationId,
          },
        });
      } catch (error) {
        logger.error('failed to complete decant process', {
          error,
          action: action.id,
          allocation: allocation.id,
          correlationId,
        });

        throw error;
      }
    },
    [action, allocation],
  );

  const cancelDecant = useCallback(async () => {
    if (!allocation) {
      return;
    }

    const status = station.mode.next?.name ? AllocationStatus.Cancelled : AllocationStatus.Approved;
    const correlationId = uuid();

    try {
      await allocationsApi.updateByAllocationId(allocation.id, {
        payload: { status },
        meta: { correlationId },
      });
    } catch (error) {
      logger.error('failed to cancel decant process', {
        error,
        allocationId: allocation.id,
        status,
        correlationId,
      });

      throw error;
    }
  }, [station, allocation]);

  const decantProcess = useMemo(() => {
    if (!action || !product || !allocation || allocation?.status !== AllocationStatus.Ongoing) {
      return null;
    }

    return {
      action,
      product,
      allocation,
    };
  }, [action, product, allocation]);

  return [
    decantProcess,
    {
      startDecant,
      completeDecant,
      cancelDecant,
    },
  ] as const;
};
