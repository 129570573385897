import type { FunctionComponent } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { UserButton } from './UserButton';
import { UserMenu } from './UserMenu';

export const User: FunctionComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const userButtonRef = useRef(null);

  const openMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <>
      <UserButton ref={userButtonRef} onClick={openMenu} />
      <UserMenu open={menuOpen} close={closeMenu} anchorEl={userButtonRef.current} />
    </>
  );
};
