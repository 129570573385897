import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { logger } from '../../../../shared/clients/loggingApi';
import { useUnfulfilledAllocations } from '../../../../shared/hooks';

export const useMarkAllocationOngoing = (allocation: Allocation, activeSession: boolean, active: boolean) => {
  const { t } = useTranslation();
  const unfulfilledAllocations = useUnfulfilledAllocations();
  const ongoingAllocationExists = useMemo(
    () => unfulfilledAllocations?.find((allocation) => allocation.status === AllocationStatus.Ongoing),
    [unfulfilledAllocations],
  );

  return useAsync(async () => {
    if (ongoingAllocationExists || allocation.status !== AllocationStatus.Approved || !activeSession || !active) {
      return;
    }

    try {
      return await allocationsApi.updateByAllocationId(allocation.id, { payload: { status: AllocationStatus.Ongoing } });
    } catch (error) {
      logger.error('During picking to tray, failed to update allocation status to ongoing', {
        error,
        allocation,
      });
      toast.error(t('pickingOut.failedMarkingAllocationAsOngoing'));
    }
  }, [allocation.id, ongoingAllocationExists, activeSession, active]);
};
