import type { FunctionComponent } from 'react';
import React from 'react';
import { ToteContainer } from '../SubstationToteBins/ToteBins.styles';
import { useModalState } from '../../hooks';
import { Bin } from '../SubstationToteBins/Bin';
import { OrderToteContentModal } from './OrderToteContentModal';

interface OrderToteBinsProps {
  toteId: string;
  enabled?: boolean;
}

export const OrderToteBins: FunctionComponent<OrderToteBinsProps> = ({ toteId, enabled = true }) => {
  const { modalOpen, close, open } = useModalState();

  return (
    <>
      <ToteContainer bins={1} data-testid={`tote-${toteId}`} onClick={open} enabled={enabled}>
        <Bin selected />
      </ToteContainer>
      <OrderToteContentModal open={modalOpen} onClose={close} toteId={toteId} />
    </>
  );
};
