import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { ExceptionType } from '../exceptionTypes';
import { ExceptionOption } from './ExceptionOption';

interface ExceptionOptionsProps {
  exceptions: ExceptionType[];
  onOptionClick: (exception: ExceptionType) => void;
}

export const ExceptionOptions: FunctionComponent<ExceptionOptionsProps> = ({ exceptions, onOptionClick }) => (
  <Flex gap={6} flexWrap={'wrap'} fullWidth>
    {exceptions.map((exception) => (
      <ExceptionOption exception={exception} onClick={onOptionClick} key={exception} />
    ))}
  </Flex>
);
