import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@get-fabric/fabric-design-system';
import { Card, CardContent, Divider } from '@mui/material';
import type { DecantAction } from '@get-fabric/action-api-client';
import { ActionDetails } from './ActionDetails';
import { ActionCompletion } from './ActionCompletion';

interface ActionOptionProps {
  action: DecantAction;
  onSelect: (actionId: string) => void;
}

const getActionCompletion = (action: DecantAction) => ({
  completed: action.results?.movedQuantity ?? 0,
  required: action.requirements.quantity ?? 0,
});

export const ActionOption: FunctionComponent<ActionOptionProps> = ({ action, onSelect }) => {
  const { t } = useTranslation();
  const line = useMemo(() => getActionCompletion(action), [action]);

  const selectAction = useCallback(() => {
    onSelect(action.id);
  }, [onSelect, action.id]);

  return (
    <Card
      onClick={selectAction}
      data-testid={`actionOption-${action.id}`}
      sx={{
        width: '290px',
        position: 'relative',
        boxSizing: 'border-box',
      }}
    >
      <CardContent>
        <ActionDetails action={action} />
        <Divider sx={{ mt: '12px', mb: '12px' }} />
        <Flex flexDirection={'column'} gap={5}>
          <ActionCompletion
            title={t('decant.actionSelectionModal.actionOption.lineCompletionTitle')}
            completed={line.completed}
            required={line.required}
          />
        </Flex>
      </CardContent>
    </Card>
  );
};
