import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import {
  BinIsEmptyException,
  DamagedItemException,
  ReportExceptionContext,
  WrongItemInBinException,
} from '../../../../shared/components/Exceptions';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';

interface MergeExceptionsStepsProps {
  onReportException: (exception: ExceptionType) => void;
  sourceBin: Bin;
  targetBin: Bin;
  actionId: string;
}

export const MergeExceptionsSteps: FunctionComponent<MergeExceptionsStepsProps> = ({
  onReportException,
  targetBin,
  sourceBin,
  actionId,
}) => {
  const { exception } = useContext(ReportExceptionContext);

  switch (exception) {
    case ExceptionType.SourceBinIsEmpty: {
      return <BinIsEmptyException bin={sourceBin} onReportException={onReportException} actionId={actionId} />;
    }
    case ExceptionType.WrongItemInTargetBin: {
      return <WrongItemInBinException bin={targetBin} actionId={actionId} onReportException={onReportException} />;
    }
    case ExceptionType.WrongItemInSourceBin: {
      return <WrongItemInBinException bin={sourceBin} actionId={actionId} onReportException={onReportException} />;
    }
    case ExceptionType.DamagedItemInSourceBin: {
      return <DamagedItemException bin={sourceBin} actionId={actionId} onReportException={onReportException} />;
    }
    default: {
      return null;
    }
  }
};
