import type { FunctionComponent } from 'react';
import React, { useContext, useMemo } from 'react';
import type { Tote } from '@get-fabric/wms-api-client';
import type { Allocation } from '@get-fabric/allocation-api-client';
import {
  BinIsEmptyException,
  ReportExceptionContext,
  DamagedItemException,
  WrongItemInBinException,
} from '../../../../shared/components/Exceptions';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';

interface PickingOutExceptionsStepsProps {
  onReportException: (exception: ExceptionType) => void;
  tote: Tote;
  allocation: Allocation;
}

export const PickingOutExceptionsSteps: FunctionComponent<PickingOutExceptionsStepsProps> = ({ onReportException, tote, allocation }) => {
  const currentBin = useMemo(() => tote.bins.find(({ id }) => id === allocation.binId), [tote, allocation.binId]);
  const { exception } = useContext(ReportExceptionContext);

  if (!currentBin) {
    return null;
  }

  switch (exception) {
    case ExceptionType.BinIsEmpty: {
      return <BinIsEmptyException bin={currentBin} actionId={allocation.actionId} onReportException={onReportException} />;
    }
    case ExceptionType.DamagedItem: {
      return <DamagedItemException bin={currentBin} actionId={allocation.actionId} onReportException={onReportException} />;
    }
    case ExceptionType.WrongItemInBin: {
      return <WrongItemInBinException bin={currentBin} actionId={allocation.actionId} onReportException={onReportException} />;
    }
    default: {
      return null;
    }
  }
};
