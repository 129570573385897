import type { FunctionComponent } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { DecantAction, DecantTask } from '@get-fabric/action-api-client';
import { TaskOption } from './TaskOption';
import { TaskSelectionModalContext } from './TaskSelectionModalContext';

interface TaskOptionsProps {
  tasks: DecantTask[];
  actions: DecantAction[];
  sku: string;
  onSelect: (taskId: string) => void;
}

export const TaskOptions: FunctionComponent<TaskOptionsProps> = ({ tasks, actions, sku, onSelect }) => {
  const { lastSelectedTaskId } = useContext(TaskSelectionModalContext);

  const sortedTasks = useMemo(
    () =>
      [...tasks].sort((task1, task2) => {
        if (task1.id === lastSelectedTaskId) {
          return -1;
        } else if (task2.id === lastSelectedTaskId) {
          return 1;
        }

        return task2.createdAt - task1.createdAt;
      }),
    [tasks, lastSelectedTaskId],
  );

  return (
    <Flex flexWrap={'wrap'} gap={4}>
      {sortedTasks.map((task) => {
        const taskActions = actions.filter(({ task: { id: taskId } }) => taskId === task.id);

        return (
          <TaskOption
            key={task.id}
            task={task}
            actions={taskActions}
            sku={sku}
            previouslySelected={lastSelectedTaskId === task.id}
            onSelect={onSelect}
          />
        );
      })}
    </Flex>
  );
};
