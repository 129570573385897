import { selector, selectorFamily } from 'recoil';
import type { Tote as StockTote } from '@get-fabric/wms-api-client';
import type { Tote as StationTote } from '@get-fabric/station-api-client';
import { v4 as uuid } from 'uuid';
import { compact } from 'lodash';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { toteApi } from '../clients/wmsApi';
import { logger } from '../clients/loggingApi';
import { queryClient } from '../../App/App';
import { stationState, substationState } from './station';
import { allocationsState } from './allocations';

export interface Tote extends StockTote, Omit<StationTote, 'id'> {}

const getStockTote = async (toteId: string, mfcId: string, correlationId: string): Promise<StockTote> => {
  if (!toteId) {
    throw new Error(`no tote ${toteId}`);
  }

  try {
    return await toteApi.getTote(toteId, mfcId, correlationId);
  } catch (error) {
    logger.error('failed to fetch tote', {
      error,
      toteId,
      correlationId,
    });
    throw error;
  }
};

const getTote = (stationTote: StationTote, stockTote: StockTote): Tote => ({ ...stationTote, ...stockTote });

export const stockTotesState = selector({
  key: 'stockTotesState',

  async get({ get }) {
    const correlationId = uuid();

    const allocations = get(allocationsState).filter((allocation) => !!allocation.toteId);
    const stationToteIds = new Set(
      Object.entries(get(stationState).substations ?? {})
        .map(([, substation]) => substation.totes?.current?.id)
        .filter((toteId): toteId is string => !!toteId),
    );

    const { mfcId } = get(stationState);

    const [toteIdsToInvalidate, toteIdsToFetch] = allocations.reduce(
      (partitionedAllocations, { toteId, status }) => {
        const allocationStatusesToFetch = new Set([AllocationStatus.Approved, AllocationStatus.Ongoing, AllocationStatus.PendingCancel]);
        const allocationStatusesToInvalidate = new Set([AllocationStatus.Completed, AllocationStatus.Cancelled, AllocationStatus.Rejected]);

        if (!toteId) {
          return partitionedAllocations;
        }
        if (allocationStatusesToInvalidate.has(status) && !stationToteIds.has(toteId)) {
          partitionedAllocations[0].push(toteId);
        }

        if (allocationStatusesToFetch.has(status)) {
          partitionedAllocations[1].push(toteId);
        }

        return partitionedAllocations;
      },
      [[] as string[], [...stationToteIds] as string[]],
    );

    await Promise.allSettled(toteIdsToInvalidate.map((toteId) => queryClient.invalidateQueries(['totes', toteId])));

    return Promise.all(
      toteIdsToFetch.map((toteId) =>
        queryClient.fetchQuery(['totes', toteId], () => getStockTote(toteId, mfcId, correlationId), { staleTime: 3_600_000 }),
      ),
    );
  },
});

export const currentToteIdsState = selector({
  key: 'currentToteIdsState',

  async get({ get }) {
    return compact(Object.entries(get(stationState).substations).map(([, substation]) => substation.totes?.current?.id));
  },
});

export const totesState = selector({
  key: 'totesState',

  async get({ get }) {
    const totes = Object.entries(get(stationState).substations ?? {})
      .map(([, substation]) => substation.totes?.current)
      .filter((tote): tote is Tote => !!tote);

    const stockTotes = get(stockTotesState);

    return totes.map((tote) => getTote(tote, stockTotes.find(({ id }) => id === tote.id)!));
  },
});

export const toteByPositionState = selectorFamily({
  key: 'toteByPositionState',
  get:
    (position: string) =>
    async ({ get }): Promise<Tote | undefined> => {
      const currentToteId = get(substationState(position)).totes?.current?.id;

      if (!currentToteId) {
        return;
      }

      const totes = get(totesState);

      return totes.find((tote) => tote.id === currentToteId);
    },
});
