import { atom, selector, selectorFamily } from 'recoil';
import type { Station, Substation } from '@get-fabric/station-api-types';

export const stationAtom = atom<Station | undefined>({
  key: 'station',
  default: undefined,
});

export const stationState = selector<Station>({
  key: 'stationState',
  get: ({ get }) => {
    const station = get(stationAtom);

    if (!station) {
      throw new Error('no station');
    }

    return station;
  },
});

export const mfcIdState = selector<string>({
  key: 'mfcId',
  get: ({ get }) => get(stationState).mfcId,
});

export const stationIdState = selector({
  key: 'stationId',
  get: ({ get }) => get(stationState).id,
});

export const stationNameState = selector({
  key: 'stationName',
  get: ({ get }) => get(stationState).name,
});

export const substationsState = selector({
  key: 'substations',
  get: ({ get }) => get(stationState).substations,
});

export const substationsWithSafetyErrorState = selector({
  key: 'substationsWithSafetyError',
  get: ({ get }) => {
    const substations = get(substationsState);
    return Object.entries(substations)
      .filter(([, substationValue]) => substationValue.safetyError)
      .map(([substationPosition]) => substationPosition);
  },
});

export const currentModeState = selector({
  key: 'currentModeState',
  get: ({ get }) => get(stationState).mode.current,
});

export const nextModeState = selector({
  key: 'nextModeState',
  get: ({ get }) => get(stationState).mode.next,
});

export const substationState = selectorFamily({
  key: 'substation',
  get:
    (position: string) =>
    ({ get }): Substation =>
      get(substationsState)[position],
});

export const substationOverflowState = selectorFamily({
  key: 'substation',
  get:
    (position: string) =>
    ({ get }): boolean =>
      get(substationState(position)).overflow ?? false,
});
