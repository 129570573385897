import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import type { Tote } from '@get-fabric/wms-api-client';
import { useRecoilValue } from 'recoil';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { useActiveSession, useButtonPress } from '../../../../shared/hooks';
import { logger } from '../../../../shared/clients/loggingApi';
import { useToteAllocations } from '../../Decant/hooks/useTotesAllocations';
import { substationOverflowState } from '../../../../shared/state';

interface ReleaseToteProps {
  tote: Tote;
  substationPosition: string;
}

export const CancelTask: FunctionComponent<ReleaseToteProps> = ({ tote, substationPosition }) => {
  const { t } = useTranslation();
  const allocations = useToteAllocations([tote]);
  const overflow = useRecoilValue(substationOverflowState(substationPosition));
  const activeSession = useActiveSession();

  const onButtonPress = useCallback(async () => {
    if (!activeSession) {
      toast.error(t('qc.notActiveSession'));

      return;
    }

    if (overflow) {
      toast.error(t('qc.overflowToastError'));

      return;
    }

    try {
      const correlationId = uuid();

      await Promise.all(
        allocations
          .filter(({ status }) => status !== AllocationStatus.Completed)
          .map(({ id: allocationId }) =>
            allocationsApi.updateByAllocationId(allocationId, {
              payload: { status: AllocationStatus.Cancelled },
              meta: { correlationId },
            }),
          ),
      );
    } catch (error) {
      logger.error('failed to release tote and cancel allocations', {
        error,
        allocations,
        substationPosition,
      });
      toast.error(t('qc.releaseToteError'));
    }
  }, [allocations, overflow, t, substationPosition, activeSession]);

  useButtonPress(onButtonPress);

  return (
    <Flex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={25}>
      {overflow ? (
        <>
          <Text weight={300} size={64} textAlign={'center'}>
            {t('qc.overflowTitle', { substationPosition: 'right' })}
          </Text>
          <Text weight={300} size={34} textAlign={'center'}>
            {t('qc.overflowInstruction')}
          </Text>
        </>
      ) : (
        <>
          <Text size={40} textAlign={'center'}>
            {t('qc.cancelTask')}
          </Text>
          <Text weight={300} size={28}>
            {t('qc.pressButtonInstruction')}
          </Text>
        </>
      )}
    </Flex>
  );
};
