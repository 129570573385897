import React, { useContext } from 'react';
import { SvgIcon } from '@mui/material';
import { ThemeContext } from '@get-fabric/fabric-design-system';

export const HandScannerIcon = (props) => {
  const theme = useContext(ThemeContext);

  return (
    <SvgIcon fontSize={'small'} sx={{ color: theme?.colors?.gray[700] }} {...props}>
      <path
        d={
          'm5,0c-2.8,0 -5,2.2 -5,5c0,2.7 2.1,4.8 4.7,5l-2.7,10.1c-0.3,1.2 -0.1,2.4 0.7,3.4c0.5,0.7 1.3,1.2 2.1,1.4c2.1,0.6 4.2,-0.7 4.8,-2.8l0.8,-3.1l1.6,0c1.7,0 3,-1.3 3,-3s-1.3,-3 -3,-3l0.8,-3l7.2,0c0.4,0 0.8,-0.3 0.9,-0.7l3,-8c0.1,-0.1 0.1,-0.2 0.1,-0.3c0,-0.6 -0.5,-1 -1,-1l-18,0z'
        }
        fill={'none'}
      />
      <path
        d={
          'm5,0c-2.8,0 -5,2.10485 -5,4.78376c0,2.58323 2.1,4.59241 4.7,4.78376l-2.7,9.66319c-0.3,1.1481 -0.1,2.2962 0.7,3.25295c0.5,0.66973 1.3,1.1481 2.1,1.33945c2.1,0.57405 4.2,-0.66973 4.8,-2.6789l0.8,-2.96593l1.6,0c1.7,0 3,-1.24378 3,-2.87025s-1.3,-2.87025 -3,-2.87025l0.8,-2.87025l7.2,0c0.4,0 0.8,-0.28703 0.9,-0.66973l3,-7.65401c0.1,-0.09568 0.1,-0.19135 0.1,-0.28703c0,-0.57405 -0.5,-0.95675 -1,-0.95675l-18,0zm5.9,16.26477l0.5,-1.9135l0.6,0c0.6,0 1,0.3827 1,0.95675s-0.4,0.95675 -1,0.95675l-1.1,0zm-4.1,-6.69726l3.9,0l-3.1,11.09832c-0.2,0.7654 -1,1.33945 -1.8,1.33945c-0.6,0 -1.1,-0.28703 -1.5,-0.66973c-0.4,-0.47838 -0.5,-1.05243 -0.3,-1.62648l2.8,-10.14156zm14.8,-7.65401l-2.2,5.74051l-14.4,0c-1.3,0 -2.4,-0.7654 -2.8,-1.9135l4.8,0c0.6,0 1,-0.3827 1,-0.95675s-0.4,-0.95675 -1,-0.95675l-4.8,0c0.4,-1.1481 1.5,-1.9135 2.8,-1.9135l16.6,0z'
        }
      />
    </SvgIcon>
  );
};
