import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import { Button, Flex, ModalBody, ModalFooter } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { QuantitySelection } from '../../QuantitySelection';
import { ReportExceptionContext } from '../ExceptionsModal';

interface SelectDamagedItemProps {
  quantity: number;
  changeQuantity: (inputQuantity: number) => void;
  submitQuantity: () => void;
  disableConfirm: boolean;
}

export const SelectDamagedItem: FunctionComponent<SelectDamagedItemProps> = ({
  quantity,
  changeQuantity,
  submitQuantity,
  disableConfirm,
}) => {
  const { resetExceptionState } = useContext(ReportExceptionContext);
  const { t } = useTranslation();

  return (
    <>
      <ModalBody>
        <QuantitySelection text={t('exceptions.damagedItem.text')} changeQuantity={changeQuantity} quantity={quantity} />
      </ModalBody>
      <ModalFooter>
        <Flex gap={8}>
          <Button size={'s'} variant={'secondary'} onClick={resetExceptionState}>
            {t('actions.cancel')}
          </Button>
          <Button disabled={disableConfirm} onClick={submitQuantity} size={'s'}>
            {t('actions.confirm')}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
