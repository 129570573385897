import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useSessionStorage } from 'react-use';
import { useScan } from '../../../hooks';
import type { ScanRecord } from './scan';

const maxRecords = 30;

export const scanHistorySessionStorageKey = 'scanHistory';

export const useScanHistory = () => {
  const [scans, setScans] = useSessionStorage<ScanRecord[]>(scanHistorySessionStorageKey, []);

  const onScan = useCallback(
    ({ barcode }: { barcode: string }) => {
      const scanRecord = {
        id: uuid(),
        barcode,
        date: new Date(),
      };

      const updatedScans = [scanRecord, ...scans];

      if (updatedScans.length === maxRecords) {
        updatedScans.pop();
      }

      setScans(updatedScans);
    },
    [scans, setScans],
  );

  useScan(onScan);

  return useMemo(() => scans.map((scan) => ({ ...scan, date: new Date(scan.date) })), [scans]);
};
