import type { FunctionComponent } from 'react';
import React from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import { ExceptionsModal } from '../../../../shared/components/Exceptions';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { ExtractStockExceptionsSteps } from './ExtractStockExceptionStep';

interface ExtractStockExceptionsModalProps {
  open: boolean;
  close: () => void;
  onReportException: (exception: ExceptionType) => void;
  bin: Bin;
  actionId: string;
}

export const ExtractStockExceptionsModal: FunctionComponent<ExtractStockExceptionsModalProps> = ({
  open,
  close,
  bin,
  actionId,
  onReportException,
}) => (
  <ExceptionsModal open={open} close={close} exceptions={[ExceptionType.BinIsEmpty, ExceptionType.WrongItemInBin]}>
    <ExtractStockExceptionsSteps bin={bin} onReportException={onReportException} actionId={actionId} />
  </ExceptionsModal>
);
