import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useKey } from 'react-use';
import type { ScanRecord } from './scan';
import { ScanAccordion } from './ScanAccordion';
import { useScanHistory } from './useScanHistory';

interface ScanHistoryProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedScanRecordId: string;
  onScanSelect: (scanRecord: ScanRecord) => void;
}

export const ScanHistory: FunctionComponent<ScanHistoryProps> = ({ open, onOpen, onClose, selectedScanRecordId, onScanSelect }) => {
  const scans = useScanHistory();
  const currentIndex = useMemo(() => scans.findIndex((scan) => scan.id === selectedScanRecordId) ?? 0, [scans, selectedScanRecordId]);

  const prevRecord = useCallback(() => {
    const prevIndex = Math.min(currentIndex + 1, scans.length - 1);

    onScanSelect(scans[prevIndex]);
  }, [scans, currentIndex, onScanSelect]);

  const nextRecord = useCallback(() => {
    const nextIndex = Math.max(currentIndex - 1, 0);

    onScanSelect(scans[nextIndex]);
  }, [scans, currentIndex, onScanSelect]);

  useKey(
    'ArrowDown',
    (e) => {
      e.preventDefault();

      if (!open) {
        onOpen();
      }

      if (!scans.length) {
        return;
      }

      prevRecord();
    },
    {},
    [open, onOpen, prevRecord, scans],
  );

  useKey(
    'ArrowUp',
    (e) => {
      e.preventDefault();

      if (!open) {
        return;
      }

      if (!scans.length || !currentIndex || currentIndex === -1) {
        onClose();

        return;
      }

      nextRecord();
    },
    {},
    [open, onClose, nextRecord, scans, currentIndex],
  );

  return (
    <ScanAccordion
      scans={scans}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      selectedScanRecordId={selectedScanRecordId}
      onScanSelect={onScanSelect}
    />
  );
};
