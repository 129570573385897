import React from 'react';
import type { FunctionComponent } from 'react';
import type { Allocation } from '@get-fabric/allocation-api-client';

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const AllocationContext = React.createContext<Allocation>(undefined as any);

export const AllocationProvider: FunctionComponent<{ allocation: Allocation }> = ({ allocation, children }) => (
  <AllocationContext.Provider value={allocation}>{children}</AllocationContext.Provider>
);
