import type { Allocation } from '@get-fabric/allocation-api-client';
import type { FunctionComponent } from 'react';
import React from 'react';
import type { Tote } from '../../../../shared/state';
import { ToteNotAvailable } from '../../../../shared/components/ToteNotAvailable';
import { SubstationToteBins } from '../../../../shared/components/SubstationToteBins';

interface TargetToteProps {
  tote?: Tote;
  allocation: Allocation;
  position: 'left' | 'right';
}

export const TargetTote: FunctionComponent<TargetToteProps> = ({ tote, allocation, position }) => {
  if (!tote || tote.id !== allocation.toteId) {
    return <ToteNotAvailable />;
  }

  return (
    <SubstationToteBins
      toteId={tote.id}
      bins={tote.bins}
      selectedBin={allocation.binId}
      orientation={tote.orientation}
      position={position}
    />
  );
};
