import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect } from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { DecantActionProduct } from '@get-fabric/wms-api-client';
import { useProductFormProperties } from './useProductFormProperties';
import type { ProductFormProperties } from './useProductFormProperties';
import { MaxItemsPerBinField } from './MaxItemsPerBinField';
import { ToteTypeSelection } from './ToteTypeSelection';
import { ScannableSelection } from './ScannableSelection';
import { otherToteType } from './useToteTypes';

interface ProductPropertiesFormProps {
  product: DecantActionProduct;
  onChange: (properties: ProductFormProperties) => void;
}

export const ProductPropertiesForm: FunctionComponent<ProductPropertiesFormProps> = ({ product, onChange }) => {
  const [properties, setProperty] = useProductFormProperties(product);

  const selectToteType = useCallback(
    (toteType: ProductFormProperties['toteType']) => {
      setProperty('toteType', toteType);
    },
    [setProperty],
  );

  const selectMaxItemPerBin = useCallback(
    (amount: ProductFormProperties['maxItemsPerBin']) => {
      setProperty('maxItemsPerBin', amount);
    },
    [setProperty],
  );

  const selectScannable = useCallback(
    (scannable: ProductFormProperties['scannable']) => {
      setProperty('scannable', scannable);
    },
    [setProperty],
  );

  useEffect(() => {
    onChange(properties);
  }, [properties, onChange]);

  return (
    <Flex flexDirection={'column'} gap={6} pb={4}>
      <ToteTypeSelection value={properties.toteType} onChange={selectToteType} />
      <MaxItemsPerBinField
        value={properties.maxItemsPerBin}
        onChange={selectMaxItemPerBin}
        disabled={properties.toteType?.name === otherToteType.name}
      />
      <ScannableSelection
        value={properties.scannable}
        onChange={selectScannable}
        disabled={properties.toteType?.name === otherToteType.name}
      />
    </Flex>
  );
};
