import { useMemo } from 'react';
import { useOrderTotesInPosition, useUnfulfilledAllocations } from '../../../../shared/hooks';

export const useOrderDoneInPosition = (position: 'left' | 'right') => {
  const orderTotesInPosition = useOrderTotesInPosition(position);
  const unfulfilledAllocations = useUnfulfilledAllocations();

  return useMemo(
    () =>
      orderTotesInPosition.find(({ orderTote, previousTote }) => {
        const totesRequestId = orderTote?.totesRequestId || (!orderTote?.id && previousTote?.totesRequestId);

        return totesRequestId && unfulfilledAllocations.every((allocation) => allocation.totesRequestId !== totesRequestId);
      }),
    [orderTotesInPosition, unfulfilledAllocations],
  );
};
