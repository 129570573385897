import { merge, isNil } from 'lodash';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import type { Mode } from '@get-fabric/station-api-types';
import type { DecantAction } from '@get-fabric/action-api-client';
import type { DecantActionProduct, PartialDecantPackaging } from '@get-fabric/wms-api-client';
import type { Tote } from '../../../../shared/state';

export const getProductPackaging = (product: DecantActionProduct): PartialDecantPackaging =>
  merge(product.defaultPackaging, product.sitePackaging);

export const productHasMissingCriticalProperties = ({ storageZone, temperatureZone }: DecantActionProduct) =>
  !storageZone || !temperatureZone;

export const filterTotesForProduct = (totes: Tote[], { sitePackaging, defaultPackaging }: DecantActionProduct) => {
  const { toteHeight, partitions } = sitePackaging ?? defaultPackaging ?? {};

  return totes.filter(
    ({ totesRequestId, bins, height }) =>
      totesRequestId && (!toteHeight || toteHeight === height) && (!partitions || partitions === bins.length),
  );
};

export const isProductSuitableToMode = (product: DecantActionProduct, mode: Mode) => {
  const { toteHeight, partitions } = getProductPackaging(product);
  const { left: leftStationOptions, right: rightStationOptions } = mode.options ?? {};

  return (
    (partitions === leftStationOptions?.partitions && toteHeight === leftStationOptions?.height) ||
    (partitions === rightStationOptions?.partitions && toteHeight === rightStationOptions?.height)
  );
};

export const productHasMissingProperties = ({ scannable, sitePackaging }: DecantActionProduct) => {
  const { toteHeight, partitions, maxItemsPerBin } = sitePackaging ?? {};

  return [toteHeight, partitions, maxItemsPerBin, scannable].some(isNil);
};

const unfulfilledAllocationStatuses = new Set([AllocationStatus.Created, AllocationStatus.Approved, AllocationStatus.Ongoing]);

export const filterAllocationsForTotes = (allocations: Allocation[], totes: Tote[]) => {
  const toteIds = new Set(totes.map(({ id }) => id));

  return allocations.filter(({ toteId, status }) => toteId && toteIds.has(toteId) && unfulfilledAllocationStatuses.has(status));
};

export const findAllocationForProduct = (totes: Tote[], allocations: Allocation[], product: DecantActionProduct) => {
  const validTotes = filterTotesForProduct(totes, product);
  const [allocation] = filterAllocationsForTotes(allocations, validTotes);

  return allocation;
};

export const getDefaultQuantityToDecant = (action: DecantAction, product: DecantActionProduct) => {
  const remainingQuantity = action.requirements.quantity - (action.results?.movedQuantity ?? 0);
  const { maxItemsPerBin } = getProductPackaging(product);

  return maxItemsPerBin && remainingQuantity > 0 ? Math.min(maxItemsPerBin, remainingQuantity) : maxItemsPerBin ?? 1;
};

const toTimelessDate = (date: Date | number | string) => new Date(date).setUTCHours(0, 0, 0, 0);

export const validateExpirationDate = (expirationDate: Date, { minExpiryDate }: DecantActionProduct) =>
  !!minExpiryDate && toTimelessDate(expirationDate) > toTimelessDate(+minExpiryDate);
