import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import DesktopAccessDisabled from '@mui/icons-material/DesktopAccessDisabled';
import DesktopWindows from '@mui/icons-material/DesktopWindows';

import styled from 'styled-components';
import { useActiveSession } from '../../../hooks';

interface ActiveSessionButtonProps {
  onClick: () => void;
}

const StyledDesktopAccessDisabled = styled(DesktopAccessDisabled).attrs({ sx: { color: '#DA1212' } })``;

export const ActiveSessionButton: FunctionComponent<ActiveSessionButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const { activeSession } = useActiveSession();

  return (
    <Tooltip title={t('activeSession.tooltip') ?? ''}>
      <span>
        <IconButton disabled={activeSession} onClick={onClick} aria-label={'activeSessionButton'}>
          {activeSession ? <DesktopWindows /> : <StyledDesktopAccessDisabled />}
        </IconButton>
      </span>
    </Tooltip>
  );
};
