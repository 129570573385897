import React from 'react';
import type { FunctionComponent } from 'react';
import { Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { ColoredTrayFlex } from './ColoredTrayFlex';

interface OrderDoneProps {
  position: string;
}

export const OrderDone: FunctionComponent<OrderDoneProps> = ({ position }) => {
  const { t } = useTranslation();

  return (
    <ColoredTrayFlex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={4} trayPosition={position}>
      <Text weight={300} size={64} textAlign={'center'}>
        {t('pickToTray.orderDone')}
      </Text>
      <Text weight={300} size={46} textAlign={'center'}>
        {t('pickToTray.replaceTote')}
      </Text>
    </ColoredTrayFlex>
  );
};
