import { Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React, { useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import type { Bin } from '@get-fabric/wms-api-client';
import type { QcAction } from '@get-fabric/action-api-client';
import { toast } from 'react-toastify';
import { useModalState, useDamagedItemsLocation } from '../../../../shared/hooks';
import { useRecoilAsync, mfcIdState, stationIdState, stationNameState, toteByPositionState } from '../../../../shared/state';
import { ActionContext } from '../providers/ActionProvider';
import { exceptionApi } from '../../../../shared/clients/wmsApi';
import { Loading } from '../../../../shared/components/Loading';
import { logger } from '../../../../shared/clients/loggingApi';
import { QuantitySelectionModal } from './QuantitySelectionModal';
import { YesNoQuestion } from './YesNoQuestion';

interface DamagedItemsProps {
  binId: string;
  onComplete: () => void;
  onException: () => void;
}

const reportDamagedItems = (bin: Bin, quantity: number, stationId: string, action: QcAction, destinationHolder: string) =>
  exceptionApi.damagedItem({
    payload: {
      exception: {
        sku: bin.content[0].product.sku,
        binId: bin.id,
        confirmedQuantity: quantity,
        destinationHolder,
        companyCode: bin.content[0].product.companyCode,
      },
      stationId,
      mfcId: action.mfcId,
      actionId: action.id,
    },
  });

export const DamagedItems: FunctionComponent<DamagedItemsProps> = ({ binId, onComplete, onException }) => {
  const { t } = useTranslation();
  const { open, modalOpen, close } = useModalState();
  const mfcId = useRecoilValue(mfcIdState);
  const stationId = useRecoilValue(stationIdState);
  const stationName = useRecoilValue(stationNameState);
  const action = useContext(ActionContext);
  const { data: tote } = useRecoilAsync(toteByPositionState('right'));
  const bin = useMemo(() => tote?.bins.find((b) => b.id === binId), [tote, binId]);

  const { loading: damagedTrolleyLocationRunning, destination: damagedTrolleyLocation } = useDamagedItemsLocation(mfcId, stationId);

  const onConfirm = useCallback(
    async (quantity: number) => {
      if (!bin || !damagedTrolleyLocation) {
        return;
      }
      if (quantity > 0) {
        try {
          await reportDamagedItems(bin, quantity, stationId, action, damagedTrolleyLocation);
          onException();
        } catch (error) {
          logger.error('failed to report damaged items', {
            error,
            quantity,
            action,
          });
          toast.error(t('Failed updating damaged items quantity. Please try again'));
        }

        return;
      }

      onComplete();
    },
    [bin, stationId, action, onException, onComplete, t, damagedTrolleyLocation],
  );

  if (!bin) {
    return <Loading />;
  }

  return (
    <>
      <Flex flexDirection={'column'} alignItems={'center'} gap={8}>
        <YesNoQuestion question={t('qc.damagedItemsQuestion')} onYes={open} onNo={onComplete} />
        {damagedTrolleyLocationRunning ? <Spinner /> : <Text size={22}>{t('qc.damagedTrolleyInstruction', { stationName })}</Text>}
      </Flex>
      <QuantitySelectionModal open={modalOpen} text={t('qc.damagedItemsAmount')} onRequestClose={close} onConfirm={onConfirm} />
    </>
  );
};
