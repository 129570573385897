import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { SuggestionDatePicker } from '../../DatePickers';

interface EnterExpiryDateStepProps {
  onClickConfirm: (dateValue: Date) => void;
  onClickCancel: () => void;
  onClickConfirmDateMissing?: () => void;
}
export const EnterExpiryDateStep: FunctionComponent<EnterExpiryDateStepProps> = ({
  onClickConfirm,
  onClickConfirmDateMissing,
  onClickCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection={'column'} gap={5}>
      <Text size={22}>{t('exceptions.addNewStock.enterExpiryDate')}</Text>
      <SuggestionDatePicker
        futureYearsOffset={4}
        pastYearsOffset={1}
        onClickConfirm={onClickConfirm}
        onClickConfirmDateMissing={onClickConfirmDateMissing}
        onClickCancel={onClickCancel}
        enableDateMissing
      />
    </Flex>
  );
};
