import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { ModalHeader, ModalBody, Modal, Flex, Button } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { QuantitySelection } from '../../../../shared/components/QuantitySelection';

interface QuantitySelectionModalProps {
  open: boolean;
  text: string;
  onConfirm: (quantity: number) => Promise<void> | void;
  onRequestClose: () => void;
}

export const QuantitySelectionModal: FunctionComponent<QuantitySelectionModalProps> = ({ text, onConfirm, onRequestClose, open }) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState<number>(0);

  const [{ loading: confirmingQuantity }, confirmQuantity] = useAsyncFn(async () => onConfirm(quantity), [onConfirm, quantity]);

  return (
    <Modal open={open} size={'l'} onRequestClose={onRequestClose} data-testid={'quantitySelectionModal'}>
      <ModalHeader />
      <ModalBody>
        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={6} fullWidth>
          <QuantitySelection text={text} changeQuantity={setQuantity} quantity={quantity} />
          <Button disabled={quantity === 0} loading={confirmingQuantity} size={'s'} onClick={confirmQuantity}>
            {t('actions.confirm')}
          </Button>
        </Flex>
      </ModalBody>
    </Modal>
  );
};
