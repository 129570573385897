import { useCallback, useMemo, useState } from 'react';

export const useModalState = (initialState = false) => {
  const [modalOpen, setModalOpen] = useState(initialState);

  const open = useCallback(() => {
    setModalOpen(true);
  }, []);
  const close = useCallback(() => {
    setModalOpen(false);
  }, []);

  return useMemo(
    () => ({
      modalOpen,
      open,
      close,
    }),
    [close, modalOpen, open],
  );
};
