import { useEffect, useState } from 'react';
import type { RecoilValue } from 'recoil';
import { useRecoilValueLoadable } from 'recoil';

export const useRecoilAsync = <T>(recoilState: RecoilValue<T>, defaultValue?: T) => {
  const { contents, state } = useRecoilValueLoadable<T>(recoilState);
  const [internalState, setInternalState] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    if (state === 'hasValue') {
      setInternalState(contents);

      return;
    }
    if (state === 'hasError') {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setInternalState(undefined);

      return;
    }
  }, [state, contents]);

  return { data: internalState, error: state === 'hasError' ? contents : null, loading: state === 'loading' };
};
