import type { FunctionComponent } from 'react';
import type { DecantActionProduct } from '@get-fabric/wms-api-client';
import React, { useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { Flex, Modal, ModalHeader, ModalBody, Button, ModalFooter } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { mfcIdState } from '../../../../../shared/state';
import { logger } from '../../../../../shared/clients/loggingApi';
import { updateProductProperties } from './productProperties';
import type { ProductFormProperties } from './ProductPropertiesForm';
import { ProductPropertiesForm, otherToteType } from './ProductPropertiesForm';

interface ProductPropertiesModalProps {
  open: boolean;
  onUpdate: () => void;
  product: DecantActionProduct | undefined;
  close: () => void;
}

export const ProductPropertiesModal: FunctionComponent<ProductPropertiesModalProps> = ({ open, close, onUpdate, product }) => {
  const { t } = useTranslation();
  const mfcId = useRecoilValue(mfcIdState);
  const [formProperties, setFormProperties] = useState<ProductFormProperties>({});

  const disableConfirm = useMemo(() => {
    if (formProperties.toteType?.name === otherToteType.name) {
      return false;
    }

    return Object.values(formProperties).some((property) => isNil(property)) || formProperties.maxItemsPerBin === 0;
  }, [formProperties]);

  const [{ loading: updatingProperties }, updateProperties] = useAsyncFn(async () => {
    if (!product) {
      return;
    }

    const { scannable, maxItemsPerBin, toteType } = formProperties;

    if (toteType?.name === otherToteType.name) {
      toast.warn(t('decant.productPropertiesModal.errors.productDoesntMatchStationOptions'));
      close();

      return;
    }

    const productProperties = {
      scannable,
      maxItemsPerBin,
      toteHeight: toteType?.toteHeight,
      partitions: toteType?.partitions,
    };

    try {
      await updateProductProperties(mfcId, product, productProperties);
      onUpdate();
    } catch (error) {
      logger.error('failed to update product properties', { error, product, productProperties });
      toast.error(t('decant.productPropertiesModal.errors.failedToUpdateProductProperties'));

      return;
    }
  }, [mfcId, product, formProperties, onUpdate, close, t]);

  return (
    <Modal open={open} onRequestClose={close} size={'m'} closeOnOverlayClick={!updatingProperties} data-testid={'productPropertiesModal'}>
      <ModalHeader>{t('decant.productPropertiesModal.title')}</ModalHeader>
      <ModalBody>{product && <ProductPropertiesForm product={product} onChange={setFormProperties} />}</ModalBody>
      <ModalFooter>
        <Flex gap={8}>
          <Button size={'s'} onClick={close} variant={'secondary'} disabled={updatingProperties}>
            {t('actions.cancel')}
          </Button>
          <Button size={'s'} onClick={updateProperties} disabled={disableConfirm || updatingProperties} loading={updatingProperties}>
            {t('actions.confirm')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
