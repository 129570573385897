import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import type { Bin, QcCheckResult } from '@get-fabric/wms-api-client';
import { useBinIsNotEmptySteps } from '../hooks/useBinIsNotEmptySteps';
import { ActionContext } from '../providers/ActionProvider';
import { VerifyProduct } from './VerifyProduct';
import { DamagedItems } from './DamagedItems';
import { CheckQuantity } from './CheckQuantity';
import { CheckExpirationDate } from './CheckExpirationDate';

interface BinIsNotEmptyStepsProps {
  bin: Bin;
  onComplete: (result: QcCheckResult) => Promise<void>;
}

export const BinIsNotEmptySteps: FunctionComponent<BinIsNotEmptyStepsProps> = ({ bin, onComplete }) => {
  const {
    task: { checkDamaged, checkQuantity, checkExpirationDate },
  } = useContext(ActionContext);
  const steps = useBinIsNotEmptySteps(onComplete, {
    initialStepsState: {
      damagedItems: checkDamaged,
      checkQuantity,
      checkExpirationDate: checkExpirationDate && (bin?.content?.[0].product.expiryDate ?? 0) > 0,
    },
  });

  if (!steps.verifyProductCompleted) {
    return <VerifyProduct bin={bin} onComplete={steps.completeVerifyProduct} onException={steps.exceptionVerifyProduct} />;
  }

  if (!steps.damagedItemsCompleted) {
    return <DamagedItems binId={bin.id} onComplete={steps.completedDamagedItems} onException={steps.exceptionDamagedItems} />;
  }

  if (!steps.checkQuantityCompleted) {
    return <CheckQuantity binId={bin.id} onComplete={steps.completeCheckQuantity} onException={steps.exceptionCheckQuantity} />;
  }

  if (!steps.checkExpiryDateCompleted) {
    return <CheckExpirationDate binId={bin.id} onComplete={steps.completeCheckExpiryDate} onException={steps.exceptionCheckExpiryDate} />;
  }

  return null;
};
