import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import type { Allocation } from '@get-fabric/allocation-api-client';
import type { CatalogProduct } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import type { Tote } from '../../../../shared/state';
import { ProductCard } from '../../../../shared/components/ProductCard';
import { SubstationToteBins } from '../../../../shared/components/SubstationToteBins';

interface PickProductInstructionsProps {
  allocation: Allocation;
  position: 'left' | 'right';
  inventoryTote: Tote;
  product: CatalogProduct;
}

export const PickProductInstructions: FunctionComponent<PickProductInstructionsProps> = ({
  allocation,
  position,
  inventoryTote,
  product,
}) => {
  const { t } = useTranslation();

  const remainingQuantity = useMemo(
    () => allocation.requiredQuantity! - allocation.quantity,
    [allocation.requiredQuantity, allocation.quantity],
  );

  return (
    <Flex flexGrow={1} justifyContent={'center'} flexDirection={'column'} gap={45}>
      <Flex alignItems={'center'} justifyContent={'space-evenly'} gap={15} fullWidth>
        <ProductCard product={product} />
        <Flex gap={5}>
          <Text size={40}>{t('pickingOut.pickingQuantity.pickAllocation', { count: remainingQuantity })}</Text>
        </Flex>
      </Flex>
      <Flex gap={15} justifyContent={'space-evenly'}>
        <SubstationToteBins
          toteId={inventoryTote.id}
          bins={inventoryTote.bins}
          orientation={inventoryTote.orientation}
          position={position}
          selectedBin={allocation.binId}
        />
      </Flex>
    </Flex>
  );
};
