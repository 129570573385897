import type { FunctionComponent } from 'react';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import { useAsync } from 'react-use';
import type { Bin } from '@get-fabric/wms-api-client';
import { useTranslation } from 'react-i18next';
import { toteApi } from '../../../clients/wmsApi';
import { mfcIdState } from '../../../state';
import { ToteContainer } from '../ToteBins.styles';
import { useSortedBins } from '../hooks/useSortedBins';
import { logger } from '../../../clients/loggingApi';
import { BinContent } from './BinContent';
import { BinContentInfo } from './BinContentInfo';
import { StyledBinContentInfo } from './BinContentInfo.styles';

interface ToteContentProps {
  toteId: string;
  reverse: boolean;
}

interface SimplifiedBinInfo {
  id: string;
  quantity?: number;
  name?: string;
  sku?: string;
  barcodes?: string[];
  imageUrl?: string;
  index: number;
  bestBeforeDate?: number;
  expiryDate?: number;
}

const extractBinData = ({ id, index, content: [firstContent] }: Bin): SimplifiedBinInfo => ({
  id,
  index,
  ...(firstContent && {
    quantity: firstContent.quantity,
    name: firstContent.product.name,
    sku: firstContent.product.sku,
    barcodes: firstContent.product.barcodes,
    imageUrl: firstContent.product.imageUrl,
    bestBeforeDate: firstContent.product.bestBeforeDate,
    expiryDate: firstContent.product.expiryDate,
  }),
});

const extractBinsData = (bins: Bin[]) => bins.map(extractBinData);

export const ToteContent: FunctionComponent<ToteContentProps> = ({ toteId, reverse }) => {
  const mfcId = useRecoilValue(mfcIdState);
  const { value: tote, loading, error } = useAsync(() => toteApi.getTote(toteId, mfcId), [toteId, mfcId]);
  const bins = useMemo<SimplifiedBinInfo[]>(() => extractBinsData(tote?.bins ?? []), [tote]);
  const { t } = useTranslation();
  const [selectedBinId, setSelectedBinId] = useState<string | null>(null);
  const selectedBin = useMemo<SimplifiedBinInfo | undefined>(() => bins.find(({ id }) => id === selectedBinId), [selectedBinId, bins]);

  const sortedBins = useSortedBins(bins, reverse);

  if (loading) {
    return <Spinner size={'xl'} />;
  }

  if (error) {
    logger.error('failed to fetch tote content', {
      error,
      toteId,
    });

    return (
      <Text weight={700} color={'red.600'} size={18}>
        {t('toteContentModal.apiError')}
      </Text>
    );
  }

  return (
    <Flex flexWrap={'wrap'} fullWidth>
      <ToteContainer bins={sortedBins.length} enabled>
        {sortedBins.map(({ id, name, quantity, imageUrl }) => (
          <BinContent
            key={id}
            imageUrl={imageUrl}
            quantity={quantity}
            name={name}
            id={id}
            onSelect={setSelectedBinId}
            masked={!!(selectedBinId && id !== selectedBinId)}
          />
        ))}
      </ToteContainer>
      <StyledBinContentInfo>
        {selectedBin && (
          <BinContentInfo
            id={selectedBin.id}
            name={selectedBin.name}
            quantity={selectedBin.quantity}
            sku={selectedBin.sku}
            barcodes={selectedBin.barcodes}
            imageUrl={selectedBin.imageUrl}
            expiryDate={selectedBin.expiryDate}
            bestBeforeDate={selectedBin.bestBeforeDate}
          />
        )}
      </StyledBinContentInfo>
    </Flex>
  );
};
