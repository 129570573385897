import React from 'react';
import type { FunctionComponent } from 'react';
import { SubstationContainer } from '../../../../shared/components/Substations';
import { ExtractStockPage } from './ExtractStockPage';

interface SubstationStepsProps {
  position: 'left' | 'right';
}

export const Substation: FunctionComponent<SubstationStepsProps> = ({ position }) => (
  <SubstationContainer data-testid={`${position}-substation`} active inactive={false}>
    <ExtractStockPage position={position} />
  </SubstationContainer>
);
