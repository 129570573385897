import { useRecoilValue } from 'recoil';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useSessionStorage } from 'react-use';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { sessionIdState, stationIdState } from '../state';
import { setActive } from '../clients/sessionApi';
import { logger } from '../clients/loggingApi';

export const useActiveSession = () => {
  const activeSessionId = useRecoilValue(sessionIdState);
  const [sessionId] = useSessionStorage<string>('sessionId', uuid(), true);
  const stationId = useRecoilValue(stationIdState);
  const { t } = useTranslation();

  const activeSession = useMemo(() => activeSessionId === sessionId, [activeSessionId, sessionId]);

  const setActiveSession = useCallback(async () => {
    if (activeSession) {
      return;
    }

    try {
      await setActive(sessionId, stationId, uuid());
    } catch (error) {
      logger.error('error setting active session', {
        error,
        sessionId,
      });
      toast.error(t('session.failedToSetActiveSession'));
    }
  }, [activeSession, sessionId, stationId, t]);

  return useMemo(
    () => ({
      activeSession,
      sessionId,
      setActiveSession,
    }),
    [activeSession, sessionId, setActiveSession],
  );
};
