import io from 'socket.io-client';
import { SocketEvents } from '@get-fabric/live-station-api-types';
import type { ScanUpdate, StationUpdates, ButtonPressUpdate } from '@get-fabric/live-station-api-types';
import Emittery from 'emittery';
import { getToken } from '../../framework/getToken';
import { liveStationApiBasePath } from '../../framework/environment';
import { initSocketEvents } from './initSocketEvents';
import { logger } from './loggingApi';

export const emitter = new Emittery<{
  [SocketEvents.ScanUpdate]: ScanUpdate;
  [SocketEvents.StationUpdates]: StationUpdates;
  [SocketEvents.ButtonPressUpdate]: ButtonPressUpdate;
}>();

export const liveStationApi = io(liveStationApiBasePath, {
  reconnection: true,
  autoConnect: false,
  query: {
    clientVersion: '1',
    clientApi: 'touchpoint',
  },
  transports: ['websocket'],
  async auth(cb) {
    const token = await getToken();

    cb({ token: `Bearer ${token}` });
  },
});

const initLiveStationApiEvents = () => {
  initSocketEvents(liveStationApi);

  liveStationApi.on(SocketEvents.StationUpdates, (data: StationUpdates) => {
    logger.info('Station Update event received', { data });
    void emitter.emit(SocketEvents.StationUpdates, data);
  });

  liveStationApi.on(SocketEvents.ScanUpdate, (data: ScanUpdate) => {
    logger.info('Scan Update event received', { data });
    void emitter.emit(SocketEvents.ScanUpdate, data);
  });

  liveStationApi.on(SocketEvents.ButtonPressUpdate, (data: ButtonPressUpdate) => {
    logger.info('Button press Update event received', { data });
    void emitter.emit(SocketEvents.ButtonPressUpdate, data);
  });
};

export const init = () => {
  liveStationApi.connect();
  initLiveStationApiEvents();

  return () => {
    liveStationApi.removeAllListeners();
    liveStationApi.disconnect();
  };
};

export const subscribe = (stationId: string) => {
  const emit = () => {
    liveStationApi.emit(SocketEvents.Subscribe, { stationId }, () => liveStationApi.emit(SocketEvents.Snapshot, { stationId }));
  };
  const unsubscribe = () => {
    liveStationApi.off('connect', emit);
  };

  liveStationApi.on('connect', emit);
  emit();

  return () => {
    unsubscribe();
    liveStationApi.emit(SocketEvents.Unsubscribe, { stationId });
  };
};
