import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { ToteOrientation } from '@get-fabric/station-api-client';
import type { Bin } from '@get-fabric/wms-api-client';
import type { UUID } from '@get-fabric/station-api-types';
import { ToteBins } from './index';

interface SubstationToteBinsProps {
  toteId: string;
  bins: Bin[];
  orientation?: ToteOrientation;
  selectedBin?: UUID;
  completedBins?: Set<UUID>;
  position: 'left' | 'right';
  enabled?: boolean;
}

export const SubstationToteBins: FunctionComponent<SubstationToteBinsProps> = ({
  toteId,
  bins,
  orientation = ToteOrientation.Front,
  position,
  completedBins,
  selectedBin,
  enabled = true,
}) => {
  const reverse = useMemo(() => {
    const correctOrientation = orientation === ToteOrientation.Front;
    const correctPosition = position === 'right';

    return correctOrientation !== correctPosition;
  }, [orientation, position]);

  return (
    <ToteBins toteId={toteId} bins={bins} reverse={reverse} completedBins={completedBins} selectedBin={selectedBin} enabled={enabled} />
  );
};
