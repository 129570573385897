import type { FunctionComponent } from 'react';
import React, { useCallback, useContext } from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import { QcCheckResult } from '@get-fabric/wms-api-client';
import { useAsync } from 'react-use';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useBinBlindCheckSteps } from '../hooks/useBinBlindCheckSteps';
import { reportBlindCheckResults } from '../services/blindCheckExceptions';
import { ActionContext } from '../providers/ActionProvider';
import { mfcIdState, stationIdState } from '../../../../shared/state';
import { logger } from '../../../../shared/clients/loggingApi';
import { VerifyProduct } from './VerifyProduct';
import { CheckQuantity } from './CheckQuantity';
import { CheckExpirationDate } from './CheckExpirationDate';

interface BinBlindCheckStepsProps {
  bin: Bin;
  onComplete: (result: QcCheckResult) => Promise<void>;
}

export const BinBlindCheckSteps: FunctionComponent<BinBlindCheckStepsProps> = ({ bin, onComplete }) => {
  const { t } = useTranslation();
  const mfcId = useRecoilValue(mfcIdState);
  const stationId = useRecoilValue(stationIdState);
  const { id: actionId } = useContext(ActionContext);

  const {
    verifyProductCompleted: verifyProductStepCompleted,
    checkQuantityCompleted: checkQuantityStepCompleted,
    checkExpiryDateCompleted: checkExpiryDateStepCompleted,
    reportedProduct,
    reportedQuantity,
    reportedExpiryDate,
    completeVerifyProduct,
    completeCheckQuantity,
    completeCheckExpiryDate,
  } = useBinBlindCheckSteps();

  const emptyExceptionCallback = useCallback(() => ({}), []);

  useAsync(async () => {
    if (!verifyProductStepCompleted || !checkQuantityStepCompleted || !checkExpiryDateStepCompleted) {
      return;
    }

    const expectedProduct = bin.content?.[0]?.product;
    const expectedQuantity = bin.content?.[0]?.quantity;
    const expectedExpiryDate = bin.content?.[0]?.product.expiryDate;

    let exceptionReported = false;
    try {
      exceptionReported = await reportBlindCheckResults(
        mfcId,
        stationId,
        actionId,
        bin,
        { product: expectedProduct, quantity: expectedQuantity, expiryDate: expectedExpiryDate ? new Date(expectedExpiryDate) : undefined },
        { product: reportedProduct, quantity: reportedQuantity, expiryDate: reportedExpiryDate },
      );
    } catch (error) {
      logger.error('Failed reporting bin QC blind check exceptions', {
        mfcId,
        stationId,
        actionId,
        bin,
        expectedProduct,
        expectedQuantity,
        expectedExpiryDate,
        reportedProduct,
        reportedQuantity,
        reportedExpiryDate,
        wmsError: error,
      });
      toast.error(t('qc.failedUpdatingBlindCheck'));
    }

    await onComplete(exceptionReported ? QcCheckResult.Exception : QcCheckResult.NoException);
  }, [
    bin,
    onComplete,
    verifyProductStepCompleted,
    checkQuantityStepCompleted,
    checkExpiryDateStepCompleted,
    reportedProduct,
    reportedQuantity,
    reportedExpiryDate,
  ]);

  if (!verifyProductStepCompleted) {
    return <VerifyProduct bin={bin} onComplete={completeVerifyProduct} onException={emptyExceptionCallback} />;
  }

  if (!checkQuantityStepCompleted) {
    return <CheckQuantity binId={bin.id} onComplete={completeCheckQuantity} onException={emptyExceptionCallback} />;
  }

  if (!checkExpiryDateStepCompleted) {
    return <CheckExpirationDate binId={bin.id} onComplete={completeCheckExpiryDate} onException={emptyExceptionCallback} />;
  }

  return null;
};
