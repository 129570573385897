import type { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';
import Block from '@mui/icons-material/Block';
import { grey } from '@mui/material/colors';
import { Flex } from '@get-fabric/fabric-design-system';
import { ToteContainer } from '../SubstationToteBins/ToteBins.styles';

const StyledToteContainer = styled(ToteContainer)`
  background-color: ${grey[300]};
  filter: blur(6px);
`;

const StyledIcon = styled(Block)`
  color: ${grey[600]};
  position: absolute;
`;

export const ToteNotAvailable: FunctionComponent = () => (
  <Flex justifyContent={'center'} alignItems={'center'}>
    <StyledToteContainer bins={1} enabled />
    <StyledIcon fontSize={'large'} />
  </Flex>
);
