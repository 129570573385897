import { AllocationsApi, Configuration } from '@get-fabric/allocation-api-client';
import { enhancedFetch } from '../../framework/enhancedFetch';
import { allocationApiBasePath } from '../../framework/environment';

export const allocationsApi = new AllocationsApi(
  new Configuration({
    basePath: `${allocationApiBasePath}/v1`,
    fetchApi: enhancedFetch,
  }),
);
