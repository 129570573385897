import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useAsync, useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { Button, Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import { v4 as uuid } from 'uuid';
import { stationIdState } from '../../../../shared/state';
import { useActiveSession } from '../../../../shared/hooks';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { logger } from '../../../../shared/clients/loggingApi';
import { Loading } from '../../../../shared/components/Loading';
import { InstructionContainer } from '../../../../shared/components/InstructionContainer';
import { OrderTote } from '../../../../shared/components/OrderTote/OrderTote';
import { updateTotesRequestId } from '../../../../shared/clients/stationApi';
import { InstructionTextContainer } from '../../../../shared/components/InstructionTextContainer';
import { OrderToteBins } from '../../../../shared/components/OrderTote/OrderToteBins';
import { ActionBar } from './shared/ActionBar';

interface Props {
  position: 'left' | 'right';
  active: boolean;
  allocation: Allocation;
  toteId: string;
  orderToteId: string;
}

export const RpcInstructions: FunctionComponent<Props> = ({ position, active, allocation, toteId, orderToteId }) => {
  const stationId = useRecoilValue(stationIdState);
  const { activeSession } = useActiveSession();
  const { t } = useTranslation();

  const { loading: markingAllocationOngoing } = useAsync(async () => {
    if (allocation.status !== AllocationStatus.Approved || !activeSession) {
      return;
    }

    try {
      await allocationsApi.updateByAllocationId(allocation.id, { payload: { status: AllocationStatus.Ongoing } });
    } catch (error) {
      logger.error('During picking rpc tote, failed to update allocation status to ongoing', {
        error,
        allocation,
      });
      toast.error(t('pickingOut.failedMarkingAllocationAsOngoing'));
    }
  }, [allocation.id]);

  const [{ loading: pickingInProgress }, confirm] = useAsyncFn(async () => {
    if (!active || !activeSession) {
      return;
    }

    // Add move stock to order tote when reply implements

    const correlationId = uuid();

    const substationPosition = `${position}-out`;

    try {
      await updateTotesRequestId(allocation.totesRequestId, substationPosition, stationId, correlationId);
    } catch (error) {
      logger.error('failed to update station tote request id', {
        error,
        toteId: orderToteId,
        toteRequestId: allocation.totesRequestId,
        substationPosition,
        correlationId,
      });
    }
  });

  const confirmDisabled = useMemo(() => !activeSession || pickingInProgress, [activeSession, pickingInProgress]);

  if (markingAllocationOngoing || allocation.status !== AllocationStatus.Ongoing) {
    return <Loading />;
  }

  return (
    <InstructionContainer flexGrow={1}>
      <ActionBar>
        <OrderTote orderToteId={orderToteId} />
      </ActionBar>
      <Flex flexGrow={1} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={45}>
        <InstructionTextContainer gap={32}>
          <Text size={40}>{t('pickingOut.fullTotePicking.moveItems')}</Text>
          <Text size={40}>{t('pickingOut.fullTotePicking.itemsWerePicked')}</Text>
        </InstructionTextContainer>
        {pickingInProgress && <Spinner size={'l'} />}
        <OrderToteBins toteId={toteId} />
        <Button size={'l'} variant={'secondary'} loading={pickingInProgress} disabled={confirmDisabled} onClick={confirm}>
          {t('actions.confirm')}
        </Button>
      </Flex>
    </InstructionContainer>
  );
};
