import type { FunctionComponent } from 'react';
import React, { useEffect } from 'react';
import { ModalBody, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { ExceptionModalFooter } from '../ExceptionsModal';
import { stationNameState } from '../../../state';

interface MoveDamagedItemProps {
  submitException: () => Promise<void>;
  damagedTrolleyLocation?: string;
}

export const MoveDamagedItem: FunctionComponent<MoveDamagedItemProps> = ({ damagedTrolleyLocation, submitException }) => {
  const { t } = useTranslation();
  const stationName = useRecoilValue(stationNameState);

  useEffect(() => {
    if (!damagedTrolleyLocation) {
      toast.error(t('exceptions.damagedItem.noDamagedTrolleyToast'), { toastId: 'noDamagedTrolleyToast' });
    }
  }, [t, damagedTrolleyLocation]);

  return (
    <>
      <ModalBody>
        <Text size={22}>
          {damagedTrolleyLocation
            ? t('exceptions.damagedItem.damagedTrolley', { stationName })
            : t('exceptions.damagedItem.noDamagedTrolley')}
        </Text>
      </ModalBody>
      <ExceptionModalFooter reportException={submitException} disableReport={!damagedTrolleyLocation} />
    </>
  );
};
