import { Flex, Spinner, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTimeout } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { substationOverflowState } from '../../state';
import { OverflowDetected } from './OverflowDetected';

interface WaitingForToteToGoDownProps {
  substationPosition: string;
}

export const WaitingForToteToGoDown: FunctionComponent<WaitingForToteToGoDownProps> = ({ substationPosition }) => {
  const [waitingForTotesTextTimeout] = useTimeout(2000);
  const { t } = useTranslation();
  const overflow = useRecoilValue(substationOverflowState(substationPosition));
  const [overflowDetected, setOverflowDetected] = useState<boolean>(false);

  const onDismissTote = useCallback(() => {
    setOverflowDetected(false);
  }, []);

  useEffect(() => {
    if (overflow) {
      setOverflowDetected(true);
    }
  }, [overflow]);

  return (
    <Flex
      flexGrow={1}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      gap={25}
      data-testid={'waitingForToteToGoDown'}
    >
      {overflowDetected ? (
        <OverflowDetected substationPosition={substationPosition} onDismissTote={onDismissTote} />
      ) : (
        <>
          {waitingForTotesTextTimeout() && (
            <Text weight={300} size={64} textAlign={'center'}>
              {t('waitingForToteToGoDown.waitingMessage')}
            </Text>
          )}
          <Spinner size={'l'} />
        </>
      )}
    </Flex>
  );
};
