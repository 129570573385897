import type { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Flex } from '@get-fabric/fabric-design-system';
import ImageNotSupported from '@mui/icons-material/ImageNotSupported';

const StyledFlex = styled(Flex)`
  background-color: #e0e0e0;
`;

export const BrokenImageFallback: FunctionComponent = () => (
  <StyledFlex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} flexGrow={1} fullWidth>
    <ImageNotSupported color={'disabled'} />
  </StyledFlex>
);
