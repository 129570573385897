import { atom, selector } from 'recoil';
import type { Session } from '@get-fabric/session-api-client';

export const sessionAtom = atom<Session | undefined>({
  key: 'session',
  default: undefined,
});

export const sessionIdState = selector({
  key: 'sessionId',
  get: ({ get }) => get(sessionAtom)?.sessionId,
});
