import { Flex, Text } from '@get-fabric/fabric-design-system';
import ReactJson from 'react-json-view';
import { Divider } from '@mui/material';
import type { FunctionComponent } from 'react';
import React, { useEffect } from 'react';
import { useList } from 'react-use';
import type { Station } from '@get-fabric/station-api-types';
import { formatDistanceToNow } from 'date-fns';

const stationHistoryLength = 10;

interface StationHistoryProps {
  station: Station;
}

export const StationHistory: FunctionComponent<StationHistoryProps> = ({ station }) => {
  const [stationHistory, { push, removeAt }] = useList<{ station: Station; timestamp: Date }>();

  useEffect(() => {
    if (stationHistory.length >= stationHistoryLength) {
      removeAt(0);
    }
    push({ station, timestamp: new Date() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station, push, removeAt]);

  return (
    <>
      {stationHistory.map(({ station, timestamp }, index) => (
        <Flex flexDirection={'column'} gap={4} key={`stationSnapshot-${timestamp}`}>
          <Flex gap={2}>
            <Text>{'Timestamp:'}</Text>
            <Text code>{formatDistanceToNow(timestamp)}</Text>
          </Flex>
          <ReactJson src={station} collapsed />
          {stationHistory.length - 1 !== index && <Divider />}
        </Flex>
      ))}
    </>
  );
};
