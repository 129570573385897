import React from 'react';
import type { FunctionComponent } from 'react';
import { Button, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../../../shared/hooks';
import { PickingActionDataModal } from './PickingActionDataModal';

interface PickingActionDataProps {
  actionId: string;
}

export const PickingActionData: FunctionComponent<PickingActionDataProps> = ({ actionId }) => {
  const { t } = useTranslation();
  const { modalOpen, open, close } = useModalState();

  return (
    <>
      <Button size={'xs'} variant={'secondary'} onClick={open}>
        <Text textAlign={'center'} size={16}>
          {t('orderData.orderDataButton')}
        </Text>
      </Button>
      <PickingActionDataModal open={modalOpen} onClose={close} actionId={actionId} />
    </>
  );
};
