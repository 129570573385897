import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useActiveSession, useModalState } from '../../../hooks';
import { ConfirmActiveSessionModal } from './SetAsActiveModal';
import { ActiveSessionButton } from './ActiveSessionButton';

export const ActiveSession: FunctionComponent = () => {
  const { open, modalOpen, close } = useModalState();
  const { activeSession, setActiveSession } = useActiveSession();
  const { t } = useTranslation();

  useUpdateEffect(() => {
    if (activeSession) {
      return;
    }

    toast.error(t('activeSession.revoked'), { toastId: 'activeSessionRevoked' });
  }, [activeSession, t]);

  const confirmActiveSession = useCallback(async () => {
    if (activeSession) {
      return;
    }

    close();
    await setActiveSession();

    toast.success(t('activeSession.success'), { toastId: 'activeSessionSuccess' });
  }, [activeSession, close, setActiveSession, t]);

  return (
    <>
      <ActiveSessionButton onClick={open} />
      <ConfirmActiveSessionModal modalOpen={modalOpen} close={close} onConfirm={confirmActiveSession} />
    </>
  );
};
