import type { Allocation } from '@get-fabric/allocation-api-client';
import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { useUnfulfilledAllocations } from './useUnfulfilledAllocations';

export interface SubstationAllocationsProps {
  position: string;
  toteInPositionId?: string;
}

export const useSubstationToteAllocations = ({ position, toteInPositionId }: SubstationAllocationsProps) => {
  const unfulfilledAllocations = useUnfulfilledAllocations();

  const allocations = useMemo<Allocation[]>(
    () =>
      sortBy(
        unfulfilledAllocations?.filter(({ substationPosition }) => substationPosition === position) ?? [],
        (allocation) => allocation.id,
      ),
    [unfulfilledAllocations, position],
  );

  const currentToteAllocations = useMemo(
    () => allocations.filter(({ toteId }) => toteId === toteInPositionId) ?? [],
    [allocations, toteInPositionId],
  );

  const currentAllocation = useMemo(() => currentToteAllocations[0], [currentToteAllocations]);

  return { allocations, currentAllocation, currentToteAllocations };
};
