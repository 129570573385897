import React, { useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import { Spinner, Text } from '@get-fabric/fabric-design-system';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { List } from '@mui/material';
import type { BinContent } from '@get-fabric/wms-api-client';
import { toteApi } from '../../clients/wmsApi';
import { mfcIdState } from '../../state';
import { logger } from '../../clients/loggingApi';
import { OrderToteListItem } from './OrderToteListItem';

interface ToteContentProps {
  toteId: string;
}

export const OrderToteContent: FunctionComponent<ToteContentProps> = ({ toteId }) => {
  const { t } = useTranslation();
  const mfcId = useRecoilValue(mfcIdState);
  const { value: tote, loading, error: toteFetchError } = useAsync(() => toteApi.getTote(toteId, mfcId), [toteId, mfcId]);

  const orderToteBinContent: BinContent[] = useMemo(
    () =>
      Object.values(
        tote?.bins[0].content.reduce(
          (prev, { product, quantity }) => ({
            ...prev,
            [product.sku]: { product, quantity: quantity + (prev[product.sku]?.quantity ?? 0) },
          }),
          {},
        ) ?? {},
      ),
    [tote?.bins],
  );

  if (loading) {
    return <Spinner size={'l'} />;
  }

  if (toteFetchError) {
    logger.error('failed to fetch order tote content', {
      error: toteFetchError,
      toteId,
    });

    return (
      <Text weight={700} color={'red.600'} size={18}>
        {t('orderToteContentModal.apiError')}
      </Text>
    );
  }

  if (!orderToteBinContent.length) {
    return <Text size={28}>{t('orderToteContentModal.emptyTote')}</Text>;
  }

  return (
    <List sx={{ width: '100%', maxWidth: 360 }}>
      {orderToteBinContent.map(({ product, quantity }) => (
        <OrderToteListItem key={product.sku} product={product} quantity={quantity} />
      ))}
    </List>
  );
};
