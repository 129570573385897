import io from 'socket.io-client';
import { SocketEvents } from '@get-fabric/live-session-api-types';
import type { SessionUpdate } from '@get-fabric/live-session-api-types';
import Emittery from 'emittery';
import { getToken } from '../../framework/getToken';
import { liveSessionApiBasePath } from '../../framework/environment';
import { initSocketEvents } from './initSocketEvents';

export const emitter = new Emittery<{
  [SocketEvents.SessionUpdates]: SessionUpdate;
}>();

export const liveSessionApi = io(liveSessionApiBasePath, {
  reconnection: true,
  autoConnect: false,
  query: {
    clientVersion: '1',
    clientApi: 'touchpoint',
  },
  transports: ['websocket'],
  async auth(cb) {
    const token = await getToken();

    cb({ token: `Bearer ${token}` });
  },
});

const initLiveSessionApiEvents = () => {
  initSocketEvents(liveSessionApi);

  liveSessionApi.on(SocketEvents.SessionUpdates, (data: SessionUpdate) => {
    void emitter.emit(SocketEvents.SessionUpdates, data);
  });
};

export const init = () => {
  liveSessionApi.connect();
  initLiveSessionApiEvents();

  return () => {
    liveSessionApi.removeAllListeners();
    liveSessionApi.disconnect();
  };
};

export const subscribe = (stationId: string) => {
  const emit = () => {
    liveSessionApi.emit(SocketEvents.Subscribe, { stationId }, () => liveSessionApi.emit(SocketEvents.Snapshot, { stationId }));
  };
  const unsubscribe = () => {
    liveSessionApi.off('connect', emit);
  };

  liveSessionApi.on('connect', emit);
  emit();

  return () => {
    unsubscribe();
    liveSessionApi.emit(SocketEvents.Unsubscribe, { stationId });
  };
};
