import React from 'react';
import { useSubstationsWithFulfilledTote } from '../hooks/useSubstationsWithFulfilledTote';
import { WaitingForTotes } from '../../../../shared/components/WaitingForTotes';
import { WaitingForToteToGoDown } from '../../../../shared/components/WaitingForToteToGoDown';
import { useCurrentTotes } from '../hooks/useCurrentTotes';
import { useDecantProcess } from '../hooks/useDecantProcess';
import { ScanProductPage } from './ScanProductPage';
import { MoveProductPage } from './MoveProductPage';

export const DecantPages = () => {
  const [process, { startDecant, completeDecant, cancelDecant }] = useDecantProcess();
  const totes = useCurrentTotes();
  const [substationWithFulfilledTote] = useSubstationsWithFulfilledTote();

  if (substationWithFulfilledTote) {
    return <WaitingForToteToGoDown substationPosition={substationWithFulfilledTote} />;
  }

  if (!totes?.length) {
    return <WaitingForTotes />;
  }

  if (!process) {
    return <ScanProductPage startDecant={startDecant} />;
  }

  return <MoveProductPage process={process} cancelDecant={cancelDecant} completeDecant={completeDecant} />;
};
