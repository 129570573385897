const upca = (barcode: string): string => barcode;

const map: Record<string, (barcode: string) => string> = {
  default: upca,
};

export const parseProductBarcode = (mfcId: string, barcode: string): string => (map[mfcId] ? map[mfcId](barcode) : map.default(barcode));

export const TOTE_BARCODE_REGEX = /^[BFLRbflr][ABab](\d+)$/;

export const parseToteBarcode = (barcode: string): string | undefined => TOTE_BARCODE_REGEX.exec(barcode)?.[1];
