import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import type { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';
import { StaticDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addYears } from 'date-fns';
import { StyledDatePicker } from './DatePicker.styles';
import { PickerToolbar } from './Toolbar';
import { PickerInput } from './Input';

interface DatePickerProps {
  dateValue: Date | null;
  setDateValue: any;
  futureYearsOffset?: number;
  pastYearsOffset?: number;
  onViewChange: (view: CalendarOrClockPickerView) => void;
}

export const DatePicker: FunctionComponent<DatePickerProps> = ({
  dateValue,
  setDateValue,
  futureYearsOffset = 5,
  pastYearsOffset = 2,
  onViewChange,
}) => {
  const todayDate = useMemo(() => new Date(), []);

  return (
    <StyledDatePicker>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDateTimePicker
          displayStaticWrapperAs={'desktop'}
          openTo={'year'}
          views={['year', 'month', 'day']}
          value={dateValue}
          onChange={setDateValue}
          minDate={addYears(todayDate, -pastYearsOffset)}
          maxDate={addYears(todayDate, futureYearsOffset)}
          renderInput={PickerInput}
          ToolbarComponent={PickerToolbar}
          onViewChange={onViewChange}
        />
      </LocalizationProvider>
    </StyledDatePicker>
  );
};
