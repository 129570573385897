import { useEffect } from 'react';
import type { ButtonPressUpdate } from '@get-fabric/live-station-api-types';
import { SocketEvents } from '@get-fabric/live-station-api-types';
import { emitter as socketEmitter } from '../clients/liveStationApi';
import { useActiveSession } from './useActiveSession';

export interface ButtonPressEvent {
  position: string;
}

export const useButtonPress = (onButtonPress: (buttonPress: ButtonPressEvent) => void) => {
  const { activeSession } = useActiveSession();

  useEffect(() => {
    if (!activeSession) {
      return;
    }

    const socketUnsubscribe = socketEmitter.on(SocketEvents.ButtonPressUpdate, (data: ButtonPressUpdate) => {
      onButtonPress({ position: data.value.substationPos });
    });

    return () => {
      socketUnsubscribe();
    };
  }, [onButtonPress, activeSession]);
};
