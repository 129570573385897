import React from 'react';
import type { FunctionComponent } from 'react';
import { SubstationContainer } from '../../../../shared/components/Substations';
import type { SubstationStepsProps } from '../../PickingOut/components/SubstationStepsProps';
import { SubstationSteps } from './SubstationSteps';

export const Substation: FunctionComponent<SubstationStepsProps> = ({ position, activeSubstation, setReady }) => (
  <SubstationContainer
    data-testid={`${position}-substation`}
    aria-disabled={!!activeSubstation && position !== activeSubstation}
    active={position === activeSubstation}
    inactive={!!activeSubstation && position !== activeSubstation}
  >
    <SubstationSteps position={position} setReady={setReady} activeSubstation={activeSubstation} />
  </SubstationContainer>
);
