import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import type { Substations } from '@get-fabric/station-api-types';

interface SubstationsStateProps {
  substations: Substations;
}

export const SubstationsState: FunctionComponent<SubstationsStateProps> = ({ substations }) => {
  const substationsWithPosition = useMemo(
    () =>
      Object.entries(substations)
        .filter(([, substation]) => substation.totes?.current)
        .map(([position, substation]) => ({ position, ...substation })),
    [substations],
  );

  if (!substationsWithPosition.length) {
    return <Text size={16}>{'No totes'}</Text>;
  }

  return (
    <Flex flexDirection={'column'} gap={4} pl={8}>
      {substationsWithPosition.map(({ position, totes }) => (
        <Flex gap={2} key={position}>
          <Text size={18} capitalize>{`${position} Tote:`}</Text>
          <Text size={18} capitalize code>
            {totes?.current?.id}
          </Text>
          <Text size={18} capitalize code>
            {totes?.current?.totesRequestId}
          </Text>
          <Text size={18} capitalize code>
            {totes?.current?.orientation}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};
