import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import type { Tote } from '@get-fabric/wms-api-client';
import { ToteKey, ToteOrder } from '@get-fabric/station-api-client';
import { useTranslation } from 'react-i18next';
import { stationApi } from '../../../../shared/clients/stationApi';
import { useScan } from '../../../../shared/hooks';
import { mfcIdState, stationIdState } from '../../../../shared/state';
import { parseToteBarcode } from '../../../../shared/services/parseBarcode';
import { toteApi } from '../../../../shared/clients/wmsApi';
import { logger } from '../../../../shared/clients/loggingApi';

const StyledText = styled(Text)`
  max-width: 450px;
`;

interface ScanHolderInstructionProps {
  position: string;
  active: boolean;
}

export const ScanHolderInstruction: FunctionComponent<ScanHolderInstructionProps> = ({ position, active }) => {
  const stationId = useRecoilValue(stationIdState);
  const mfcId = useRecoilValue(mfcIdState);
  const { t } = useTranslation();

  const addOrderHolder = useCallback(
    async (barcode: string) => {
      let holder: Tote;
      const correlationId = uuid();

      try {
        holder = await toteApi.getTote(barcode, mfcId, correlationId);
      } catch (error) {
        logger.error('failed to get tote', {
          error,
          barcode,
          correlationId,
        });

        toast.error(
          t((error as Response)?.status === 404 ? 'scanHolder.notFound' : 'scanHolder.failedToGetHolder', { holderBarcode: barcode }),
          { toastId: 'scanHolderNotFound' },
        );

        return;
      }

      if (holder.bins.length > 1) {
        logger.error('tote has more than one bin', {
          tote: holder,
          correlationId,
        });

        toast.error(t('scanHolder.wrongBinNumber', { holderBarcode: barcode }), { toastId: 'scanHolderWrongBinNumber' });

        return;
      }

      if (holder.bins[0].content.length > 0) {
        logger.error('tote is not empty', {
          tote: holder,
          correlationId,
        });

        toast.error(t('scanHolder.holderNotEmpty', { holderBarcode: barcode }), { toastId: 'scanHolderHolderNotEmpty' });

        return;
      }

      if (holder.zone !== 'OUT') {
        logger.error('tote is not in OUT zone', {
          tote: holder,
          correlationId,
        });

        toast.error(t('scanHolder.wrongToteZone', { holderBarcode: barcode, holderZone: holder.zone }), {
          toastId: 'wrongToteZone',
        });

        return;
      }

      try {
        await stationApi.updateTote(stationId, `${position}-out`, ToteOrder.Current, {
          payload: { tote: { id: holder.id }, removeFields: [ToteKey.TotesRequestId] },
          meta: { correlationId },
        });
      } catch (error) {
        logger.error('failed to update tote', {
          error,
          tote: holder,
          correlationId,
        });

        toast.error(t('scanHolder.updateToteFailed', { holderBarcode: barcode }), { toastId: 'updateToteFailed' });

        return;
      }
    },
    [mfcId, position, stationId, t],
  );

  useScan(async ({ barcode }) => {
    if (!active) {
      return;
    }

    const parsedBarcode = parseToteBarcode(barcode);

    if (!parsedBarcode) {
      logger.error('invalid tote barcode', {
        barcode,
      });

      toast.error(t('scanHolder.invalidToteBarcode', { barcode }), { toastId: 'invalidToteBarcode' });

      return;
    }

    await addOrderHolder(parsedBarcode);
  });

  return (
    <Flex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={6}>
      <StyledText weight={300} size={64} textAlign={'center'}>
        {t('scanHolder.scanInstruction')}
      </StyledText>
    </Flex>
  );
};
