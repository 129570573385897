import mixpanel from 'mixpanel-browser';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { operatorApi } from '../../../../shared/clients/wmsApi';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { createTrace } from '../../../../framework/correlation';
import { wmsErrorHandler, wmsErrorTypes } from '../../../../shared/components/Exceptions/wmsErrorHandler';
import { queryClient } from '../../../App';
import type { Tote } from '../../../../shared/state';
import { logger } from '../../../../shared/clients/loggingApi';

export const pick = async (
  allocation: Allocation,
  pickedQuantity: number,
  movedQuantity: number,
  orderTote: Tote,
  stationId: string,
  correlationId: string,
) => {
  const traced = createTrace();

  const targetHolderId = orderTote.bins[0].id;
  mixpanel.track('Started picking-out pick');
  const pickingCompletedAnalyticsEvent = 'Picking-out pick completed';
  const pickingStartTime = Date.now();
  mixpanel.time_event(pickingCompletedAnalyticsEvent);

  try {
    logger.debug('picking first step started', { correlationId });
    await traced(operatorApi).confirmPicking({
      payload: {
        quantity: pickedQuantity,
        movedQuantity,
        sourceHolderId: allocation.binId,
        targetHolderId,
        actionId: allocation.actionId!,
        stationId,
      },
      meta: { correlationId },
    });
    logger.debug('picking first step completed', { correlationId });
  } catch (error) {
    logger.error('picking first step failed', { correlationId, error });
    wmsErrorHandler(error, wmsErrorTypes.wmsPickingError, 'Failed to update action', {
      quantity: pickedQuantity,
      sourceHolderId: allocation.binId,
      targetHolderId,
      actionId: allocation.actionId!,
      correlationId,
    });
  }

  logger.debug('picking second step started', { correlationId });
  const [updatedAllocation] = await Promise.all([
    traced(allocationsApi).increaseQuantity(allocation.id, {
      payload: { originalQuantity: allocation.quantity, amount: pickedQuantity },
      meta: { correlationId },
    }),
    queryClient.invalidateQueries(['totes', allocation.toteId]),
    queryClient.invalidateQueries(['totes', orderTote.id]),
  ]);
  mixpanel.track(pickingCompletedAnalyticsEvent, {
    stationId,
    correlationId,
    allocationId: allocation.id,
    DurationMs: Date.now() - pickingStartTime,
  });
  logger.debug('picking second step completed', { correlationId });

  return updatedAllocation;
};
