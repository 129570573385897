import { Button, Flex, Text } from '@get-fabric/fabric-design-system';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Product } from '@get-fabric/wms-api-client';
import { ProductCard } from '../../../../shared/components/ProductCard';

interface InstructionsProps {
  product: Product | undefined;
  loading: boolean;
  confirm: () => void;
}

export const Instructions: FunctionComponent<InstructionsProps> = ({ product, confirm, loading }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={45} fullWidth>
      {product && <ProductCard product={product} />}
      <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={6} flexWrap={'wrap'}>
        <Text size={34}>{t('extractStock.confirmation')}</Text>
        <Flex gap={5}>
          <Button loading={loading} onClick={confirm}>
            {t('actions.yes')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
