import type { FunctionComponent } from 'react';
import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { ProductImage } from './ProductImage';

interface ProductCardProps {
  product: { name: string; sku: string; imageUrl: string; barcodes: string[] };
}

export const ProductCard: FunctionComponent<ProductCardProps> = ({ product: { sku, name, imageUrl, barcodes } }) => (
  <Card sx={{ padding: 2, width: 'fit-content', borderWidth: '2px' }} variant={'outlined'} data-testid={`productCard-${sku}`}>
    <Flex alignItems={'center'} flexDirection={'column'}>
      <ProductImage url={imageUrl} />
      <CardContent>
        <Flex flexDirection={'column'}>
          <Text textAlign={'center'} size={28} weight={700}>
            {name}
          </Text>
          <Text textAlign={'center'} size={28}>
            {barcodes[0]}
          </Text>
        </Flex>
      </CardContent>
    </Flex>
  </Card>
);
