import type { FunctionComponent } from 'react';
import React from 'react';
import type { StationMode } from '@get-fabric/station-api-types';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ArrowIcon } from './ArrowIcon';

const StyledModeContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const TouchpointMode: FunctionComponent<{ mode: StationMode }> = ({ mode }) => {
  const { t } = useTranslation();

  return (
    <StyledModeContainer gap={4} alignItems={'center'}>
      <Text size={22} capitalize>
        {t(`modes.${mode.current.name}`, mode.current.name)}
      </Text>
      {mode.next?.name && (
        <>
          <ArrowIcon />
          <Text size={22} capitalize>
            {t(`modes.${mode.next.name}`, mode.next.name)}
          </Text>
        </>
      )}
    </StyledModeContainer>
  );
};
