import type { DecantActionProduct, PartialDecantPackaging } from '@get-fabric/wms-api-client';
import { productsApi } from '../../../../../shared/clients/wmsApi';
import { getProductPackaging } from '../../services';

export type ProductProperties = Pick<DecantActionProduct, 'scannable'> &
  Pick<PartialDecantPackaging, 'maxItemsPerBin' | 'partitions' | 'toteHeight'>;

export const getProductProperties = (product: DecantActionProduct): ProductProperties => {
  const { toteHeight, partitions, maxItemsPerBin } = getProductPackaging(product);

  return { toteHeight, partitions, maxItemsPerBin, scannable: product.scannable };
};

export const updateProductProperties = (
  mfcId: string,
  product: DecantActionProduct,
  { scannable, partitions, toteHeight, maxItemsPerBin }: ProductProperties,
) =>
  productsApi.updateProductProperties({
    payload: {
      mfcId,
      sku: product.sku,
      companyCode: product.companyCode,
      scannable,
      ...(maxItemsPerBin && partitions && toteHeight
        ? {
            sitePackaging: {
              maxItemsPerBin,
              partitions,
              toteHeight,
            },
          }
        : {}),
    },
  });
