import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { QcCheckResult } from '@get-fabric/wms-api-client';
import { Flex, Text, Button } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../../../shared/hooks';
import { useCompleteBin } from '../hooks/useCompleteBin';
import { BinIsEmptyExceptionsModal } from './BinIsEmptyExceptionsModal';

export const BinIsEmpty: FunctionComponent = () => {
  const { t } = useTranslation();
  const exceptionsModal = useModalState();
  const { completeBin, completingBin } = useCompleteBin();

  const onYes = useCallback(() => completeBin(QcCheckResult.NoException), [completeBin]);

  return (
    <>
      <Flex flexDirection={'column'} alignItems={'center'} gap={30}>
        <Text size={40}>{t('qc.binIsEmptyQuestion')}</Text>
        <Flex alignItems={'center'} justifyContent={'center'} gap={20} fullWidth>
          <Button onClick={onYes} loading={completingBin}>
            {t('actions.yes')}
          </Button>
          <Button variant={'secondary'} onClick={exceptionsModal.open}>
            {t('actions.no')}
          </Button>
        </Flex>
      </Flex>
      <BinIsEmptyExceptionsModal open={exceptionsModal.modalOpen} close={exceptionsModal.close} />
    </>
  );
};
