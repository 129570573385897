import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@get-fabric/fabric-design-system';
import { BrokenImageFallback } from './BrokenImageFallback';

const ImageContainer = styled(Flex)`
  aspect-ratio: 1;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
`;

const StyledImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 100%;
`;

interface ProductImageProps {
  url: string;
}

export const ProductImage: FunctionComponent<ProductImageProps> = ({ url }) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const onImageLoadError = useCallback(() => {
    setImageFailedToLoad(true);
  }, []);

  return (
    <ImageContainer justifyContent={'center'} alignItems={'stretch'}>
      {imageFailedToLoad ? <BrokenImageFallback /> : <StyledImage src={url} onError={onImageLoadError} />}
    </ImageContainer>
  );
};
