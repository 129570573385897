import type { SvgIconComponent } from '@mui/icons-material';
import BrokenImage from '@mui/icons-material/BrokenImage';
import Report from '@mui/icons-material/Report';

export enum ExceptionType {
  BinIsNotEmpty = 'binIsNotEmpty',
  BinIsEmpty = 'binIsEmpty',
  WrongQuantityInBin = 'wrongQuantityInBin',
  RejectedItem = 'rejectedItem',
  DamagedItem = 'damagedItem',
  WrongItemInBin = 'wrongItemInBin',
  WrongItemInSourceBin = 'wrongItemInSourceBin',
  WrongItemInTargetBin = 'wrongItemInTargetBin',
  SourceBinIsEmpty = 'sourceBinIsEmpty',
  DamagedItemInSourceBin = 'damagedItemInSourceBin',
}

export const exceptionOptionLogos: Record<ExceptionType, SvgIconComponent> = {
  [ExceptionType.BinIsEmpty]: Report,
  [ExceptionType.BinIsNotEmpty]: Report,
  [ExceptionType.WrongQuantityInBin]: Report,
  [ExceptionType.RejectedItem]: BrokenImage,
  [ExceptionType.DamagedItem]: BrokenImage,
  [ExceptionType.DamagedItemInSourceBin]: BrokenImage,
  [ExceptionType.WrongItemInBin]: Report,
  [ExceptionType.WrongItemInSourceBin]: Report,
  [ExceptionType.WrongItemInTargetBin]: Report,
  [ExceptionType.SourceBinIsEmpty]: Report,
};
