import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, lightTheme } from '@get-fabric/fabric-design-system';
import { Card, CardContent, Divider } from '@mui/material';
import type { DecantAction, DecantTask } from '@get-fabric/action-api-client';
import { YesNoQuestionModal } from '../../../../Qc/components/YesNoQuestionModal';
import { useModalState } from '../../../../../../shared/hooks';
import { TaskDetails } from './TaskDetails';
import { TaskCompletion } from './TaskCompletion';
import { PreviouslySelectedRibbon } from './PreviouslySelectedRibbon';

interface TaskOptionProps {
  task: DecantTask;
  actions: DecantAction[];
  sku: string;
  previouslySelected?: boolean;
  onSelect: (taskId: string) => void;
}

enum TaskStatus {
  Pending = 'pending',
  Ongoing = 'ongoing',
}

const getActionsCompletion = (actions: DecantAction[]) =>
  actions.reduce(
    ({ completed, required }, action) => ({
      completed: completed + (action.results?.movedQuantity ?? 0),
      required: required + action.requirements.quantity,
    }),
    {
      completed: 0,
      required: 0,
    },
  );

export const TaskOption: FunctionComponent<TaskOptionProps> = ({ task, actions, sku, previouslySelected = false, onSelect }) => {
  const { t } = useTranslation();
  const line = useMemo(() => getActionsCompletion(actions.filter((action) => action.requirements.product.sku === sku)), [actions, sku]);
  const allLines = useMemo(() => getActionsCompletion(actions), [actions]);
  const taskStatus = useMemo(
    () => (actions.some(({ results }) => results?.movedQuantity) ? TaskStatus.Ongoing : TaskStatus.Pending),
    [actions],
  );
  const { modalOpen, open, close } = useModalState();
  const lineCompleted = line.completed >= line.required;

  const selectTask = useCallback(() => {
    if (lineCompleted && !modalOpen) {
      open();
    } else {
      onSelect(task.id);
    }
  }, [open, modalOpen, lineCompleted, onSelect, task.id]);

  return (
    <>
      <Card
        onClick={selectTask}
        data-testid={`taskOption-${task.id}`}
        sx={{
          width: '290px',
          position: 'relative',
          boxSizing: 'border-box',
          border: previouslySelected && !lineCompleted ? `4px ${lightTheme.colors.purple[700]} solid` : '',
        }}
      >
        {previouslySelected && !lineCompleted && <PreviouslySelectedRibbon />}
        <CardContent>
          <TaskDetails task={task} status={taskStatus} />
          <Divider sx={{ mt: '12px', mb: '12px' }} />
          <Flex flexDirection={'column'} gap={5}>
            <TaskCompletion
              title={t('decant.taskSelectionModal.taskOption.lineCompletionTitle')}
              completed={line.completed}
              required={line.required}
              allowOverflow
            />
            <TaskCompletion
              title={t('decant.taskSelectionModal.taskOption.allLinesCompletionTitle')}
              completed={allLines.completed}
              required={allLines.required}
            />
          </Flex>
        </CardContent>
      </Card>
      <YesNoQuestionModal open={modalOpen} question={t('decant.taskConfirmationModal.question')} onYes={selectTask} onNo={close} />
    </>
  );
};
