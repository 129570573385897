import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { useButtonPress } from '../../../../shared/hooks';
import { substationOverflowState } from '../../../../shared/state';
import { logger } from '../../../../shared/clients/loggingApi';

interface ReleaseToteProps {
  allocation: Allocation;
  substationPosition: string;
}

export const ReleaseTote: FunctionComponent<ReleaseToteProps> = ({ allocation, substationPosition }) => {
  const { t } = useTranslation();
  const overflow = useRecoilValue(substationOverflowState(substationPosition));

  const onButtonPress = useCallback(async () => {
    if (overflow) {
      toast.error(t('qc.overflowToastError'));

      return;
    }

    try {
      const correlationId = uuid();

      await allocationsApi.updateByAllocationId(allocation.id, {
        payload: { status: allocation.status === AllocationStatus.Ongoing ? AllocationStatus.Completed : AllocationStatus.Cancelled },
        meta: { correlationId },
      });
    } catch (error) {
      logger.error('failed to release tote', {
        error,
        allocation,
        substationPosition,
      });
      toast.error(t('qc.releaseToteError'));
    }
  }, [allocation, overflow, t, substationPosition]);

  useButtonPress(onButtonPress);

  return (
    <Flex flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={25}>
      {overflow ? (
        <>
          <Text weight={300} size={64} textAlign={'center'}>
            {t('qc.overflowTitle', { substationPosition: 'right' })}
          </Text>
          <Text weight={300} size={34} textAlign={'center'}>
            {t('qc.overflowInstruction')}
          </Text>
        </>
      ) : (
        <>
          <Text size={40} textAlign={'center'}>
            {t('qc.toteCompleted')}
          </Text>
          <Text weight={300} size={28}>
            {t('qc.pressButtonInstruction')}
          </Text>
        </>
      )}
    </Flex>
  );
};
