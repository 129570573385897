import type { FunctionComponent } from 'react';
import React from 'react';
import type { Bin } from '@get-fabric/wms-api-client';
import { ExceptionsModal } from '../../../../shared/components/Exceptions';
import { ExceptionType } from '../../../../shared/components/Exceptions/exceptionTypes';
import { MergeExceptionsSteps } from './MergeExceptionsSteps';

interface MergeExceptionsModalProps {
  open: boolean;
  close: () => void;
  onReportException: (exception: ExceptionType) => void;
  sourceBin: Bin;
  targetBin: Bin;
  actionId: string;
}

export const MergeExceptionsModal: FunctionComponent<MergeExceptionsModalProps> = ({
  open,
  close,
  onReportException,
  actionId,
  targetBin,
  sourceBin,
}) => (
  <ExceptionsModal
    open={open}
    close={close}
    exceptions={[
      ExceptionType.SourceBinIsEmpty,
      ExceptionType.WrongItemInSourceBin,
      ExceptionType.DamagedItemInSourceBin,
      ExceptionType.WrongItemInTargetBin,
    ]}
  >
    <MergeExceptionsSteps onReportException={onReportException} targetBin={targetBin} sourceBin={sourceBin} actionId={actionId} />
  </ExceptionsModal>
);
