import type { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';

export const StyledBinInfoProductImage = styled.img`
  max-height: 200px;
  max-width: 50%;
  flex-grow: 0;
`;

interface BinInfoContainerProps {
  imageUrl: string;
}

export const BinInfoProductImage: FunctionComponent<BinInfoContainerProps> = ({ imageUrl }) => <StyledBinInfoProductImage src={imageUrl} />;
