import React, { useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useRecoilAsync, toteByPositionState } from '../../../../shared/state';
import { useAllocationsByStatus, useSubstationToteAllocations } from '../../../../shared/hooks';
import { TasksDone } from '../../../../shared/components/TasksDone';
import { WaitingForRobots } from '../../../../shared/components/WaitingForRobots';
import { WaitingForTotes } from '../../../../shared/components/WaitingForTotes';
import { Loading } from '../../../../shared/components/Loading';
import { ExtractStock } from './ExtractStock';

interface ExtractStockPageProps {
  position: 'left' | 'right';
}

export const ExtractStockPage: FunctionComponent<ExtractStockPageProps> = ({ position }) => {
  const { data: toteInPosition } = useRecoilAsync(toteByPositionState(position));

  const { currentAllocation, allocations } = useSubstationToteAllocations({
    position,
    toteInPositionId: toteInPosition?.id,
  });

  const { allocations: ongoingAllocations } = useAllocationsByStatus({ allocations, statuses: [AllocationStatus.Ongoing] });
  const { allocations: approvedAllocations } = useAllocationsByStatus({ allocations, statuses: [AllocationStatus.Approved] });

  const currentBin = useMemo(
    () => toteInPosition?.bins?.find((bin) => bin.id === currentAllocation?.binId),
    [toteInPosition, currentAllocation],
  );

  if (!allocations.length) {
    return <TasksDone />;
  }

  if (!approvedAllocations.length && !ongoingAllocations.length) {
    return <WaitingForRobots />;
  }

  if (!toteInPosition || !currentAllocation) {
    return <WaitingForTotes />;
  }

  if (!currentBin) {
    return <Loading />;
  }

  return <ExtractStock allocation={currentAllocation} bin={currentBin} position={position} />;
};
