import { withRetry, withHeaders, withToken, withThrow, withTimeout } from '@osskit/fetch-enhancers';
import { v4 as uuid } from 'uuid';
import { getToken } from './getToken';
import { traceIdHeader } from './correlation';

const retryOpts = {
  minTimeout: 1000,
  retries: 3,
  factor: 5,
};

const headers = (headers?: HeadersInit) => ({
  'x-api-client': 'touchpoint',
  'x-api-client-version': '1.0',
  [traceIdHeader]: new Headers(headers)?.get(traceIdHeader) ?? uuid(),
});

export const enhancedFetch = withThrow(withToken(getToken, withHeaders(withRetry(window.fetch, retryOpts), headers)));
export const enhancedFetchNoRetry = withThrow(
  withToken(getToken, withHeaders(withTimeout(window.fetch, { requestTimeoutMs: 65_000 }), headers)),
);
