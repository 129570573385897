import { ListItemButton } from '@mui/material';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { formatDistanceToNow } from 'date-fns';
import React, { forwardRef, useCallback } from 'react';
import type { ScanRecord } from './scan';

interface ScanRecordListItemProps {
  scanRecord: ScanRecord;
  selected?: boolean;
  onClick: (scanRecord: ScanRecord) => void;
}

export const ScanRecordListItem = forwardRef<HTMLDivElement, ScanRecordListItemProps>(({ scanRecord, selected, onClick }, ref) => {
  const { id, barcode, date } = scanRecord;

  const clickedScan = useCallback(() => {
    onClick(scanRecord);
  }, [scanRecord, onClick]);

  return (
    <ListItemButton key={id} id={id} onClick={clickedScan} selected={selected} ref={ref} data-testid={`scanRecord-${id}`} role={'listitem'}>
      <Flex flexDirection={'column'} alignItems={'baseline'} fullWidth>
        <Flex alignSelf={'flex-end'}>
          <Text color={'gray.700'} italic>
            {formatDistanceToNow(date)}
          </Text>
        </Flex>
        <Text size={22}>{barcode}</Text>
      </Flex>
    </ListItemButton>
  );
});
