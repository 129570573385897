import type { FunctionComponent } from 'react';
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { useTranslation } from 'react-i18next';
import { ScanList } from './ScanList';
import type { ScanRecord } from './scan';

interface ScanAccordionProps {
  scans: ScanRecord[];
  selectedScanRecordId?: string;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onScanSelect: (scanRecord: ScanRecord) => void;
}

const accordionStyle = {
  width: '100%',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:before': {
    display: 'none',
  },
};

const accordionDetailsStyle = { maxHeight: '200px', overflow: 'auto', marginTop: '16px' };

export const ScanAccordion: FunctionComponent<ScanAccordionProps> = ({
  scans,
  selectedScanRecordId,
  open,
  onOpen,
  onClose,
  onScanSelect,
}) => {
  const { t } = useTranslation();

  return (
    <Accordion expanded={open} onChange={open ? onClose : onOpen} elevation={0} disableGutters sx={accordionStyle}>
      <AccordionSummary
        sx={{
          minHeight: 0,
          height: 0,
        }}
      />
      <AccordionDetails sx={accordionDetailsStyle}>
        {scans.length ? (
          <ScanList scans={scans} selectedRecordId={selectedScanRecordId ?? ''} onScanSelect={onScanSelect} />
        ) : (
          <Flex justifyContent={'center'}>
            <Text color={'gray.700'}>{t('manualScan.nothingWasScanned')}</Text>
          </Flex>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
