import styled, { css } from 'styled-components';
import { Flex, Text } from '@get-fabric/fabric-design-system';
import { BinBase } from '../Bin.styles';

export const StyledBinContent = styled(BinBase)<{ imageUrl?: string; masked?: boolean }>`
  background-color: #9fd6ff;
  flex-direction: column;
  text-align: center;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      position: relative;
      background-color: white;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${imageUrl});
        filter: opacity(0.3);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        mix-blend-mode: multiply;
      }
    `}

  ${({ masked }) =>
    masked &&
    css`
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
      }
    `}
`;

export const StyledBinBrief = styled(Flex)`
  z-index: 1;
  flex-direction: column;
  padding: 0 2px;
`;

export const TextMultilineEllipsis = styled(Text)<{ numOfLines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ numOfLines }) => numOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
