import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import { TaskSelectionModalProvider } from './TaskSelectionModal';
import { DecantPages } from './DecantPages';

export const Decant: FunctionComponent = () => (
  <TaskSelectionModalProvider>
    <Flex p={6} flexGrow={1} justifyContent={'center'} fullWidth>
      <DecantPages />
    </Flex>
  </TaskSelectionModalProvider>
);
