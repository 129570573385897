import type { FunctionComponent } from 'react';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, Flex, NumberInput, Text } from '@get-fabric/fabric-design-system';
import type { CatalogProduct } from '@get-fabric/wms-api-client';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ProductImage } from '../../ProductImage';

const QuantityInput = styled(NumberInput)`
  width: 100px;
`;

interface EnterQuantityStepProps {
  product: CatalogProduct;
  quantity: number;
  onChangeQuantity: (quantity: number) => void;
  expiryDate: Date | null;
  expiryDateMandatory: boolean;
  onClickExpiryDate: (() => void) | null;
}
export const EnterQuantityStep: FunctionComponent<EnterQuantityStepProps> = ({
  product,
  quantity,
  onChangeQuantity,
  expiryDate,
  expiryDateMandatory,
  onClickExpiryDate,
}) => {
  const { t } = useTranslation();

  const quantityChanged = useCallback(
    (quantity: number) => {
      if (!quantity) {
        toast.warn(t('exceptions.binIsNotEmpty.invalidQuantity'), { toastId: 'invalidQuantity' });

        return;
      }

      onChangeQuantity(quantity);
    },
    [onChangeQuantity, t],
  );

  return (
    <Flex gap={8} justifyContent={'space-between'} fullWidth>
      <Flex flexDirection={'column'} gap={10}>
        <Text size={28}>
          <Trans
            i18nKey={'exceptions.binIsNotEmpty.foundItem'}
            t={t}
            components={[<Text key={'productName'} color={'purple.700'} size={28} weight={700} />]}
            values={{ name: product.name }}
          />
        </Text>
        {expiryDateMandatory && (
          <Flex alignSelf={'flex-start'} gap={4}>
            <Text size={28}>{t('exceptions.addNewStock.displayExpiryDate')}</Text>
            <Button size={'xs'} variant={'secondary'} onClick={onClickExpiryDate!}>
              <Text size={22}>{expiryDate ? format(expiryDate, 'dd MMM yyyy') : t('exceptions.addNewStock.displayExpiryMissing')}</Text>
            </Button>
          </Flex>
        )}
        <Flex gap={4}>
          <Text size={28}>{t('exceptions.binIsNotEmpty.enterQuantity')}</Text>
          <QuantityInput value={quantity} onChange={quantityChanged} aria-label={'quantity'} />
        </Flex>
      </Flex>
      <ProductImage url={product.imageUrl ?? ''} />
    </Flex>
  );
};
