import { useMemo } from 'react';
import { useOrderTotesInPosition } from '../../../../shared/hooks';

export const useTraysVacancy = (position: 'left' | 'right') => {
  const orderTotesInPosition = useOrderTotesInPosition(position);

  const emptyTray = useMemo(
    () => orderTotesInPosition.find(({ orderTote, previousTote }) => !orderTote?.id && !orderTote?.totesRequestId && !previousTote?.id),
    [orderTotesInPosition],
  );

  const notEmptyTray = useMemo(
    () => orderTotesInPosition.find(({ orderTote, previousTote }) => orderTote?.id ?? orderTote?.totesRequestId ?? previousTote?.id),
    [orderTotesInPosition],
  );

  return { emptyTray, notEmptyTray };
};
