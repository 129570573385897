import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { substationsState } from '../../../../shared/state';
import { useUnfulfilledAllocations } from '../../../../shared/hooks';

export const useSubstationsWithFulfilledTote = () => {
  const substations = useRecoilValue(substationsState);
  const unfulfilledAllocations = useUnfulfilledAllocations();

  return useMemo(
    () =>
      Object.entries(substations)
        .filter(([, { totes }]) => totes?.current?.id && !unfulfilledAllocations.some(({ toteId }) => toteId === totes?.current?.id))
        .map(([position]) => position),
    [unfulfilledAllocations, substations],
  );
};
