import type { FunctionComponent } from 'react';
import React from 'react';
import { Flex } from '@get-fabric/fabric-design-system';
import type { Allocation } from '@get-fabric/allocation-api-client';
import { AllocationStatus } from '@get-fabric/allocation-api-client';
import { useAsync } from 'react-use';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import type { Action } from '@get-fabric/action-api-client';
import type { Tote } from '../../../../shared/state';
import { productByAllocationIdState, useRecoilAsync } from '../../../../shared/state';
import { useModalState, useActiveSession } from '../../../../shared/hooks';
import { InstructionContainer } from '../../../../shared/components/InstructionContainer';
import { ReportExceptionButton } from '../../../../shared/components/Exceptions';
import { Loading } from '../../../../shared/components/Loading';
import { allocationsApi } from '../../../../shared/clients/allocationApi';
import { OrderTote } from '../../../../shared/components/OrderTote/OrderTote';
import { logger } from '../../../../shared/clients/loggingApi';
import { PickingOutExceptionsModal } from './PickingOutExceptionsModal';
import { NewOrderToteButton } from './NewOrderToteButton';
import { ActionBar } from './shared/ActionBar';
import { PickProductInstructions } from './PickProductInstructions';
import { PickingActionData } from './PickingActionData';

interface Props {
  position: 'left' | 'right';
  active: boolean;
  allocation: Allocation;
  action: Action;
  tote: Tote;
  orderTote?: Tote;
}

export const Instructions: FunctionComponent<Props> = ({ action, position, active, allocation, tote, orderTote }) => {
  const { activeSession } = useActiveSession();
  const exceptionsModal = useModalState();
  const { t } = useTranslation();

  const { data: product } = useRecoilAsync(productByAllocationIdState(allocation?.id));

  const { loading: markingAllocationOngoing } = useAsync(async () => {
    if (allocation.status !== AllocationStatus.Approved || !activeSession) {
      return;
    }

    try {
      await allocationsApi.updateByAllocationId(allocation.id, { payload: { status: AllocationStatus.Ongoing } });
    } catch (error) {
      logger.error('During picking out, failed to update allocation status to ongoing', {
        error,
        allocation,
      });
      toast.error(t('pickingOut.failedMarkingAllocationAsOngoing'));
    }
  }, [allocation.id]);

  if (!product || markingAllocationOngoing || allocation.status !== AllocationStatus.Ongoing) {
    return <Loading />;
  }

  return (
    <>
      <InstructionContainer flexGrow={1}>
        <ActionBar>
          <ReportExceptionButton onClick={exceptionsModal.open} />
          {allocation?.actionId && <PickingActionData actionId={allocation.actionId} />}
        </ActionBar>
        <PickProductInstructions
          action={action}
          allocation={allocation}
          position={position}
          tote={tote}
          orderTote={orderTote}
          product={product}
          active={active}
          modalOpen={exceptionsModal.modalOpen}
        />
        {orderTote && (
          <Flex justifyContent={'space-between'}>
            <NewOrderToteButton orderToteId={orderTote.id} position={position} />
            <OrderTote orderToteId={orderTote.id} />
          </Flex>
        )}
      </InstructionContainer>
      <PickingOutExceptionsModal
        open={exceptionsModal.modalOpen}
        close={exceptionsModal.close}
        onReportException={exceptionsModal.close}
        tote={tote}
        allocation={allocation}
      />
    </>
  );
};
